import { createModelPage } from '../utils'

import Create from './pages/Create'
import Update from './pages/Update'
import List from './pages/List'
import View from './pages/View'
import Destroy from './pages/Delete'

export default createModelPage<any, any>({
  name: 'Course Projects',
  index: List,
  view: View,
  create: Create,
  edit: Update,
  destroy: Destroy,
})
