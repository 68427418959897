import { API } from '../../../../api'
import ImagePreview from '../../../../components/ImagePreview'
import MarkdownPreview from '../../../../components/MarkdownPreview'
import { PageView } from '../../utils'

import { QUERY } from './queries'

const View: PageView<API.people_view_person> = {
  queryKey: 'person',
  query: QUERY,
}

export default View
