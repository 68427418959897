import styled from 'styled-components'
import { TextVerticalSpacer, theme } from '@superhi/design'

export const Header = styled(TextVerticalSpacer).attrs({
  level: '5',
})`
  grid-area: content-header;
  padding: 24px 40px;
  background-color: ${theme.colors.PALE_BLUE_40};
`

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  user-select: none;

  > * {
    font-size: 18px;
  }

  > *:not(:last-child) {
    margin-right: 0.5em;
  }
`

export const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
`

export const HeaderTitle = styled.div`
  h1,
  p {
    margin: 0;
    padding: 0;
  }
`

export const Content = styled.div`
  grid-area: content;
  /* font-size: 16px; */
  padding: 40px;
  overflow: scroll;
`

export const Actions = styled.div`
  /* background-color: ${theme.colors.GREY_10}; */
  font-size: 16px;
  padding: 24px;
  grid-area: content-actions;
  /* background-color: ${theme.colors.GREY_10}; */
  overflow: scroll;
  border-left: 1px solid ${theme.colors.GREY_50};

  > *:not(:last-child) {
    margin-bottom: 0.5em;
  }
`

export const ActionsGroup = styled.div`
  &:not(:first-child):before {
    content: '';
    display: block;
    height: 1px;
    background-color: ${theme.colors.GREY_50};
    margin: 0.5em 0 1em 0;
  }

  &:not(:last-child):after {
    content: '';
    display: block;
    height: 1px;
    background-color: ${theme.colors.GREY_50};
    margin: 1em 0;
  }

  > *:not(:last-child) {
    margin-bottom: 0.5em;
  }
`

export const Breadcrumbs = styled.div`
  display: flex;
  text-transform: capitalize;
  /* display: none; */

  > *:not(:last-child) {
    &:after {
      content: '—';
      display: inline-block;
      padding: 0 8px;
    }
  }
`
