import React from 'react'
import { Price } from '@superhi/design'

import { createModelPage } from '../utils'
import { API } from '../../../api'
import { formatDateTime } from '../../../utils'

import Create from './Create'
import { INDEX, VIEW } from './queries'

export default createModelPage<API.refund_index_refunds_nodes[], API.refund_view_refund>({
  name: 'Membership Refunds',
  excludeNavItem: true,
  // index: {
  //   queryKey: 'refunds',
  //   query: INDEX,
  //   formatData: (data) =>
  //     data.map((item) => ({
  //       // ...data,
  //       id: item.id,
  //       orderId: item.order.id,
  //       status: item.status,
  //     })),
  // },
  // view: {
  //   queryKey: 'refund',
  //   query: VIEW,
  //   formatData: (data) => ({
  //     id: data.id,
  //     ...(data.basket?.id
  //       ? {
  //           'Payment link': (
  //             <Link
  //               href={`${process.env.REACT_APP_MARKETING_SITE_URL}/checkout/exchange?basketId=${data.basket?.id}`}
  //               isExternal
  //             >{`${process.env.REACT_APP_MARKETING_SITE_URL}/checkout/exchange?basketId=${data.basket?.id}`}</Link>
  //           ),
  //         }
  //       : {}),
  //     'Original order': (
  //       <Link href={`/orders/${data.order.id}`}>
  //         #{data.order.id} on {formatDateTime(data.order.insertedAt)} by{' '}
  //         {data.order.customerDetails.name}
  //       </Link>
  //     ),
  //     lineItems: (
  //       <>
  //         {data.lineItems?.nodes.map((lineItem) => (
  //           <div key={lineItem.id}>
  //             {lineItem.quantity} x {lineItem.orderLineItem.variant.title} - [{lineItem.status}]
  //           </div>
  //         ))}
  //       </>
  //     ),
  //     exchangeItems: (
  //       <>
  //         {data.exchangeItems?.nodes.map((exchangeItem) => (
  //           <div key={exchangeItem.id}>
  //             {exchangeItem.quantity} x {exchangeItem.variant.title}
  //           </div>
  //         ))}
  //       </>
  //     ),
  //     payments: (
  //       <>
  //         {data.payments?.nodes.map((payment) => (
  //           <div>
  //             {formatPrice(payment.value.value, payment.value.currencyCode)} on{' '}
  //             {formatDateTime(payment.insertedAt)}
  //           </div>
  //         ))}
  //       </>
  //     ),
  //     status: data.status,
  //     total: formatPrice(data.total.value, data.total.currencyCode),
  //     notes: data.notes,
  //     insertedAt: formatDateTime(data.insertedAt),
  //     updatedAt: formatDateTime(data.updatedAt),
  //   }),
  // },
  create: Create,
})
