import React from 'react'
import { Link } from '@superhi/design'

import { createModelPage } from '../../utils'
import { API } from '../../../../api'

import Create from './Create'
import Update from './Update'
import { INDEX, VIEW } from './queries'
import { DELETE } from './mutations'

export default createModelPage<
  API.product_courses_index_productCourses_nodes[],
  API.product_courses_view_productCourse
>({
  name: 'Product Courses',
  index: {
    queryKey: 'productCourses',
    query: INDEX,
  },
  view: {
    queryKey: 'productCourse',
    query: VIEW,
    // formatData: ({ variants, ...data }) => ({
    //   ...data,
    // }),
  },
  edit: Update,
  create: Create,
  destroy: {
    mutation: DELETE,
  },
})
