import gql from 'graphql-tag'

export default gql`
  query product_courses_update($id: ID!) {
    productCourse(id: $id) {
      id
      title
      slug
      image {
        url
        width
        height
      }
      isBeta
      description
      shortDescription
      mailchimpTag
      trailerWistiaId
      contents {
        title
        description
      }
      projects {
        ... on ProductCourseProjectImage {
          id
          title
          description
          image {
            url
          }
        }

        ... on ProductCourseProjectVideo {
          id
          title
          description
          videoId
        }
      }
      difficultyLevel
      length
      instructors {
        id
      }
      order
      requirements
      subjects
      releaseDate
      syllabusUrl
      status
      targetAudience
      overview
      isBestSeller
      options {
        name
        value
      }
      variants {
        nodes {
          id
          title
          description
          maxQuantity
          mailchimpTag
          options {
            name
            value
          }
          fulfillables {
            nodes {
              id
            }
          }
          price {
            id
          }
        }
      }
      course {
        id
      }
    }
  }
`
