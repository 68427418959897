import { FieldConfig } from '../utils'

export const NAME: FieldConfig<unknown> = {
  type: 'string',
  label: 'Name',
  ariaLabel: 'Name',
  name: 'name',
  required: true,
  placeholder: 'E.g. United States Dollar',
}

export const CODE: FieldConfig<unknown> = {
  type: 'string',
  label: 'Code',
  ariaLabel: 'Code',
  name: 'code',
  required: true,
  placeholder: 'E.g. USD',
}

export const CONVERSIONS_VALUE = (name: string, label: string): FieldConfig<unknown> => ({
  type: 'number',
  min: 0,
  step: 0.01,
  ariaLabel: label,
  label,
  name: `${name}.value`,
  required: true,
})
