import { API } from '../../../../../api'
import { PageList } from '../../../utils'

import { QUERY } from './queries'

const List: PageList<API.courses_index> = {
  queryKey: 'courses',
  query: QUERY,
}

export default List
