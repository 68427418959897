import gql from 'graphql-tag'

export default gql`
  query MultiSelectProductFulfillable {
    productFulfillables(perPage: 100) {
      nodes {
        id
        title
      }
    }
  }
`
