import { Form } from '@superhi/design'
import React from 'react'
import { array, boolean, mixed, object, string } from 'yup'

import { API } from '../../../../api'
import { configToInput } from '../../utils'
import * as config from '../config'
import BaseForm from '../../_templates/BaseForm'

import MUTATION from './mutations'

type FormValues = {
  title: string
  slug: string
  image: {
    url?: string
    value: string
    file: any
  }
  description: string
  variantIds: string[]
  isBestSeller: boolean
}

const ProductCollectionCreate: React.FC<React.PropsWithChildren<unknown>> = () => (
  <BaseForm<FormValues, API.ProductCollectionCreateVariables>
    mutation={MUTATION}
    validationSchema={{
      title: string().required(),
      slug: string().required(),
      image: object({
        url: string(),
        value: string().required(),
        file: mixed().required(),
      }).required(),
      description: string().required(),
      variantIds: array().of(string().required()).required(),
      isBestSeller: boolean().required(),
    }}
    initialValues={{
      title: '',
      slug: '',
      description: '',
      variantIds: [],
      isBestSeller: false,
      image: {
        url: '',
        value: '',
        file: undefined,
      },
    }}
    createVariablesFn={(values) => ({
      ...values,
      image: values.image.file,
    })}
  >
    <Form.Row>{configToInput(config.TITLE)}</Form.Row>

    <Form.Row>{configToInput(config.SLUG)}</Form.Row>

    <Form.Row>{configToInput(config.DESCRIPTION)}</Form.Row>

    <Form.Row>{configToInput(config.IS_BEST_SELLER)}</Form.Row>

    <Form.Row>{configToInput(config.IMAGE)}</Form.Row>

    <Form.Row>{configToInput(config.VARIANT_IDS)}</Form.Row>
  </BaseForm>
)

export default ProductCollectionCreate
