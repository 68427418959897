import gql from 'graphql-tag'

export default gql`
  mutation ProductFulfillableUpdate(
    $id: ID!
    $title: String!
    $description: String
    $fulfillmentId: String!
    $sku: String!
    $allowMultiplePerOrder: Boolean!
  ) {
    productFulfillableUpdate(
      id: $id
      title: $title
      description: $description
      fulfillmentId: $fulfillmentId
      sku: $sku
      allowMultiplePerOrder: $allowMultiplePerOrder
    ) {
      id
    }
  }
`
