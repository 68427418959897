import gql from 'graphql-tag'

export default gql`
  mutation CurrencyUpdateMutation(
    $id: ID!
    $name: String!
    $conversions: [CurrencyConversionInput!]!
  ) {
    currencyUpdate(id: $id, name: $name, conversions: $conversions) {
      id
    }
  }
`
