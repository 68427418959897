import { gql } from '@apollo/client'

export const QUERY = gql`
  query FormSelectCourseProject {
    courseProjects(perPage: 1000) {
      nodes {
        id
        title
      }
    }
  }
`
