import gql from 'graphql-tag'

export default gql`
  fragment MembershipInvoiceCardComponent on MembershipInvoice {
    id
    status
    product {
      id
      title
    }
    price {
      id
      recurring {
        interval
        intervalCount
      }
    }
    total {
      currencyCode
      value
    }
    createdAt
  }
`
