import { array, boolean, mixed, number, object, ObjectSchemaDefinition, string } from 'yup'
import groupBy from 'lodash.groupby'

import { API } from '../../../api'

import { InitialValuesCreate, InitialValuesUpdate } from './types'

export const INITIAL_VALUES_CREATE: InitialValuesCreate = {
  title: '',
  slug: '',
  image: {
    url: undefined,
    value: '',
    file: '',
  },
  description: '',
  shortDescription: '',
  isBestSeller: false,
  releaseDate: undefined,
  fulfillableIds: undefined,
  priceId: undefined,
  maxQuantity: 1,
  hasVariants: false,
  variants: undefined,
  mailchimpTag: '',
  contents: [],
}

type CreateVariablesOutput = Omit<
  API.ProductBookCreateVariables,
  'previewDownloadUrl' | 'previewMailchimpTag'
>

export const CREATE_VARIABLES_CREATE = (data: InitialValuesCreate): CreateVariablesOutput => ({
  slug: data.slug,
  title: data.title,
  shortDescription: data.shortDescription,
  description: data.description,
  contents: data.contents,
  releaseDate: data.releaseDate,
  isBestSeller: data.isBestSeller,
  maxQuantity: data.hasVariants ? undefined : data.maxQuantity,
  fulfillableIds: data.hasVariants ? undefined : data.fulfillableIds,
  image: data.image.file,
  priceId: data.hasVariants ? undefined : data.priceId,
  variants: data.hasVariants ? data.variants : undefined,
  mailchimpTag: data.mailchimpTag,
})

export const VALIDATION_SCHEMA_CREATE: ObjectSchemaDefinition<InitialValuesCreate> = {
  title: string().required(),
  slug: string().required(),
  image: object({
    url: string(),
    value: string().required(),
    file: mixed().required(),
  }).required(),
  description: string().required(),
  mailchimpTag: string().required(),
  shortDescription: string().required(),
  isBestSeller: boolean().required(),
  releaseDate: string(),
  fulfillableIds: array().of(string().required()),
  priceId: string(),
  hasVariants: boolean().required(),
  variants: array().of(
    object({
      title: string().required(),
      description: string(),
      fulfillableIds: array().of(string().required()),
      priceId: string().required(),
      maxQuantity: number().required(),
      options: array()
        .of(
          object({
            name: string().required(),
            value: string().required(),
          }).required(),
        )
        .required(),
    }).required(),
  ),
  contents: array()
    .of(
      object({
        title: string().required(),
        description: string(),
      }).required(),
    )
    .required(),
}

export const INITIAL_VALUES_UPDATE = (product: {
  title: string
  slug: string
  image: { url: string; width?: number; height?: number }
  description: string
  shortDescription: string
  isBestSeller: boolean
  mailchimpTag: string
  releaseDate: any | null
  contents: { __typename: string; title: string; description?: string | null }[]
  options?:
    | {
        __typename: string
        name: string
        value: string
      }[]
    | null
  variants?: {
    nodes: {
      id: string
      title: string
      maxQuantity: number
      description?: string | null
      mailchimpTag?: string | null
      price: {
        id: string
      }
      fulfillables?: {
        nodes: {
          id: string
        }[]
      } | null
      options:
        | {
            __typename: string
            name: string
            value: string
          }[]
        | null
    }[]
  }
}): InitialValuesUpdate => ({
  title: product.title,
  slug: product.slug,
  image: {
    url: product.image.url,
    value: undefined,
    file: undefined,
  },
  description: product.description,
  shortDescription: product.shortDescription,
  isBestSeller: product.isBestSeller,
  fulfillableIds:
    product.variants?.nodes.length === 1
      ? product.variants.nodes[0].fulfillables?.nodes.map((fulfillable) => fulfillable.id)
      : undefined,
  priceId: product.variants?.nodes.length === 1 ? product.variants.nodes[0].price.id : undefined,
  maxQuantity:
    product.variants?.nodes.length === 1 ? product.variants.nodes[0].maxQuantity : undefined,
  hasVariants: (product.variants?.nodes?.length || 0) > 1,
  releaseDate: product.releaseDate || undefined,
  options:
    Object.entries(groupBy(product.options, (option) => option.name)).map(([name, values]) => ({
      name,
      value: values.map((value) => value.value).join(', '),
    })) || undefined,
  variants:
    (product.variants?.nodes.length || 0) > 1
      ? product.variants?.nodes.map((variant) => ({
          id: variant.id,
          title: variant.title,
          description: variant.description || undefined,
          maxQuantity: variant.maxQuantity,
          mailchimpTag: variant.mailchimpTag,
          fulfillableIds: variant.fulfillables?.nodes.map((fulfilabble) => fulfilabble.id),
          priceId: variant.price?.id!,
          options: variant.options?.map(({ __typename, ...option }) => option) || [],
        }))
      : undefined,
  contents: product.contents.map(({ __typename, ...content }) => content),
  mailchimpTag: product.mailchimpTag,
})

type UpdateVariablesOutput = Omit<
  API.ProductBookUpdateMutationVariables,
  'previewDownloadUrl' | 'previewMailchimpTag'
>

export const CREATE_VARIABLES_UPDATE = (
  data: InitialValuesUpdate & { id: string },
): UpdateVariablesOutput => ({
  id: data.id,
  slug: data.slug,
  title: data.title,
  shortDescription: data.shortDescription,
  description: data.description,
  releaseDate: data.releaseDate || null,
  contents: data.contents,
  isBestSeller: data.isBestSeller,
  maxQuantity: data.hasVariants ? undefined : data.maxQuantity,
  fulfillableIds: data.hasVariants ? undefined : data.fulfillableIds,
  image: data.image.file,
  priceId: data.hasVariants ? undefined : data.priceId,
  variants: data.hasVariants ? data.variants : undefined,
  mailchimpTag: data.mailchimpTag,
})

export const VALIDATION_SCHEMA_UPDATE: ObjectSchemaDefinition<InitialValuesUpdate> = {
  title: string().required(),
  slug: string().required(),
  image: object({
    url: string(),
    value: string(),
    file: mixed(),
  }).required(),
  description: string().required(),
  shortDescription: string().required(),
  isBestSeller: boolean().required(),
  fulfillableIds: array().of(string().required()),
  priceId: string(),
  releaseDate: string(),
  hasVariants: boolean().required(),
  mailchimpTag: string().required(),
  variants: array().of(
    object({
      title: string().required(),
      description: string(),
      fulfillableIds: array().of(string().required()),
      priceId: string().required(),
      maxQuantity: number().required(),
      mailchimpTag: string(),
      options: array()
        .of(
          object({
            name: string().required(),
            value: string().required(),
          }).required(),
        )
        .required(),
    }).required(),
  ),
  contents: array()
    .of(
      object({
        title: string().required(),
        description: string(),
      }).required(),
    )
    .required(),
}
