import React from 'react'
import { useQuery, DocumentNode } from '@apollo/client'
import { useLocation, useParams } from 'react-router-dom'
import { Button } from '@superhi/design'

import Page from '../../../../components/Page'
import { getModelFromPath } from '../../utils'

import Data from './Data'

export type Props<T> = {
  query: DocumentNode
  queryKey: string
  actions?: (id: string, data: T) => any
  formatData?: (data: T) => any
  hideEdit?: boolean
  hideDelete?: boolean
}

function View<T>({ query, queryKey, formatData, actions, hideEdit = false, hideDelete }: Props<T>) {
  const { id } = useParams<any>()
  const modelName = getModelFromPath(useLocation().pathname)
  const { data, loading } = useQuery(query, {
    variables: {
      id,
    },
  })

  const formattedData = React.useMemo(() => {
    if (data) {
      return formatData ? formatData(data[queryKey]) : data[queryKey]
    }

    return {}
  }, [data, formatData, queryKey])

  return (
    <Page
      title={loading && !data ? 'Loading...' : undefined}
      actions={
        <>
          {!hideEdit && (
            <Button href={`/${modelName}/${id}/edit`} fullWidth>
              Edit
            </Button>
          )}
          {!hideDelete && (
            <Button href={`/${modelName}/${id}/delete`} version="outline" fullWidth>
              Delete
            </Button>
          )}
          {actions && (
            <Page.ActionsGroup>{data ? actions(id, data[queryKey]) : undefined}</Page.ActionsGroup>
          )}
        </>
      }
    >
      {React.isValidElement(formattedData) ? (
        formattedData
      ) : formattedData !== null ? (
        <Data data={formattedData} />
      ) : (
        'No data'
      )}
    </Page>
  )
}

export default View
