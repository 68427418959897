import gql from 'graphql-tag'

export default gql`
  query product_books_update($id: ID!) {
    productBook(id: $id) {
      id
      title
      slug
      image {
        url
      }
      description
      shortDescription
      isBestSeller
      releaseDate
      previewDownloadUrl
      mailchimpTag
      contents {
        title
        description
      }
      options {
        name
        value
      }
      variants {
        nodes {
          id
          title
          description
          maxQuantity
          mailchimpTag
          options {
            name
            value
          }
          fulfillables {
            nodes {
              id
            }
          }
          price {
            id
          }
        }
      }
    }
  }
`
