import gql from 'graphql-tag'

export default gql`
  mutation popups_update_mutation(
    $id: ID!
    $title: String!
    $isActive: Boolean!
    $body: String!
    $buttonUrl: String!
    $buttonText: String!
  ) {
    popupUpdate(
      id: $id
      title: $title
      isActive: $isActive
      body: $body
      buttonUrl: $buttonUrl
      buttonText: $buttonText
    ) {
      id
    }
  }
`
