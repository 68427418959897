import React from 'react'
import { useQuery } from '@apollo/client'
import { FormSelectInput } from '@superhi/design'

import { API } from '../../api'

import QUERY from './queries'

type Props = Omit<React.ComponentProps<typeof FormSelectInput>, 'options'>

const SelectPrice: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { data } = useQuery<API.SelectPrice>(QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  if (!data?.prices) {
    return null
  }

  const OPTIONS = [
    { display: 'Choose one', value: undefined },
    ...data.prices.nodes.map((price) => ({
      value: price.id,
      display: `${price.name}`,
    })),
  ]

  return <FormSelectInput {...props} options={OPTIONS} />
}

export default SelectPrice
