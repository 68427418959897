import { gql } from '@apollo/client'

export default gql`
  fragment PersonPreview on Person {
    id
    name
    image {
      url
    }
  }
`
