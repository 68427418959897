import { API } from '../../../../../api'
import { PageView } from '../../../utils'

import { QUERY } from './queries'

const View: PageView<API.course_projects_view_courseProject> = {
  queryKey: 'courseProject',
  query: QUERY,
}

export default View
