import { createModelPage } from '../utils'
import { API } from '../../../api'
import { formatDateTime } from '../../../utils'

import Create from './Create'
import Edit from './Update'
import { INDEX, VIEW } from './queries'
import { getFriendlyDescription } from './Form/Clauses/utils'
import { transformDiscountClauses } from './utils'
import { DELETE } from './mutations'

export default createModelPage<API.discounts_index_discounts, API.discounts_view_discount>({
  name: 'Discounts',
  index: {
    queryKey: 'discounts',
    query: INDEX,
  },
  create: Create,
  view: {
    queryKey: 'discount',
    query: VIEW,
    formatData: ({ percent, clauses, ...data }) => ({
      ...data,
      percent: `${percent}%`,
      clauses: getFriendlyDescription(transformDiscountClauses(clauses)),
    }),
  },
  edit: Edit,
  destroy: {
    mutation: DELETE,
  },
})
