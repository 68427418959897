import React from 'react'
import { useQuery } from '@apollo/client'
import { string, array, number, object, boolean } from 'yup'
import { Form } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import * as config from '../config'
import { configToInput } from '../../utils'

import MUTATION from './mutations'

type FormValues = {
  title: string
  isActive: boolean
  body: string
  buttonUrl: string
  buttonText: string
}

const PricesCreate: React.FC<React.PropsWithChildren<unknown>> = () => (
  <BaseForm<FormValues, API.popups_create_mutationVariables>
    initialValues={{
      title: '',
      isActive: true,
      body: '',
      buttonUrl: '',
      buttonText: '',
    }}
    validationSchema={{
      title: string().required(),
      isActive: boolean().required(),
      body: string().required(),
      buttonUrl: string().required(),
      buttonText: string().required(),
    }}
    mutation={MUTATION}
    createVariablesFn={(formValues) => formValues}
  >
    <Form.Row>{configToInput(config.TITLE)}</Form.Row>
    <Form.Row>{configToInput(config.IS_ACTIVE)}</Form.Row>
    <Form.Row>{configToInput(config.BODY)}</Form.Row>
    <Form.Row>{configToInput(config.BUTTON_TEXT)}</Form.Row>
    <Form.Row>{configToInput(config.BUTTON_URL)}</Form.Row>
  </BaseForm>
)

export default PricesCreate
