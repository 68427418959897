import React from 'react'
import { useFormikContext } from 'formik'
import { Form, FormTextInput, FormMultiSelectInput, FormSelectInput } from '@superhi/design'

import { FormData } from '../'
import MultiSelectProductVariant from '../../../../../components/MultiSelectProductVariant'

import {
  getFriendlyDescription,
  COUNT_EXPRESSIONS_OPERATOR_OPTIONS,
  PRODUCT_TYPE_OPTIONS,
  createEmptyCountExpression,
  createEmptyProductExpression,
  createEmptyTypeExpression,
  createEmptyClause,
} from './utils'

const Clauses: React.FC<React.PropsWithChildren<unknown>> = () => {
  const formik = useFormikContext<FormData>()
  const friendlyDescription = getFriendlyDescription(formik.values.clauses)

  return (
    <>
      {friendlyDescription && <Form.Message>{friendlyDescription}</Form.Message>}

      <Form.Repeatable<FormData, 'clauses'>
        name="clauses"
        label="Expressions"
        defaultValue={createEmptyClause()}
      >
        {(clause) => (
          <div>
            <Form.Repeatable<FormData['clauses'][0], 'countExpressions'>
              name={`${clause.name}.countExpressions`}
              label="Count expressions"
              defaultValue={createEmptyCountExpression()}
            >
              {(expression) => (
                <>
                  <Form.Row>
                    <FormSelectInput
                      name={`${expression.name}.operator`}
                      label="Operator"
                      ariaLabel="Operator"
                      options={COUNT_EXPRESSIONS_OPERATOR_OPTIONS}
                      required
                    />
                  </Form.Row>
                  <Form.Row>
                    <FormTextInput
                      type="number"
                      name={`${expression.name}.value`}
                      label="Value"
                      ariaLabel="Value"
                      min={0}
                      step={1}
                      required
                    />
                  </Form.Row>
                </>
              )}
            </Form.Repeatable>

            <Form.Repeatable<FormData['clauses'][0], 'productExpressions'>
              name={`${clause.name}.productExpressions`}
              label="Product expressions"
              defaultValue={createEmptyProductExpression()}
            >
              {(expression) => (
                <Form.Row>
                  <MultiSelectProductVariant
                    name={`${expression.name}.value`}
                    label="Product variants"
                    ariaLabel="Product variants"
                    required
                  />
                </Form.Row>
              )}
            </Form.Repeatable>
            <Form.Repeatable<FormData['clauses'][0], 'typeExpressions'>
              name={`${clause.name}.typeExpressions`}
              label="Type expressions"
              defaultValue={createEmptyTypeExpression()}
            >
              {(expression) => (
                <Form.Row>
                  <FormMultiSelectInput
                    name={`${expression.name}.value`}
                    label="Type"
                    ariaLabel="Type"
                    options={PRODUCT_TYPE_OPTIONS}
                    required
                  />
                </Form.Row>
              )}
            </Form.Repeatable>
          </div>
        )}
      </Form.Repeatable>
    </>
  )
}

export default Clauses
