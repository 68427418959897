import gql from 'graphql-tag'

export default gql`
  mutation PersonUpdateMutation(
    $id: ID!
    $description: String!
    $image: Upload
    $jobTitle: String!
    $location: String
    $name: String!
    $slug: String!
    $facebook: String
    $instagram: String
    $twitter: String
    $isSuperhi: Boolean!
    $startedOn: Date
  ) {
    personUpdate(
      id: $id
      description: $description
      image: $image
      jobTitle: $jobTitle
      location: $location
      name: $name
      slug: $slug
      facebook: $facebook
      instagram: $instagram
      twitter: $twitter
      isSuperhi: $isSuperhi
      startedOn: $startedOn
    ) {
      id
    }
  }
`
