import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Form, FormCheckboxInput, FormLongTextInput, FormTextInput } from '@superhi/design'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'
import InputImage from '../../../../../components/InputImage'

import { FormValues } from './types'
import QUERY from './queries'
import MUTATION from './mutations'
import { FIELDS, VALIDATION_SCHEMA } from './config'

const CourseHomeworksUpdate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.course_homeworks_update, API.course_homeworks_updateVariables>(
    QUERY,
    {
      variables: {
        id,
      },
    },
  )

  if (!data?.courseHomework) {
    return null
  }

  return (
    <BaseForm<FormValues, API.course_homeworks_update_mutationVariables>
      mutation={MUTATION}
      validationSchema={VALIDATION_SCHEMA}
      initialValues={{
        description: data.courseHomework.description,
        files: [],
        finishedUrl: data.courseHomework.finishedUrl || '',
        briefUrl: data.courseHomework.briefUrl || '',
        image: {
          url: data.courseHomework.image.url,
          value: '',
          file: undefined,
        },
        links: [],
        slug: data.courseHomework.slug,
        title: data.courseHomework.title,
        isRequired: data.courseHomework.isRequired,
      }}
      createVariablesFn={(values) => ({
        ...values,
        id,
        image: values.image.file,
      })}
    >
      <Form.Rows>
        <FormTextInput {...FIELDS.TITLE} />
        <FormTextInput {...FIELDS.SLUG} />
        <InputImage {...FIELDS.IMAGE} />
        <FormLongTextInput {...FIELDS.DESCRIPTION} />
        <FormTextInput {...FIELDS.FINISHED_URL} />
        <FormTextInput {...FIELDS.BRIEF_URL} />
        <FormCheckboxInput {...FIELDS.IS_REQUIRED} />
      </Form.Rows>
    </BaseForm>
  )
}

export default CourseHomeworksUpdate
