import React from 'react'
import { array, boolean, number, object, string } from 'yup'
import { Form } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import { configToInput } from '../../utils'

import MUTATION from './mutations'

const getTotal = (lineItems: FormValues['lineItems']) =>
  lineItems.reduce((acc, item) => acc + item.value * item.quantity, 0)

const createEmptyLineItem = (): LineItem => ({
  variantId: '',
  quantity: 1,
  value: 0,
})

type LineItem = {
  variantId: string
  value: number
  quantity: number
}

type FormValues = {
  requiresShipping: boolean
  currencyCode: API.CurrencyCode
  customerDetails: {
    name: string
    email: string
  }
  shippingDetails?: {
    city: string
    country: string
    line1: string
    line2?: string
    name: string
    phoneNumber?: string
    postalCode: string
    state: string
  }
  lineItems: LineItem[]
  notes?: string
}

const OrderCreate: React.FC<React.PropsWithChildren<unknown>> = () => (
  <BaseForm<FormValues, API.orders_create_mutationVariables>
    initialValues={{
      requiresShipping: false,
      customerDetails: {
        name: '',
        email: '',
      },
      shippingDetails: undefined,
      lineItems: [],
      notes: '',
      currencyCode: API.CurrencyCode.USD,
    }}
    validationSchema={{
      requiresShipping: boolean().required(),
      customerDetails: object({
        name: string().required(),
        email: string().required(),
      }).required(),
      shippingDetails: object({
        name: string().required(),
        line1: string().required(),
        line2: string(),
        city: string().required(),
        state: string().required(),
        postalCode: string().required(),
        country: string().required(),
      }).default(undefined),
      lineItems: array()
        .of(
          object({
            variantId: string().required(),
            value: number().required(),
            quantity: number().required(),
          }).required(),
        )
        .required(),
      notes: string(),
      currencyCode: string().oneOf(Object.values(API.CurrencyCode)).required(),
    }}
    mutation={MUTATION}
    createVariablesFn={(formValues) => ({
      customerDetails: formValues.customerDetails,
      shippingDetails: formValues.shippingDetails,
      lineItems: formValues.lineItems.map((lineItem) => ({
        variantId: lineItem.variantId,
        quantity: lineItem.quantity,
        price: {
          currencyCode: formValues.currencyCode,
          value: lineItem.value,
        },
      })),
      total: {
        currencyCode: formValues.currencyCode,
        value: getTotal(formValues.lineItems),
      },
      notes: formValues.notes,
    })}
  >
    <Form.Row>
      {configToInput({
        type: 'string',
        name: 'customerDetails.name',
        label: 'Customer name',
        ariaLabel: 'Customer name',
        placeholder: 'E.g. Elaine Baines',
        required: true,
      })}
    </Form.Row>
    <Form.Row>
      {configToInput({
        type: 'string',
        name: 'customerDetails.email',
        placeholder: 'E.g. hi@superhi.com',
        label: 'Customer email',
        ariaLabel: 'Customer email',
        required: true,
      })}
    </Form.Row>

    <Form.Row>
      {configToInput({
        type: 'select-currency',
        name: 'currencyCode',
        label: 'Currency',
        ariaLabel: 'Currency',
        required: true,
      })}
    </Form.Row>

    <Form.Repeatable<FormValues, 'lineItems'>
      name="lineItems"
      label="Line items"
      defaultValue={createEmptyLineItem()}
      required={false}
    >
      {(clause) => (
        <Form.Split ratios={{ small: [50, 25, 25], medium: [50, 25, 25], large: [50, 25, 25] }}>
          {configToInput({
            type: 'select-variant',
            name: `${clause.name}.variantId`,
            label: 'Variant',
            ariaLabel: 'Variant',
            required: true,
          })}
          {configToInput({
            type: 'number',
            min: 1,
            name: `${clause.name}.quantity`,
            label: 'Quantity',
            ariaLabel: 'Quantity',
            required: true,
          })}
          {configToInput({
            type: 'number',
            min: 0,
            name: `${clause.name}.value`,
            label: 'Price (per item)',
            ariaLabel: 'Price (per item)',
            required: true,
          })}
        </Form.Split>
      )}
    </Form.Repeatable>

    <Form.Legend label="Shipping">
      <Form.Row>
        {configToInput({
          type: 'boolean',
          name: 'requiresShipping',
          // label: 'Requires shipping?',
          ariaLabel: 'Requires shipping?',
          required: false,
          text: 'Requires shipping?',
        })}
      </Form.Row>
      <Form.Peek<FormValues, FormValues['requiresShipping']> name="requiresShipping">
        {({ meta }) =>
          meta.value && (
            <>
              <Form.Row>
                {configToInput({
                  type: 'string',
                  name: 'shippingDetails.name',
                  label: 'Shipping name',
                  ariaLabel: 'Shipping name',
                  placeholder: 'E.g. Elaine Baines',
                  required: true,
                })}
              </Form.Row>
              <Form.Row>
                {configToInput({
                  type: 'string',
                  name: 'shippingDetails.line1',
                  label: 'Address line 1',
                  ariaLabel: 'Address line 1',
                  placeholder: 'e.g. 129 West 81st St',
                  hint: 'Street address, Company Name, c/o.',
                  required: true,
                })}
              </Form.Row>
              <Form.Row>
                {configToInput({
                  type: 'string',
                  name: 'shippingDetails.line2',
                  label: 'Address line 2',
                  ariaLabel: 'Address line 2',
                  placeholder: 'Apartment, suite, unit, building, floor, etc',
                  required: false,
                })}
              </Form.Row>
              <Form.Row>
                {configToInput({
                  type: 'string',
                  name: 'shippingDetails.city',
                  label: 'City or town',
                  ariaLabel: 'City or town',
                  placeholder: 'e.g. New York',
                  required: true,
                })}
              </Form.Row>
              <Form.Row>
                {configToInput({
                  type: 'string',
                  name: 'shippingDetails.state',
                  label: 'State, province or region',
                  ariaLabel: 'State, province or region',
                  placeholder: 'e.g. NY',
                  required: true,
                })}
              </Form.Row>
              <Form.Row>
                {configToInput({
                  type: 'string',
                  name: 'shippingDetails.postalCode',
                  label: 'Postal or zip code',
                  ariaLabel: 'Postal or zip code',
                  placeholder: 'e.g. 10024',
                  required: true,
                })}
              </Form.Row>
              <Form.Row>
                {configToInput({
                  type: 'select-country',
                  name: 'shippingDetails.country',
                  label: 'Country',
                  ariaLabel: 'Country',
                  required: true,
                })}
              </Form.Row>
            </>
          )
        }
      </Form.Peek>
    </Form.Legend>

    <Form.Row>
      {configToInput({
        type: 'longtext',
        name: 'notes',
        label: 'Notes',
        ariaLabel: 'Notes',
        placeholder: 'E.g. Customer paid on...',
        required: false,
        height: 6,
      })}
    </Form.Row>

    <Form.Legend label="Total">
      <Form.Peek<FormValues, FormValues['currencyCode']> name="currencyCode">
        {(currencyCode) => (
          <Form.Peek<FormValues, FormValues['lineItems']> name="lineItems">
            {(lineItems) => `${getTotal(lineItems.meta.value)} ${currencyCode.meta.value}`}
          </Form.Peek>
        )}
      </Form.Peek>
    </Form.Legend>
  </BaseForm>
)

export default OrderCreate
