import gql from 'graphql-tag'

export const FRAGMENT = gql`
  fragment UserSession on Session {
    id
    name
    username
    email
    # isAdmin
    token
    avatar {
      url
    }
  }
`

export const SESSION = gql`
  query HeaderSession {
    session {
      ...UserSession
    }
  }

  ${FRAGMENT}
`
