import gql from 'graphql-tag'

import { FRAGMENT as MONEY_FRAGMENT } from '../../../../components/MoneyPreview'

export const INDEX = gql`
  query product_courses_index($page: Int!, $perPage: Int!) {
    productCourses(page: $page, perPage: $perPage, statuses: [LIVE, DRAFT]) {
      nodes {
        id
        title
        status
        order
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`

export const VIEW = gql`
  query product_courses_view($id: ID!) {
    productCourse(id: $id) {
      id
      slug
      title
      shortDescription
      description
      status
      syllabusUrl
      mailchimpTag
      isBeta
      image {
        url
      }
      lowestPrice {
        ...MoneyPreview
      }
    }
  }

  ${MONEY_FRAGMENT}
`
