import gql from 'graphql-tag'

export const PRICES = gql`
  query CurrencyUpdatePrices {
    prices {
      nodes {
        id
        name
        conversions {
          currency {
            id
          }
          value
        }
      }
    }
  }
`

export default gql`
  query currencies_update($id: ID!) {
    currency(id: $id) {
      id
      code
      name
    }
  }
`
