import gql from 'graphql-tag'

export default gql`
  mutation teams_create_mutation(
    $adminEmail: String!
    $plan: TeamPlan!
    $title: String!
    $userEmails: [String!]!
    $stripeSubscriptionId: String!
  ) {
    teamCreate(
      adminEmail: $adminEmail
      plan: $plan
      title: $title
      userEmails: $userEmails
      stripeSubscriptionId: $stripeSubscriptionId
    ) {
      id
    }
  }
`
