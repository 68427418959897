import gql from 'graphql-tag'

export default gql`
  query user_update($id: ID!) {
    user(id: $id) {
      id
      email
      courses {
        nodes {
          id
          accessType
          course {
            id
          }
        }
      }
    }
  }
`
