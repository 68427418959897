import React from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { string } from 'yup'
import { Button, Form, FormSelectInput, Link, Price, SelectInput } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'

import MUTATION from './mutations'
import { INVOICE } from './queries'

import querystring from 'querystring'

export type FormData = {
  reason: API.CreditNoteReason
}

const MembershipRefundCreate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const query = querystring.parse(useLocation().search.substr(1))
  const invoiceId = Array.isArray(query.invoiceId) ? query.invoiceId[0] : query.invoiceId!

  const { data } = useQuery<
    API.membership_refunds_create_invoice,
    API.membership_refunds_create_invoiceVariables
  >(INVOICE, {
    variables: {
      id: invoiceId,
    },
  })

  if (!data?.membershipInvoice) {
    return null
  }

  return (
    <BaseForm<FormData, API.membership_refunds_create_mutationVariables>
      mutation={MUTATION}
      initialValues={{
        reason: API.CreditNoteReason.PRODUCT_UNSATISFACTORY,
      }}
      validationSchema={{
        reason: string().oneOf(Object.values(API.CreditNoteReason)).required(),
      }}
      createVariablesFn={(values) => ({
        ...values,
        membershipInvoiceId: invoiceId,
      })}
      onCompleteUrl={`/membership-invoices/${data.membershipInvoice.id}`}
    >
      <Form.Rows>
        {/* <Form.Legend  label="Customer">
        <div>Name: {data.order.customerDetails.name}</div>
        <div>Email: {data.order.customerDetails.email}</div>

        {data.order.user?.id && (
          <Button version="outline" href={`/users/${data.order.user.id}`}>
            View customer
          </Button>
        )}
      </Form.Legend> */}

        <Form.Legend label="Invoice">
          <Form.Rows>
            <div>
              ID:{' '}
              <Link href={`/membership-invoices/${data.membershipInvoice.id}`}>
                #{data.membershipInvoice.id}
              </Link>
            </div>
            <div>
              User:{' '}
              <Link href={`/users/${data.membershipInvoice.user.id}`}>
                {data.membershipInvoice.user.email}
              </Link>
            </div>
            <div>
              Product:{' '}
              <Link href={`/membership-products/${data.membershipInvoice.product.id}`}>
                {data.membershipInvoice.product.title}
              </Link>
            </div>
            <div>
              Refund total:{' '}
              {data.membershipInvoice
                ? Price.format(
                    data.membershipInvoice.total.value,
                    data.membershipInvoice.total.currencyCode,
                  )
                : ''}
            </div>
          </Form.Rows>
        </Form.Legend>

        <FormSelectInput
          name="reason"
          label="Reason"
          ariaLabel="Reason"
          required
          options={Object.values(API.CreditNoteReason).map((d) => ({ value: d, display: d }))}
        />

        <Form.Message>This will also cancel their membership with immediate effect.</Form.Message>
      </Form.Rows>
    </BaseForm>
  )
}

export default MembershipRefundCreate
