import gql from 'graphql-tag'

export default gql`
  mutation membership_affiliates_create_mutation(
    $applicationFeePercent: Int!
    $name: String!
    $slug: String!
    $stripeAccountId: String!
    $stripeCouponId: String
  ) {
    membershipAffiliateCreate(
      applicationFeePercent: $applicationFeePercent
      name: $name
      slug: $slug
      stripeAccountId: $stripeAccountId
      stripeCouponId: $stripeCouponId
    ) {
      id
      applicationFeePercent
      name
      slug
      stripeAccountId
      stripeCouponId
    }
  }
`
