import { FieldConfig } from '@superhi/utils'
import { array, boolean, mixed, object, string } from 'yup'

import FormMultiSelectCourseChapters from '../../../../../components/FormMultiSelectCourseChapters'
import {
  FIELDS as CREATE_FIELDS,
  VALIDATION_SCHEMA as CREATE_VALIDATION_SCHEMA,
} from '../Create/config'

const CHAPTER_IDS: FieldConfig<typeof FormMultiSelectCourseChapters> = {
  label: 'Chapters',
  ariaLabel: 'Chapters',
  name: 'chapterIds',
  required: false,
}

export const FIELDS = {
  DESCRIPTION: CREATE_FIELDS.DESCRIPTION,
  DIFFICULTY_LEVEL: CREATE_FIELDS.DIFFICULTY_LEVEL,
  LENGTH: CREATE_FIELDS.LENGTH,
  INSTRUCTORS: CREATE_FIELDS.INSTRUCTORS,
  MAILCHIMP_TAG: CREATE_FIELDS.MAILCHIMP_TAG,
  SLACK_CHANNEL_ID: CREATE_FIELDS.SLACK_CHANNEL_ID,
  SLACK_CHANNEL_NAME: CREATE_FIELDS.SLACK_CHANNEL_NAME,
  SYLLABUS_URL: CREATE_FIELDS.SYLLABUS_URL,
  SLUG: CREATE_FIELDS.SLUG,
  STATUS: CREATE_FIELDS.STATUS,
  TITLE: CREATE_FIELDS.TITLE,
  REQUIREMENTS: CREATE_FIELDS.REQUIREMENTS,
  SHORT_DESCRIPTION: CREATE_FIELDS.SHORT_DESCRIPTION,
  SUBJECTS: CREATE_FIELDS.SUBJECTS,
  ORDER: CREATE_FIELDS.ORDER,
  IS_BETA: CREATE_FIELDS.IS_BETA,
  TARGET_AUDIENCE: CREATE_FIELDS.TARGET_AUDIENCE,
  RESOURCE_URL: CREATE_FIELDS.RESOURCE_URL,
  OVERVIEW: CREATE_FIELDS.OVERVIEW,
  HAS_ALL_CHAPTERS: CREATE_FIELDS.HAS_ALL_CHAPTERS,
  IMAGE: { ...CREATE_FIELDS.IMAGE, required: false },
  CHAPTER_IDS,
}

export const VALIDATION_SCHEMA = {
  description: CREATE_VALIDATION_SCHEMA.description,
  difficultyLevel: CREATE_VALIDATION_SCHEMA.difficultyLevel,
  instructors: CREATE_VALIDATION_SCHEMA.instructors,
  length: CREATE_VALIDATION_SCHEMA.length,
  mailchimpTag: CREATE_VALIDATION_SCHEMA.mailchimpTag,
  order: CREATE_VALIDATION_SCHEMA.order,
  requirements: CREATE_VALIDATION_SCHEMA.requirements,
  shortDescription: CREATE_VALIDATION_SCHEMA.shortDescription,
  slackChannelId: CREATE_VALIDATION_SCHEMA.slackChannelId,
  slackChannelName: CREATE_VALIDATION_SCHEMA.slackChannelName,
  syllabusUrl: CREATE_VALIDATION_SCHEMA.syllabusUrl,
  resourceUrl: CREATE_VALIDATION_SCHEMA.resourceUrl,
  slug: CREATE_VALIDATION_SCHEMA.slug,
  subjects: CREATE_VALIDATION_SCHEMA.subjects,
  title: CREATE_VALIDATION_SCHEMA.title,
  overview: CREATE_VALIDATION_SCHEMA.overview,
  targetAudience: CREATE_VALIDATION_SCHEMA.targetAudience,
  isBeta: CREATE_VALIDATION_SCHEMA.isBeta,
  hasAllChapters: CREATE_VALIDATION_SCHEMA.hasAllChapters,
  status: CREATE_VALIDATION_SCHEMA.status,
  image: object({
    url: string().required(),
    value: string(),
    file: mixed(),
  }).required(),
  chapterIds: array().of(string().required()),
}
