import { Price, Body, Button, Link, TextVerticalSpacer } from '@superhi/design'
import { formatDateOnly } from '@superhi/design/dist/cjs/components/utils/DateTime'

import { API } from '../../../../../api'
import { getStripeUrl } from '../../../../../utils/stripe'
import { ModelPageConfigView } from '../../../utils'

import { VIEW } from './queries'

const View: ModelPageConfigView<API.users_view_user> = {
  queryKey: 'user',
  query: VIEW,
  formatData: ({ membership, insertedAt, team, orders, courses, type, ...user }) => {
    const lastInvoice = membership?.invoices
      ? membership.invoices[membership.invoices.length - 1]
      : undefined

    return {
      ...user,
      userType: type,
      membership: membership ? (
        <TextVerticalSpacer level="3">
          <Body level="3">Status: {membership.status}</Body>
          <Body level="3">Auto-renewal: {!!membership.plan?.nextPayment ? 'ON' : 'OFF'}</Body>
          <Body level="3">
            Last payment:{' '}
            {!!lastInvoice
              ? `${Price.format(
                  lastInvoice.total.value,
                  lastInvoice.total.currencyCode,
                )} on ${formatDateOnly(lastInvoice.createdAt)}`
              : 'None'}
          </Body>
          <Body level="3">
            Next payment:{' '}
            {!!membership.plan?.nextPayment
              ? `${Price.format(
                  membership.plan?.nextPayment?.value,
                  membership.plan?.nextPayment?.currencyCode,
                )} on ${formatDateOnly(membership.plan?.renewalDate)}`
              : 'None'}
          </Body>
          {membership?.plan?.stripeSubscriptionId && (
            <Button
              href={getStripeUrl(`/subscriptions/${membership.plan.stripeSubscriptionId}`)}
              fullWidth
              version="outline"
              isExternal
            >
              View Subscription in Stripe
            </Button>
          )}
        </TextVerticalSpacer>
      ) : team ? (
        <div>
          Has a team membership through <Link href={`/teams/${team.id}`}>{team.title}</Link>
        </div>
      ) : null,
      team: team && <Link href={`/teams/${team.id}`}>{team.title}</Link>,
      courseAccess: courses?.nodes,
      orders: orders?.nodes,
      joined: insertedAt,
    }
  },
}

export default View
