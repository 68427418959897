import { FormLongTextInput, FormTextInput } from '@superhi/design'
import { FieldConfig } from '@superhi/utils'
import { omit } from 'lodash'
import { array, mixed, object, string } from 'yup'

import MultiSelectCourseLesson from '../../../../../components/FormMultiSelectCourseLessons'
import {
  FIELDS as CREATE_FIELDS,
  VALIDATION_SCHEMA as CREATE_VALIDATION_SCHEMA,
} from '../Create/config'

const LESSONS: FieldConfig<typeof MultiSelectCourseLesson> = {
  ariaLabel: 'Lessons',
  label: 'Lessons',
  name: 'lessons',
  placeholder: 'E.g.',
  required: true,
}

const DESCRIPTION: FieldConfig<typeof FormLongTextInput> = {
  ariaLabel: 'Description',
  label: 'Description',
  name: 'description',
  placeholder: 'E.g.',
  required: true,
  height: 6,
}

const SLUG: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Slug',
  label: 'Slug',
  name: 'slug',
  placeholder: 'E.g.',
  required: true,
}

const TITLE: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Title',
  label: 'Title',
  name: 'title',
  placeholder: 'E.g.',
  required: true,
}

export const FIELDS = {
  SLUG,
  TITLE,
  DESCRIPTION,
  LESSONS,
  HOMEWORK_ID: CREATE_FIELDS.HOMEWORK_ID,
  PROJECT_ID: CREATE_FIELDS.PROJECT_ID,
  RESOURCE_URL: CREATE_FIELDS.RESOURCE_URL,
  IS_LOCKED: CREATE_FIELDS.IS_LOCKED,
}

export const VALIDATION_SCHEMA = {
  homeworkId: CREATE_VALIDATION_SCHEMA.homeworkId,
  projectId: CREATE_VALIDATION_SCHEMA.projectId,
  resourceUrl: CREATE_VALIDATION_SCHEMA.resourceUrl,
  isLocked: CREATE_VALIDATION_SCHEMA.isLocked,
  slug: string().required(),
  title: string().required(),
  description: string().required(),
  lessons: array().of(string().required()).required(),
}
