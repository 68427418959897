import gql from 'graphql-tag'

export default gql`
  query courses_update($id: ID!) {
    course(id: $id) {
      id
      description
      difficultyLevel
      image {
        url
      }
      instructors {
        id
      }
      isBeta
      length
      resourceUrl
      mailchimpTag
      requirements
      shortDescription
      status
      order
      hasAllChapters
      slackChannelId
      slackChannelName
      syllabusUrl
      overview
      targetAudience
      slug
      subjects
      title
      chapters {
        nodes {
          id
        }
      }
    }
  }
`
