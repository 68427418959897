import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import {
  Form,
  FormCheckboxInput,
  FormLongTextInput,
  FormMultiSelectInput,
  FormTextInput,
} from '@superhi/design'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'
import MultiSelectCourseLessons from '../../../../../components/FormMultiSelectCourseLessons'
import FormSelectCourseHomework from '../../../../../components/FormSelectCourseHomework'
import FormSelectCourseProject from '../../../../../components/FormSelectCourseProject'

import { FormValues } from './types'
import QUERY from './queries'
import MUTATION from './mutations'
import { FIELDS, VALIDATION_SCHEMA } from './config'

const CourseChaptersUpdate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.course_chapters_update, API.course_chapters_updateVariables>(
    QUERY,
    {
      variables: {
        id,
      },
    },
  )

  if (!data?.courseChapter) {
    return null
  }

  return (
    <BaseForm<FormValues, API.course_chapters_update_mutationVariables>
      mutation={MUTATION}
      validationSchema={VALIDATION_SCHEMA}
      initialValues={{
        slug: data.courseChapter.slug,
        title: data.courseChapter.title,
        description: data.courseChapter.description,
        lessons: data.courseChapter.lessons?.nodes.map((lesson) => lesson.id) || [],
        homeworkId: data.courseChapter.homework?.id,
        projectId: data.courseChapter.project?.id,
        resourceUrl: data.courseChapter.resourceUrl || '',
        isLocked: data.courseChapter.isLocked,
      }}
      createVariablesFn={(values) => ({
        ...values,
        id,
        homeworkId: values.homeworkId,
        projectId: values.projectId,
        resourceUrl: values.resourceUrl,
      })}
    >
      <Form.Rows>
        <FormTextInput {...FIELDS.TITLE} />
        <FormTextInput {...FIELDS.SLUG} />
        <FormLongTextInput {...FIELDS.DESCRIPTION} />
        <MultiSelectCourseLessons {...FIELDS.LESSONS} />
        <FormSelectCourseHomework {...FIELDS.HOMEWORK_ID} />
        <FormSelectCourseProject {...FIELDS.PROJECT_ID} />
        <FormTextInput {...FIELDS.RESOURCE_URL} />
        <FormCheckboxInput {...FIELDS.IS_LOCKED} />
      </Form.Rows>
    </BaseForm>
  )
}

export default CourseChaptersUpdate
