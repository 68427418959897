import gql from 'graphql-tag'

import { FRAGMENT as PRODUCT_VARIANT_FRAGMENT } from '../../../components/ProductVariantPreview'

export const INDEX = gql`
  query product_fulfillables_index($page: Int!, $perPage: Int!) {
    productFulfillables(page: $page, perPage: $perPage) {
      nodes {
        id
        title
        sku
        fulfillmentId
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`

export const VIEW = gql`
  query product_fulfillables_view($id: ID!) {
    productFulfillable(id: $id) {
      id
      title
      sku
      description
      fulfillmentId

      variants {
        nodes {
          ...ProductVariantPreview
        }
      }
    }
  }

  ${PRODUCT_VARIANT_FRAGMENT}
`

export const DELETE_VIEW = gql`
  query product_fulfillables_delete_view($id: ID!) {
    productFulfillable(id: $id) {
      id
      variants {
        nodes {
          id
          title
          product {
            id
            title
          }
        }
      }
    }
  }
`
