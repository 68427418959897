import gql from 'graphql-tag'

export default gql`
  mutation RefundCancel($id: ID!) {
    refundCancel(id: $id) {
      id
      status
    }
  }
`
