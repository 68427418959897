import { createModelPage } from '../utils'
import { API } from '../../../api'

import Index from './pages/Index'
import Update from './pages/Update'
import { VIEW } from './queries'

export default createModelPage<unknown, API.users_view_user>({
  name: 'Settings',
  index: Index,
  view: {
    queryKey: 'settings',
    query: VIEW,
  },
  edit: Update,
})
