import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@superhi/design'
import { HelmetProvider } from 'react-helmet-async'

import initApollo from '../../api'
import PAGES from '../../pages'
import ReactRouterLink from '../ReactRouterLink'

import Layout from './components/Layout'

const apolloClient = initApollo({ initialState: {} })

const App: React.FC<React.PropsWithChildren<unknown>> = () => (
  <HelmetProvider>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider LinkComponent={ReactRouterLink}>
        <Router>
          <Layout>
            <Switch>
              {PAGES.map(({ Component, ...rest }, i) => (
                <Route {...rest} key={i}>
                  <Component />
                </Route>
              ))}
            </Switch>
          </Layout>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  </HelmetProvider>
)

export default App
