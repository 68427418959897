import gql from 'graphql-tag'

export const INDEX = gql`
  query refund_index($page: Int, $perPage: Int) {
    refunds(page: $page, perPage: $perPage) {
      nodes {
        id
        order {
          id
        }
        status
      }
    }
  }
`

export const VIEW = gql`
  query refund_view($id: ID!) {
    refund(id: $id) {
      id
      lineItems {
        nodes {
          id
          quantity
          status
          orderLineItem {
            product {
              id
            }
            variant {
              title
            }
          }
        }
      }

      order {
        id
        insertedAt
        customerDetails {
          name
        }
      }

      notes

      total {
        value
        currencyCode
      }
      exchangeItems {
        nodes {
          id
          quantity
          variant {
            title
          }
        }
      }

      basket {
        id
        status
      }

      payments {
        nodes {
          id
          value {
            value
            currencyCode
          }
          insertedAt
        }
      }
      status
      insertedAt
      updatedAt
    }
  }
`
