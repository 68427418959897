import { gql } from '@apollo/client'

export default gql`
  fragment OrderLineItemPreview on OrderLineItem {
    id
    title
    price {
      currencyCode
      value
    }
    product {
      id
      title
    }
    quantity
  }
`
