import React from 'react'
import { boolean, string } from 'yup'
import { Form } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import { configToInput } from '../../utils'
import * as config from '../config'

import MUTATION from './mutations'

type FormData = {
  title: string
  description: string
  fulfillmentId: string
  sku: string
  allowMultiplePerOrder: boolean
}

const ProductFulfillableCreate: React.FC<React.PropsWithChildren<unknown>> = () => (
  <BaseForm<FormData, API.ProductFulfillableCreateVariables>
    validationSchema={{
      title: string().required(),
      description: string().required(),
      fulfillmentId: string().required(),
      sku: string().required(),
      allowMultiplePerOrder: boolean().required(),
    }}
    mutation={MUTATION}
    initialValues={{
      title: '',
      description: '',
      fulfillmentId: '',
      sku: '',
      allowMultiplePerOrder: false,
    }}
    createVariablesFn={(values) => values}
  >
    <Form.Row>{configToInput(config.TITLE)}</Form.Row>

    <Form.Row>{configToInput(config.DESCRIPTION)}</Form.Row>

    <Form.Row>{configToInput(config.FULFILLMENT_ID)}</Form.Row>

    <Form.Row>{configToInput(config.SKU)}</Form.Row>

    <Form.Row>{configToInput(config.ALLOW_MULTIPLE_PER_ORDER)}</Form.Row>
  </BaseForm>
)

export default ProductFulfillableCreate
