import styled from 'styled-components'
import { theme } from '@superhi/design'

export const Wrapper = styled.table`
  width: 100%;
  background-color: white;
  border-spacing: 0;
  border: 0;
  border-top: 1px solid ${theme.colors.GREY_50};
  border-bottom: 1px solid ${theme.colors.GREY_50};
  max-width: 900px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  > tbody {
    > tr {
      &:not(:first-child) {
        > td,
        > th {
          border-top: 1px solid ${theme.colors.GREY_50};
        }
      }

      &:hover {
        > td,
        > th {
          background-color: ${theme.colors.GREY_10};
        }
      }

      > th {
        border-right: 1px solid ${theme.colors.GREY_50};
        font-weight: normal;
        width: 1px;
        vertical-align: top;
      }

      > td {
        &.actions {
          a {
            margin-right: 8px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      > td,
      > th {
        text-align: left;

        &:not(.isNested) {
          padding: 24px;
        }

        &:not(.isNestedArray) {
          padding: 24px;
        }
      }
    }
  }
`
