import styled from 'styled-components'
import { theme, SITE_HEADER_HEIGHT } from '@superhi/design'

export const Wrapper = styled.main<{ $isNotProd: boolean }>`
  display: grid;
  grid-template-columns: 1fr minmax(300px, 20vw);
  grid-template-rows: min-content min-content 1fr;
  grid-template-areas: 'nav nav' 'content-header content-actions' 'content content-actions';
  height: calc(100vh - ${SITE_HEADER_HEIGHT.small}px);
  border-top: ${({ $isNotProd }) =>
    `1px solid ${$isNotProd ? theme.colors.RED_50 : theme.colors.GREY_50}`};
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    display: ${({ $isNotProd }) => ($isNotProd ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.RED_50};
  }

  &:after {
    content: 'STAGING';
    font-weight: bold;
    display: ${({ $isNotProd }) => ($isNotProd ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${theme.colors.RED_50};
    padding: 0.2em 0.5em;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    font-size: 14px;
    color: white;
  }

  @media ${theme.breakpoints.medium} {
    height: calc(100vh - ${SITE_HEADER_HEIGHT.medium}px);
  }

  @media ${theme.breakpoints.large} {
    height: calc(100vh - ${SITE_HEADER_HEIGHT.large}px);
  }
`

export const Content = styled.section`
  grid-area: content;
`
