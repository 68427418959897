import { Body } from '@superhi/design'

type Props = { children?: boolean | null }

const BooleanPreview = ({ children }: Props) => (
  <Body level="3" color={!!children ? 'GREEN_50' : 'RED_50'}>
    {(!!children).toString()}
  </Body>
)

export default BooleanPreview
