import { API } from '../../../../../api'
import { PageView } from '../../../utils'

import { QUERY } from './queries'

const View: PageView<API.course_lessons_view_courseLesson> = {
  queryKey: 'courseLesson',
  query: QUERY,
  // formatData: ({ ...data }) => ({
  //   ...data,
  // }),
}

export default View
