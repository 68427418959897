import { mixed, object, string } from 'yup'

import {
  FIELDS as CREATE_FIELDS,
  VALIDATION_SCHEMA as CREATE_VALIDATION_SCHEMA,
} from '../Create/config'

export const FIELDS = {
  ...CREATE_FIELDS,
  IMAGE: { ...CREATE_FIELDS.IMAGE, required: false },
}

export const VALIDATION_SCHEMA = {
  ...CREATE_VALIDATION_SCHEMA,
  image: object({
    url: string().required(),
    value: string(),
    file: mixed(),
  }).required(),
}
