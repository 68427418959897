import React from 'react'
import { Button, Price } from '@superhi/design'

import { createModelPage } from '../utils'
import { API } from '../../../api'

import Update from './Update'
import Create from './Create'
import { INDEX, VIEW } from './queries'

export default createModelPage<
  API.membership_products_index_membershipProducts_nodes[],
  API.membership_products_view_membershipProduct
>({
  name: 'Membership Products',
  index: {
    queryKey: 'membershipProducts',
    query: INDEX,
    formatData: (data) => data,
  },
  view: {
    queryKey: 'membershipProduct',
    query: VIEW,
    actions: (id, data) => (
      <Button
        href={`https://dashboard.stripe.com/${
          process.env.REACT_APP_API_DOMAIN?.includes('staging') ? 'test/' : ''
        }products/${data.stripeProductId}`}
        fullWidth
        version="outline"
        isExternal
      >
        View in Stripe
      </Button>
    ),
  },
  create: Create,
  edit: Update,
})
