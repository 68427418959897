import { API } from '../../../api'

export const transformDiscountClauses = (clauses: API.discounts_view_discount['clauses']) =>
  clauses.map((clause) =>
    clause.expressions?.reduce(
      (acc, expression) => {
        switch (expression.__typename) {
          case 'DiscountExpressionCount': {
            const countExpression =
              expression as API.discounts_view_discount_clauses_expressions_DiscountExpressionCount
            const { __typename, ...value } = countExpression.countValue

            return {
              ...acc,
              countExpressions: [...acc.countExpressions, value],
            }
          }
          case 'DiscountExpressionType': {
            const typeExpression =
              expression as API.discounts_view_discount_clauses_expressions_DiscountExpressionType
            const { __typename, ...value } = typeExpression.typeValue

            return {
              ...acc,
              typeExpressions: [...acc.typeExpressions, value],
            }
          }
          case 'DiscountExpressionProduct': {
            const productExpression =
              expression as API.discounts_view_discount_clauses_expressions_DiscountExpressionProduct
            const { __typename, ...value } = productExpression.productValue

            return {
              ...acc,
              productExpressions: [...acc.productExpressions, value],
            }
          }
          default: {
            return acc
          }
        }
      },
      {
        typeExpressions: [],
        countExpressions: [],
        productExpressions: [],
      } as any,
    ),
  )
