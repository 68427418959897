import gql from 'graphql-tag'

export default gql`
  query membership_affiliates_update($id: ID!) {
    membershipAffiliate(id: $id) {
      id
      name
      slug
      stripeAccountId
      stripeCouponId
      applicationFeePercent
    }
  }
`
