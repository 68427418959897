import gql from 'graphql-tag'

export default gql`
  mutation membership_products_create_mutation(
    $description: String!
    $mailchimpTag: String!
    $slug: String!
    $stripeProductId: String!
    $title: String!
  ) {
    membershipProductCreate(
      description: $description
      mailchimpTag: $mailchimpTag
      slug: $slug
      stripeProductId: $stripeProductId
      title: $title
    ) {
      id
    }
  }
`
