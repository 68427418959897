import gql from 'graphql-tag'

export default gql`
  mutation ScholarshipUpdateMutation(
    $id: ID!
    $applicationUrl: String!
    $body: String!
    $closingAt: DateTime!
    $image: Upload
    $intro: String!
    $publishedAt: DateTime!
    $slug: String!
    $title: String!
    $winnersNotifiedAt: DateTime!
  ) {
    scholarshipUpdate(
      id: $id
      applicationUrl: $applicationUrl
      body: $body
      closingAt: $closingAt
      image: $image
      intro: $intro
      publishedAt: $publishedAt
      slug: $slug
      title: $title
      winnersNotifiedAt: $winnersNotifiedAt
    ) {
      id
    }
  }
`
