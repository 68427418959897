import { boolean, mixed, object, ObjectSchemaDefinition, string } from 'yup'
import { FormCheckboxInput, FormLongTextInput, FormTextInput } from '@superhi/design'
import { FieldConfig } from '@superhi/utils'

import InputImage from '../../../../../components/InputImage'

import { FormValues } from './types'

const DESCRIPTION: FieldConfig<typeof FormLongTextInput> = {
  ariaLabel: 'Description',
  label: 'Description',
  name: 'description',
  placeholder: 'E.g.',
  height: 6,
  required: true,
}

const FINISHED_URL: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Finished URL',
  label: 'Finished URL',
  name: 'finishedUrl',
  type: 'url',
  placeholder: 'E.g.',
}

const BRIEF_URL: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Brief URL',
  label: 'Brief URL',
  name: 'briefUrl',
  type: 'url',
  placeholder: 'E.g.',
}

const IMAGE: FieldConfig<typeof InputImage> = {
  label: 'Image',
  ariaLabel: 'Image',
  name: 'image',
  required: true,
}

const SLUG: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Slug',
  label: 'Slug',
  name: 'slug',
  placeholder: 'E.g.',
  required: true,
}

const TITLE: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Title',
  label: 'Title',
  name: 'title',
  placeholder: 'E.g.',
  required: true,
}

const IS_REQUIRED: FieldConfig<typeof FormCheckboxInput> = {
  ariaLabel: 'Is required?',
  label: 'Is required?',
  name: 'isRequired',
  required: true,
}

export const FIELDS = {
  DESCRIPTION,
  FINISHED_URL,
  BRIEF_URL,
  IMAGE,
  SLUG,
  TITLE,
  IS_REQUIRED,
}

export const VALIDATION_SCHEMA: ObjectSchemaDefinition<FormValues> = {
  description: string().required(),
  finishedUrl: string(),
  briefUrl: string(),
  image: object({
    url: string(),
    value: string().required(),
    file: mixed().required(),
  }).required(),
  slug: string().required(),
  title: string().required(),
  isRequired: boolean().required(),
}
