import { Price } from '@superhi/design'

import { API } from '../../api'
import StringPreview from '../StringPreview'

type Props = API.StripePricePreview

const StripePricePreview: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  currencyCode,
  recurring,
}) => (
  <StringPreview>{`${Price.format(value, currencyCode)}${
    recurring ? ` per ${recurring.intervalCount} ${recurring.interval}` : ''
  }`}</StringPreview>
)

export { default as FRAGMENT } from './fragments'
export default StripePricePreview
