import { Body, Link } from '@superhi/design'

import { API } from '../../../../../api'

type Props = Partial<API.CourseProjectPreview> & {}

const CourseProjectPreview: React.FC<React.PropsWithChildren<Props>> = ({ id, title }) => {
  const exists = !!id

  return (
    <div>
      <Body level="3" bold={exists} inline color={exists ? 'GREY_90' : 'GREY_60'}>
        {exists ? <Link href={`/course-projects/${id}`}>[Project] {title}</Link> : `<No project>`}
      </Body>
    </div>
  )
}

export { default as FRAGMENT } from './fragments'
export default CourseProjectPreview
