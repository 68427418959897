import gql from 'graphql-tag'

export default gql`
  query course_homeworks_update($id: ID!) {
    courseHomework(id: $id) {
      id
      description
      files {
        url
      }
      finishedUrl
      briefUrl
      isRequired
      image {
        id
        url
      }
      links {
        id
        url
      }
      slug
      title
    }
  }
`
