import gql from 'graphql-tag'

import { FRAGMENT as MEMBERSHIP_PRODUCT_FRAGMENT } from '../membershipProducts/components/MembershipProductPreview'
import { FRAGMENT as USER_FRAGMENT } from '../users/components/UserPreview'
import { FRAGMENT as MONEY_FRAGMENT } from '../../../components/MoneyPreview'
//
export const INDEX = gql`
  query membership_invoices_index {
    membershipInvoices {
      # (page: $page, perPage: $perPage) {
      nodes {
        id
        # product {
        #   id
        #   title
        # }
        status
        user {
          id
          email
        }
        total {
          value
          currencyCode
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`

export const VIEW = gql`
  query membership_invoices_view($id: ID!) {
    membershipInvoice(id: $id) {
      id
      product {
        ...MembershipProductPreview
      }
      value {
        ...MoneyPreview
      }
      status
      payments {
        insertedAt
        status
        refunds {
          insertedAt
          reason
          value {
            currencyCode
            value
          }
        }
        value {
          currencyCode
          value
        }
      }
      totalDiscount {
        ...MoneyPreview
      }
      user {
        ...UserPreviewComponent
      }
      total {
        ...MoneyPreview
      }
      createdAt
    }
  }

  ${MONEY_FRAGMENT}
  ${USER_FRAGMENT}
  ${MEMBERSHIP_PRODUCT_FRAGMENT}
`
