import gql from 'graphql-tag'

export default gql`
  mutation orders_create_mutation(
    $customerDetails: OrderCustomerDetailsInput!
    $shippingDetails: OrderAddressInput
    $lineItems: [OrderLineItemInput!]!
    $total: MoneyInput!
    $notes: String
  ) {
    orderCreate(
      customerDetails: $customerDetails
      shippingDetails: $shippingDetails
      lineItems: $lineItems
      total: $total
      notes: $notes
    ) {
      id
    }
  }
`
