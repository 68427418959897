import gql from 'graphql-tag'

export const QUERY = gql`
  query people_view($id: ID!) {
    person(id: $id) {
      id
      name
      image {
        url
      }
      description
      jobTitle
      location
      facebook
      instagram
      twitter
    }
  }
`
