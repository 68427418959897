import React, { useState } from 'react'
import { OperationVariables, useQuery } from '@apollo/client'
import { TextInput, Modal, Button, CheckboxInput, TextVerticalSpacer } from '@superhi/design'
import { DocumentNode } from 'graphql'
import { useDebounce } from 'react-use'

type Item = {
  id: string
  children: React.ReactNode
}

export type Props<QueryData, QueryVariables extends OperationVariables> = Pick<
  React.ComponentProps<typeof Modal>,
  'onClose'
> & {
  query: DocumentNode
  createQueryVariablesFn: (searchValue: string) => QueryVariables
  queryDataToItemsFn: (data: QueryData) => Item[]
  onDone: (ids: string[]) => void
  selected: string[]
  title: string
}

function Search<QueryData, QueryVariables>({
  createQueryVariablesFn,
  queryDataToItemsFn,
  onClose,
  onDone,
  selected,
  query,
  title,
  ...props
}: Props<QueryData, QueryVariables>) {
  const [search, setSearch] = useState('')
  const [searchVariable, setSearchVariable] = useState('')
  const [items, setItems] = React.useState<string[]>(selected)
  const theQuery = useQuery<QueryData>(query, {
    variables: createQueryVariablesFn(searchVariable),
    fetchPolicy: 'cache-and-network',
  })

  const data = theQuery.data ? queryDataToItemsFn(theQuery.data) : []

  const handleDone = () => {
    onDone(items)
    onClose()
  }

  useDebounce(() => setSearchVariable(search), 500, [search])

  return (
    <Modal title={title} onClose={onClose}>
      <TextInput
        id="search"
        ariaLabel="search"
        name="search"
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <TextVerticalSpacer level="4">
        {data.map((item) => (
          <CheckboxInput
            id={item.id}
            ariaLabel="whatever"
            key={item.id}
            name="whatever"
            value={items.includes(item.id)}
            onChange={(v) =>
              setItems((state) => (v ? [...state, item.id] : state.filter((i) => i !== item.id)))
            }
          >
            {item.children}
          </CheckboxInput>
        ))}
      </TextVerticalSpacer>

      <Button fullWidth onClick={handleDone}>
        Done
      </Button>
    </Modal>
  )
}

export default Search
