import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Form, FormLongTextInput, FormSelectInput, FormTextInput } from '@superhi/design'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'

import { FormValues } from './types'
import { FIELDS, VALIDATION_SCHEMA } from './config'
import QUERY from './queries'
import MUTATION from './mutations'

const TeamsUpdate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.teams_update, API.teams_updateVariables>(QUERY, {
    variables: {
      id,
    },
  })

  if (!data?.team) {
    return null
  }

  return (
    <BaseForm<FormValues, API.teams_update_mutationVariables>
      mutation={MUTATION}
      validationSchema={VALIDATION_SCHEMA}
      initialValues={{
        title: data.team.title,
        plan: data.team.plan,
        adminEmail: data.team.adminEmail,
        userEmails: data.team.users.map((user) => user.email).join('\n'),
      }}
      createVariablesFn={(values) => ({
        ...values,
        id,
        userEmails: values.userEmails
          .split('\n')
          .map((v) => v.trim())
          .filter(Boolean),
      })}
    >
      <Form.Rows>
        <FormTextInput {...FIELDS.TITLE} />
        <FormSelectInput {...FIELDS.PLAN} />
        <FormTextInput {...FIELDS.ADMIN_EMAIL} />
        <FormLongTextInput {...FIELDS.USER_EMAILS} />
      </Form.Rows>
    </BaseForm>
  )
}

export default TeamsUpdate
