import gql from 'graphql-tag'

export const QUERY = gql`
  query course_lessons_view($id: ID!) {
    courseLesson(id: $id) {
      id
      slug
      title
      description
      duration
      position
      wistiaId
    }
  }
`
