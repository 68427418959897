import { gql } from '@apollo/client'

export const QUERY = gql`
  query MultiSelectCourseChapters($ids: [ID!]) {
    courseChapters(ids: $ids) {
      nodes {
        id
        title
      }
    }
  }
`

export const SEARCH_QUERY = gql`
  query MultiSelectCourseChapters_Search {
    courseChapters(perPage: 1000) {
      nodes {
        id
        title
      }
    }
  }
`
