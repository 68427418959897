import { LinkComponentProps } from '@superhi/design/dist/cjs/components/navigation/Link/Link'
import React from 'react'
import { Link } from 'react-router-dom'

const ReactRouterLink: React.FC<React.PropsWithChildren<LinkComponentProps>> = ({
  href,
  ...props
}) => <Link {...props} to={href} />

export default ReactRouterLink
