import gql from 'graphql-tag'

export default gql`
  query SelectPrice {
    prices(perPage: 100) {
      nodes {
        id
        name
      }
    }
  }
`
