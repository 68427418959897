import { TextVerticalSpacer, theme } from '@superhi/design'
import styled from 'styled-components'

export const Payouts = styled(TextVerticalSpacer)`
  > *:not(:last-child) {
    &:after {
      display: block;
      content: '';
      background-color: ${theme.colors.GREY_50};
      height: 1px;
      margin: 16px 0;
    }
  }
`
