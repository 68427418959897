import gql from 'graphql-tag'

export default gql`
  mutation DiscountUpdateMutation(
    $id: ID!
    $clauses: [DiscountClauseInput!]!
    $code: String!
    $description: String
    $endAt: DateTime
    $isPaused: Boolean
    $isStackable: Boolean!
    $name: String!
    $percent: Int!
    $startAt: DateTime
    $type: DiscountType!
  ) {
    discountUpdate(
      id: $id
      clauses: $clauses
      code: $code
      description: $description
      endAt: $endAt
      isPaused: $isPaused
      name: $name
      percent: $percent
      startAt: $startAt
      type: $type
      isStackable: $isStackable
    ) {
      id
    }
  }
`
