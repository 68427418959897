import { Price } from '@superhi/design'

import { createModelPage } from '../utils'
import { API } from '../../../api'

import Create from './Create'
import Update from './Update'
import { INDEX, VIEW } from './queries'
import { DELETE } from './mutations'

export default createModelPage<API.prices_index_prices_nodes[], API.prices_view_price>({
  name: 'Prices',
  index: {
    queryKey: 'prices',
    query: INDEX,
    formatData: (prices) =>
      prices.map((price) => ({
        id: price.id,
        name: price.name,
        conversions: price.conversions
          .map((conversion) =>
            Price.format(conversion.value, conversion.currency.code as API.CurrencyCode),
          )
          .join(', '),
      })),
  },
  view: {
    queryKey: 'price',
    query: VIEW,
  },
  create: Create,
  edit: Update,
  destroy: {
    mutation: DELETE,
  },
})
