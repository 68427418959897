import gql from 'graphql-tag'

import { FRAGMENT as PRODUCT_VARIANT_FRAGMENT } from '../../../components/ProductVariantPreview'
import { FRAGMENT as MONEY_FRAGMENT } from '../../../components/MoneyPreview'

export const INDEX = gql`
  query product_collections_index($page: Int!, $perPage: Int!) {
    productCollections(page: $page, perPage: $perPage) {
      nodes {
        id
        title
        variants {
          nodes {
            title
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`

export const VIEW = gql`
  query product_collections_view($id: ID!) {
    productCollection(id: $id) {
      id
      title
      description
      variants {
        nodes {
          ...ProductVariantPreview
        }
      }
      fullPrice {
        ...MoneyPreview
      }
      price {
        ...MoneyPreview
      }
    }
  }

  ${PRODUCT_VARIANT_FRAGMENT}
  ${MONEY_FRAGMENT}
`
