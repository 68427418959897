import React from 'react'
import { array, boolean, mixed, number, object, string } from 'yup'
import {
  Form,
  FormCheckboxInput,
  FormLongTextInput,
  FormMultiSelectInput,
  FormSelectInput,
  FormTextInput,
} from '@superhi/design'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import BaseForm from '../../../_templates/BaseForm'
import { API } from '../../../../../api'
import {
  CREATE_VARIABLES_UPDATE as BASE_CREATE_VARIABLES_UPDATE,
  INITIAL_VALUES_UPDATE as BASE_INITIAL_VALUES_UPDATE,
  VALIDATION_SCHEMA_UPDATE as BASE_VALIDATION_SCHEMA_UPDATE,
} from '../../config'
import { FormUpdate } from '../../Form'
import { InitialValuesUpdate as BaseInitialValuesUpdate } from '../../types'
import * as config from '../config'
import MultiSelectPerson from '../../../../../components/MultiSelectPerson'
import SelectCourse from '../../../../../components/SelectCourse'
import InputImage from '../../../../../components/InputImage'

import QUERY from './queries'
import MUTATION from './mutations'

type InitialValues = BaseInitialValuesUpdate & {
  difficultyLevel: API.ProductCourseDifficultyLevel
  length: API.ProductCourseLength
  requirements: string
  subjects: API.ProductCourseSubject[]
  syllabusUrl: string
  targetAudience: string
  overview: string[]
  courseId: string
  order: number
  instructorIds?: string[]
  projects: {
    id?: string
    title: string
    description: string
    image: {
      url?: string
      value?: string
      file?: any
    }
    videoId?: string
  }[]
  status: API.ProductStatus
  mailchimpTag: string
  isBeta: boolean
  trailerWistiaId?: string
}

const ProductCourseUpdate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.product_courses_update>(QUERY, {
    variables: {
      id,
    },
  })

  if (!data?.productCourse) {
    return null
  }

  return (
    <BaseForm<InitialValues, API.ProductCourseUpdateMutationVariables>
      mutation={MUTATION}
      initialValues={{
        ...BASE_INITIAL_VALUES_UPDATE(data.productCourse),
        difficultyLevel: data.productCourse.difficultyLevel,
        length: data.productCourse.length,
        requirements: data.productCourse.requirements,
        subjects: data.productCourse.subjects as API.ProductCourseSubject[],
        syllabusUrl: data.productCourse.syllabusUrl,
        targetAudience: data.productCourse.targetAudience,
        overview: data.productCourse.overview,
        order: data.productCourse.order,
        instructorIds: data.productCourse.instructors?.map((instructor) => instructor.id),
        courseId: data.productCourse.course.id,
        projects:
          data.productCourse.projects
            ?.map((project) => ({
              ...project,
              typename: undefined,
              image: {
                url:
                  project.__typename === 'ProductCourseProjectImage'
                    ? project.image.url
                    : undefined,
                file: undefined,
                value: undefined,
              },
            }))
            .map(({ __typename, ...project }) => project) || [],
        status: data.productCourse.status,
        mailchimpTag: data.productCourse.mailchimpTag,
        isBeta: data.productCourse.isBeta,
        trailerWistiaId: data.productCourse.trailerWistiaId || '',
      }}
      validationSchema={{
        ...BASE_VALIDATION_SCHEMA_UPDATE,
        subjects: array()
          .of(string().oneOf(Object.values(API.ProductCourseSubject)).required())
          .required(),
        difficultyLevel: string().oneOf(Object.values(API.ProductCourseDifficultyLevel)).required(),
        length: string().oneOf(Object.values(API.ProductCourseLength)).required(),
        requirements: string().required(),
        syllabusUrl: string().required(),
        targetAudience: string().required(),
        overview: array().of(string().required()).required(),
        instructorIds: array().of(string().required()),
        courseId: string().required(),
        order: number().required(),
        projects: array()
          .of(
            object({
              id: string(),
              title: string().required(),
              description: string().required(),
              image: object({
                url: string(),
                value: string(),
                file: mixed(),
              }).required(),
              videoId: string(),
            }).required(),
          )
          .required(),
        status: string().oneOf(Object.values(API.ProductStatus)).required(),
        mailchimpTag: string().required(),
        isBeta: boolean().required(),
        trailerWistiaId: string(),
      }}
      createVariablesFn={({ options, ...formValues }) => ({
        ...BASE_CREATE_VARIABLES_UPDATE({ ...formValues, id }),
        difficultyLevel: formValues.difficultyLevel,
        length: formValues.length,
        requirements: formValues.requirements,
        subjects: formValues.subjects,
        syllabusUrl: formValues.syllabusUrl,
        targetAudience: formValues.targetAudience,
        order: formValues.order,
        overview: formValues.overview,
        instructorIds: formValues.instructorIds,
        courseId: formValues.courseId,
        projects: formValues.projects.map(({ image, ...project }) => ({
          ...project,
          image: image.file,
        })),
        status: formValues.status,
        mailchimpTag: formValues.mailchimpTag,
        isBeta: formValues.isBeta,
        trailerWistiaId: formValues.trailerWistiaId,
      })}
    >
      <Form.Rows>
        <FormUpdate />

        <FormSelectInput {...config.STATUS} />

        <FormSelectInput {...config.DIFFICULTY_LEVEL} />

        <FormSelectInput {...config.LENGTH} />

        <FormTextInput {...config.ORDER} />

        <FormCheckboxInput {...config.IS_BETA} />

        <FormTextInput {...config.TRAILER_WISTIA_ID} />

        <FormLongTextInput {...config.REQUIREMENTS} />

        <MultiSelectPerson {...config.INSTRUCTOR_IDS} />

        <FormMultiSelectInput {...config.SUBJECTS} />

        <FormTextInput {...config.SYLLABUS_URL} />

        <FormTextInput {...config.MAILCHIMP_TAG} />

        <FormLongTextInput {...config.TARGET_AUDIENCE} />

        <Form.Repeatable<InitialValues, 'overview'>
          name="overview"
          label="What youll learn"
          defaultValue={''}
        >
          {({ name }) => <FormTextInput {...config.OVERVIEW(name)} />}
        </Form.Repeatable>

        <Form.Repeatable<InitialValues, 'projects'>
          name="projects"
          label="Projects"
          defaultValue={{
            title: '',
            description: '',
            image: {
              url: '',
              file: undefined,
              value: undefined,
            },
            videoId: '',
          }}
        >
          {({ name }) => (
            <>
              <FormTextInput {...config.PROJECTS_TITLE(name)} />

              <FormLongTextInput {...config.PROJECTS_DESCRIPTION(name)} />

              <InputImage {...{ ...config.PROJECTS_IMAGE(name), required: false }} />

              <FormTextInput {...{ ...config.PROJECTS_VIDEO_ID(name), required: false }} />
            </>
          )}
        </Form.Repeatable>

        <SelectCourse {...config.COURSE_ID} />
      </Form.Rows>
    </BaseForm>
  )
}

export default ProductCourseUpdate
