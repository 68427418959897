import { Form, FormLongTextInput, FormMultiSelectInput, FormTextInput } from '@superhi/design'
import React from 'react'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'
import InputImage from '../../../../../components/InputImage'

import { FormValues } from './types'
import { FIELDS, VALIDATION_SCHEMA } from './config'
import MUTATION from './mutations'

const MembershipAffiliatesCreate: React.FC<React.PropsWithChildren<unknown>> = () => (
  <BaseForm<FormValues, API.resources_create_mutationVariables>
    mutation={MUTATION}
    validationSchema={VALIDATION_SCHEMA}
    initialValues={{
      description: '',
      image: {
        url: undefined,
        value: '',
        file: null,
      },
      tags: [],
      url: '',
      title: '',
    }}
    createVariablesFn={(values) => ({
      ...values,
      image: values.image.file,
    })}
  >
    <Form.Rows>
      <FormTextInput {...FIELDS.TITLE} />
      <FormLongTextInput {...FIELDS.DESCRIPTION} />
      <InputImage {...FIELDS.IMAGE} />
      <FormTextInput {...FIELDS.URL} />
      <FormMultiSelectInput {...FIELDS.TAGS} />
    </Form.Rows>
  </BaseForm>
)

export default MembershipAffiliatesCreate
