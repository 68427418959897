import { gql } from '@apollo/client'

export default gql`
  fragment ProductCoursesPreview on ProductCourse {
    id
    title
    image {
      url
    }
  }
`
