import { FieldConfig } from '../utils'

export const STRIPE_PRODUCT_ID: FieldConfig<unknown> = {
  type: 'string',
  label: 'Stripe Product ID',
  ariaLabel: 'Stripe Product ID',
  name: 'stripeProductId',
  placeholder: 'E.g. 123456',
  required: true,
}

export const TITLE: FieldConfig<unknown> = {
  type: 'string',
  label: 'Title',
  ariaLabel: 'Title',
  name: 'title',
  placeholder: 'E.g. SuperHi Unlimited',
  required: true,
}

export const SLUG: FieldConfig<unknown> = {
  type: 'string',
  label: 'Slug',
  ariaLabel: 'Slug',
  name: 'slug',
  placeholder: 'E.g. superhi-unlimited',
  required: true,
}

export const DESCRIPTION: FieldConfig<unknown> = {
  type: 'longtext',
  label: 'Description',
  ariaLabel: 'Description',
  height: 6,
  name: 'description',
  placeholder:
    "E.g. Rik is a Mancunian coder, teacher and CEO of SuperHi. He was the co-founder of Steer (a code school in London) and has taught several thousand people to code. He is a bit too old to be posting memes on our social media and recently featured as a Sour Patch Kid in the Macy's Thanksgiving Parade.",
  required: true,
}

export const MAILCHIMP_TAG: FieldConfig<unknown> = {
  type: 'string',
  label: 'Mailchimp tag',
  ariaLabel: 'Mailchimp tag',
  name: 'mailchimpTag',
  placeholder: 'E.g. membership-subscription-unlimited',
  required: true,
}
