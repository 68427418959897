import { theme } from '@superhi/design'
import styled from 'styled-components'

export const EmptyCheckbox = styled.div`
  color: ${theme.colors.GREY_20};
  border: 1px solid currentColor;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  background-color: currentColor;
`
