import React from 'react'
import { Button, Price, Link } from '@superhi/design'

import { createModelPage } from '../utils'
import { API } from '../../../api'
import { formatDateTime } from '../../../utils'

import ResendReceiptButton from './ResendReceiptButton'
import Update from './Update'
import Create from './Create'
import { INDEX, VIEW } from './queries'
import { createAddress } from './utils'
import { DELETE } from './mutations'

export default createModelPage<API.orders_index_orders_nodes[], API.orders_view_order>({
  name: 'Orders',
  index: {
    queryKey: 'orders',
    query: INDEX,
    hideSearch: false,
    formatData: (orders) =>
      orders.map((order) => ({
        id: order.id,
        name: order.customerDetails.name,
        email: order.customerDetails.email,
        date: formatDateTime(order.insertedAt),
      })),
  },
  view: {
    queryKey: 'order',
    query: VIEW,
    formatData: ({ lineItems, billingAddress, shippingAddress, customerDetails, ...order }) => ({
      ...order,
      Customer: (
        <>
          {customerDetails.name} {'<'}
          <Link href={`/users?q=${encodeURIComponent(customerDetails.email)}`}>
            {customerDetails.email}
          </Link>
          {'>'}
        </>
      ),
      'Line items': lineItems?.nodes,
      refunds:
        (order.refunds?.nodes?.length || 0) > 0 ? (
          <>
            {order.refunds?.nodes.map((refund) => (
              <div key={refund.id}>
                <Link href={`/refunds/${refund.id}`}>
                  #{refund.id} - {formatDateTime(refund.insertedAt)}
                </Link>
              </div>
            ))}
          </>
        ) : null,
      payments: (
        <>
          {order.payments.map((payment) => (
            <div key={payment.paidAt}>
              {Price.format(payment.value.value, payment.value.currencyCode)} -{' '}
              {formatDateTime(payment.paidAt)} - {payment.method}
            </div>
          ))}
        </>
      ),
      'Billing address': billingAddress && createAddress(billingAddress),
      'Shipping address': shippingAddress && createAddress(shippingAddress),
      // taxId: order.taxId || '-',
      // discounts: (order.discounts?.nodes.length || 0) > 0 ? order.discounts?.nodes : '-',
      // 'Paid using': order.paymentDetails.paypal ? 'PayPal' : 'Stripe',
    }),
    actions: (id) => (
      <>
        <Button fullWidth href={`/refunds/new?orderId=${id}`}>
          Create refund/exchange
        </Button>
        <ResendReceiptButton orderId={id} />{' '}
        {/* This can be removed once we allow them to see all of their orders in their dashboard */}
      </>
    ),
  },
  create: Create,
  edit: Update,
  destroy: {
    mutation: DELETE,
  },
})
