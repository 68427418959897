import gql from 'graphql-tag'

export const PRICES = gql`
  query CurrencyCreatePrices {
    prices {
      nodes {
        id
        name
      }
    }
  }
`
