import gql from 'graphql-tag'

import { FRAGMENT as ORDER_FRAGMENT } from '../../../orders/components/OrderPreview'
import { FRAGMENT as USER_COURSE_FRAGMENT } from '../../../courses/components/UserCoursePreview'
import MEMBERSHIP_INVOICE_CARD_FRAGMENT from '../../../membershipInvoices/components/MembershipInvoiceCard/fragments'

export const VIEW = gql`
  query users_view($id: ID!) {
    user(id: $id) {
      id
      name
      username
      email

      isAdmin
      isFrozen
      hasOverdueInvoice

      type

      courses {
        nodes {
          ...UserCoursePreview
        }
      }

      team {
        id
        title
      }

      membership {
        id
        status
        stripeCustomerId
        plan {
          stripeSubscriptionId
          product {
            id
            title
          }

          renewalDate

          nextPayment {
            value
            currencyCode
          }
          currentPrice {
            id
            recurring {
              interval
              intervalCount
            }
          }
        }
        invoices {
          ...MembershipInvoiceCardComponent
        }
      }

      orders(perPage: 100) {
        nodes {
          ...OrderPreview
        }
      }

      insertedAt
    }
  }

  ${ORDER_FRAGMENT}
  ${USER_COURSE_FRAGMENT}
  ${MEMBERSHIP_INVOICE_CARD_FRAGMENT}
`
