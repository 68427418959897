import gql from 'graphql-tag'

export default gql`
  mutation CreateCurrency(
    $name: String!
    $code: String!
    $conversions: [CurrencyConversionInput!]!
  ) {
    currencyCreate(name: $name, code: $code, conversions: $conversions) {
      id
    }
  }
`
