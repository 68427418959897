import React from 'react'
import { useQuery } from '@apollo/client'
import { string, boolean, object } from 'yup'
import { Form, FormCheckboxInput, FormTextInput } from '@superhi/design'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'

import QUERY from './queries'
import MUTATION from './mutations'

const announcementShape = object({
  id: string(),
  body: string().required(),
  path: string(),
  showAt: string(),
  hideAt: string(),
}).default(undefined)

type FormValues = {
  hasAnnouncement: boolean
  announcement?: {
    id?: string
    body: string
    path?: string
    showAt?: string
    hideAt?: string
  }
}

const PersonUpdate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { data } = useQuery<API.settings_update>(QUERY)

  if (!data?.settings) {
    return null
  }

  return (
    <BaseForm<FormValues, API.SettingsUpdateMutationVariables>
      initialValues={{
        hasAnnouncement: !!data.settings.announcement,
        announcement: data.settings.announcement
          ? {
              id: data.settings.announcement.id,
              body: data.settings.announcement.body,
              // path: data.settings.announcement.path || undefined,
              showAt: data.settings.announcement.showAt || undefined,
              hideAt: data.settings.announcement.hideAt || undefined,
            }
          : undefined,
      }}
      validationSchema={{
        hasAnnouncement: boolean(),
        announcement: announcementShape.when('hasAnnouncement', {
          is: true,
          then: announcementShape.required(),
          otherwise: announcementShape,
        }),
      }}
      mutation={MUTATION}
      createVariablesFn={({ hasAnnouncement, ...formValues }) => ({
        ...formValues,
        announcement: hasAnnouncement ? formValues.announcement! : undefined,
      })}
    >
      <Form.Legend id="xx" label="Announcement banner">
        <Form.Row>
          <FormCheckboxInput name="hasAnnouncement" label="Show?" ariaLabel="Show?" />
        </Form.Row>

        <Form.Peek<FormValues, FormValues['hasAnnouncement']> name="hasAnnouncement">
          {({ meta }) =>
            meta.value && (
              <>
                <Form.Row>
                  <FormTextInput name="announcement.body" ariaLabel="Body" label="Body" />
                </Form.Row>

                {/* <Form.Row>
                  <FormTextInput
                    name="announcement.path"
                    label="Path"
                    ariaLabel="Path"
                    placeholder="E.g. /courses"
                  />
                </Form.Row> */}
              </>
            )
          }
        </Form.Peek>
      </Form.Legend>
    </BaseForm>
  )
}

export default PersonUpdate
