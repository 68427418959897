import gql from 'graphql-tag'

export const QUERY = gql`
  query membership_affiliates_index($page: Int!, $perPage: Int!) {
    membershipAffiliates(page: $page, perPage: $perPage) {
      nodes {
        id
        # applicationFeePercent
        name
        # slug
        stripeAccountId
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`
