import gql from 'graphql-tag'

export default gql`
  query hall_of_fame_update($id: ID!) {
    hallOfFamer(id: $id) {
      id
      slug
      image {
        url
      }
      title
      type
      url
      users {
        id
      }
    }
  }
`
