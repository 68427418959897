import gql from 'graphql-tag'

export default gql`
  mutation ProductCollectionUpdateMutation(
    $id: ID!
    $title: String!
    $slug: String!
    $description: String!
    $variantIds: [ID!]!
    $isBestSeller: Boolean!
    $image: Upload
  ) {
    productCollectionUpdate(
      id: $id
      slug: $slug
      title: $title
      description: $description
      variantIds: $variantIds
      isBestSeller: $isBestSeller
      image: $image
    ) {
      id
    }
  }
`
