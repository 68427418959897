import { createModelPage } from '../utils'
import { API } from '../../../api'

import Update from './pages/Update'
import { INDEX } from './queries'
import { DELETE } from './mutations'
import View from './pages/View'

export default createModelPage<API.users_index_users_nodes[], API.users_view_user>({
  name: 'Users',
  index: {
    queryKey: 'users',
    query: INDEX,
    hideSearch: false,
    formatData: (users) =>
      users.map((user) => ({
        ...user,
      })),
  },
  view: View,
  edit: Update,
  destroy: {
    mutation: DELETE,
  },
})
