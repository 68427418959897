import { ObjectSchemaDefinition, string } from 'yup'

export type FormValues = {
  membershipProductId: string
  stripeSubscriptionId: string
}

export const VALIDATION_SCHEMA: ObjectSchemaDefinition<FormValues> = {
  membershipProductId: string().required(),
  stripeSubscriptionId: string().required(),
}
