import { createModelPage } from '../utils'
import { API } from '../../../api'

import Create from './Create'
import Update from './Update'
import { INDEX, VIEW } from './queries'

export default createModelPage<API.popups_index_popups_nodes[], API.popups_view_popup>({
  name: 'Popups',
  index: {
    queryKey: 'popups',
    query: INDEX,
    formatData: (popups) => popups,
  },
  view: {
    queryKey: 'popup',
    query: VIEW,
    formatData: (popup) => popup,
  },
  create: Create,
  edit: Update,
})
