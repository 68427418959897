import React from 'react'
import { Link, Price } from '@superhi/design'

import { createModelPage } from '../utils'
import { API } from '../../../api'

import Update from './Update'
import Create from './Create'
import { INDEX, VIEW } from './queries'
import { DELETE } from './mutations'

export default createModelPage<
  API.product_collections_index_productCollections_nodes[],
  API.product_collections_view_productCollection
>({
  name: 'Product Collections',
  index: {
    queryKey: 'productCollections',
    query: INDEX,
    formatData: (productCollections) =>
      productCollections.map((productCollection) => ({
        ...productCollection,
        variants: productCollection.variants?.nodes.map((variant) => variant.title).join(', '),
      })),
  },
  view: {
    queryKey: 'productCollection',
    query: VIEW,
    formatData: ({ variants, ...data }) => ({
      ...data,
      Products: variants?.nodes,
    }),
  },
  edit: Update,
  create: Create,
  destroy: { mutation: DELETE },
})
