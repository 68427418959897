import gql from 'graphql-tag'

import { FRAGMENT as STRIPE_PRICE_FRAGMENT } from '../../../components/StripePricePreview'

export const INDEX = gql`
  query membership_products_index($page: Int!, $perPage: Int!) {
    membershipProducts(page: $page, perPage: $perPage) {
      nodes {
        id
        title
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`

export const VIEW = gql`
  query membership_products_view($id: ID!) {
    membershipProduct(id: $id) {
      id
      slug
      stripeProductId
      title
      description
      mailchimpTag
      # prices {
      #   ...StripePricePreview
      # }
      price {
        ...StripePricePreview
      }
    }
  }

  ${STRIPE_PRICE_FRAGMENT}
`
