import { FieldConfig } from '../../utils'

import { InitialValues } from './Create'

export const PREVIEW_DOWNLOAD_URL: FieldConfig<InitialValues> = {
  type: 'string',
  name: 'previewDownloadUrl',
  label: 'Preview download URL',
  ariaLabel: 'Preview download URL',
  required: true,
}

export const MAILCHIMP_TAG: FieldConfig<InitialValues> = {
  type: 'string',
  name: 'mailchimpTag',
  label: 'Mailchimp Tag',
  ariaLabel: 'Mailchimp Tag',
  required: true,
}
