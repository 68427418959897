import gql from 'graphql-tag'

export const INDEX = gql`
  query currencies_index($page: Int!, $perPage: Int!) {
    currencies(page: $page, perPage: $perPage) {
      nodes {
        id
        name
        code
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`

export const VIEW = gql`
  query currencies_view($id: ID!) {
    currency(id: $id) {
      id
      name
      code
    }
  }
`
