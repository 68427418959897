import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import {
  Form,
  FormCheckboxInput,
  FormLongTextInput,
  FormMultiSelectInput,
  FormSelectInput,
  FormTextInput,
} from '@superhi/design'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'
import InputImage from '../../../../../components/InputImage'
import MultiSelectPerson from '../../../../../components/MultiSelectPerson'
import FormMultiSelectCourseChapters from '../../../../../components/FormMultiSelectCourseChapters'

import { FormValues } from './types'
import QUERY from './queries'
import MUTATION from './mutations'
import { FIELDS, VALIDATION_SCHEMA } from './config'

const MembershipAffiliatesUpdate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.courses_update, API.courses_updateVariables>(QUERY, {
    variables: {
      id,
    },
  })

  if (!data?.course) {
    return null
  }

  return (
    <BaseForm<FormValues, API.courses_update_mutationVariables>
      mutation={MUTATION}
      validationSchema={VALIDATION_SCHEMA}
      initialValues={{
        description: data.course.description,
        difficultyLevel: data.course.difficultyLevel,
        image: {
          url: data.course.image.url,
          value: '',
          file: undefined,
        },
        resourceUrl: data.course.resourceUrl || '',
        instructors: data.course.instructors.map((item) => item.id),
        length: data.course.length,
        mailchimpTag: data.course.mailchimpTag,
        order: data.course.order,
        status: data.course.status,
        overview: data.course.overview,
        targetAudience: data.course.targetAudience,
        requirements: data.course.requirements,
        shortDescription: data.course.shortDescription,
        slackChannelId: data.course.slackChannelId,
        slackChannelName: data.course.slackChannelName,
        syllabusUrl: data.course.syllabusUrl,
        slug: data.course.slug,
        subjects: data.course.subjects,
        title: data.course.title,
        isBeta: data.course.isBeta,
        hasAllChapters: data.course.hasAllChapters,
        chapterIds: data.course.chapters?.nodes.map((item) => item.id) || [],
      }}
      createVariablesFn={(values) => ({
        ...values,
        id,
        image: values.image.file,
      })}
    >
      <Form.Rows>
        <FormTextInput {...FIELDS.TITLE} />

        <FormTextInput {...FIELDS.SLUG} />

        <FormCheckboxInput {...FIELDS.IS_BETA} />
        <FormSelectInput {...FIELDS.STATUS} />
        <FormCheckboxInput {...FIELDS.HAS_ALL_CHAPTERS} />

        <FormTextInput {...FIELDS.MAILCHIMP_TAG} />
        <FormTextInput {...FIELDS.SLACK_CHANNEL_NAME} />
        <FormTextInput {...FIELDS.SLACK_CHANNEL_ID} />
        <FormTextInput {...FIELDS.SYLLABUS_URL} />

        <InputImage {...FIELDS.IMAGE} />

        <FormLongTextInput {...FIELDS.SHORT_DESCRIPTION} />

        <FormTextInput {...FIELDS.RESOURCE_URL} />

        <FormLongTextInput {...FIELDS.DESCRIPTION} />

        <FormLongTextInput {...FIELDS.REQUIREMENTS} />

        <FormSelectInput {...FIELDS.LENGTH} />

        <FormSelectInput {...FIELDS.DIFFICULTY_LEVEL} />

        <FormMultiSelectInput {...FIELDS.SUBJECTS} />

        <FormMultiSelectCourseChapters {...FIELDS.CHAPTER_IDS} />

        <MultiSelectPerson {...FIELDS.INSTRUCTORS} />

        <FormTextInput {...FIELDS.ORDER} />

        <FormLongTextInput {...FIELDS.TARGET_AUDIENCE} />

        <Form.Repeatable<FormValues, 'overview'>
          name="overview"
          label="What youll learn"
          defaultValue={''}
        >
          {({ name }) => (
            <Form.Row>
              <FormTextInput {...FIELDS.OVERVIEW} name={name} />
            </Form.Row>
          )}
        </Form.Repeatable>
      </Form.Rows>
    </BaseForm>
  )
}

export default MembershipAffiliatesUpdate
