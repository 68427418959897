import { string } from 'yup'
import { FormTextInput } from '@superhi/design'
import { FieldConfig } from '@superhi/utils'

const WISTIA_VIDEO_ID: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Wistia video ID',
  label: 'Wistia video ID',
  name: 'wistiaVideoId',
  placeholder: 'E.g.',
  required: true,
}

export const FIELDS = {
  WISTIA_VIDEO_ID,
}

export const VALIDATION_SCHEMA = {
  wistiaVideoId: string().required(),
}
