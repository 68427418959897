import React from 'react'
import { DocumentNode, useQuery } from '@apollo/client'
import { useFormikContext } from 'formik'
import { Form, Button, FormMultiSelectInput, TextVerticalSpacer, Link } from '@superhi/design'
import FormField from '@superhi/design/dist/cjs/components/forms/inputs/Field/FormField'

import SortableList from '../SortableList'

import Search, { Props as SearchProps } from './Search'

type SelectOption = { display: string; value: string; href?: string }

type Props<QueryData, QueryVariables, SearchQueryData, SearchQueryVariables> = Omit<
  React.ComponentProps<typeof FormMultiSelectInput>,
  'options'
> & {
  query: DocumentNode
  getQueryVariables: (value: string[]) => QueryVariables
  queryDataToOptionsFn: (data: QueryData) => SelectOption[]

  searchTitle: string
  searchQuery: SearchProps<SearchQueryData, SearchQueryVariables>['query']
  searchCreateQueryVariablesFn: SearchProps<
    SearchQueryData,
    SearchQueryVariables
  >['createQueryVariablesFn']
  searchQueryDataToItemsFn: SearchProps<SearchQueryData, SearchQueryVariables>['queryDataToItemsFn']
}

function MultiSelectSearch<QueryData, QueryVariables, SearchQueryData, SearchQueryVariables>({
  query,
  getQueryVariables,
  queryDataToOptionsFn,

  searchQuery,
  searchCreateQueryVariablesFn,
  searchQueryDataToItemsFn,

  searchTitle,
  ...props
}: Props<QueryData, QueryVariables, SearchQueryData, SearchQueryVariables>) {
  const formik = useFormikContext()
  const fieldValue = ((formik.values as any)[props.name] || []) as string[]
  const [showModal, setShowModal] = React.useState(false)

  const theQuery = useQuery<QueryData, QueryVariables>(query, {
    fetchPolicy: 'cache-and-network',
    variables: getQueryVariables(fieldValue),
  })

  const OPTIONS = theQuery.data ? queryDataToOptionsFn(theQuery.data) : []

  const OPTION_MAP = OPTIONS.reduce(
    (acc, item) => ({
      ...acc,
      [item.value]: {
        ...item,
        display: item.display || item.value,
      },
    }),
    {} as {
      [key: string]: SelectOption
    },
  )

  const fixedData = fieldValue.map((id) => OPTION_MAP[id]).filter(Boolean)

  const handleChange = (sortedData: typeof fixedData) => {
    formik.setFieldValue(
      props.name,
      sortedData.map((item) => item.value),
    )
  }

  return (
    <>
      <FormField<Omit<JSX.IntrinsicElements['select'], 'children'>> {...props}>
        {({ field, helpers, ...rest }) => (
          <Form.Legend label={props.label} required={props.required} hint={props.hint}>
            {theQuery.loading ? (
              'Loading...'
            ) : (
              <TextVerticalSpacer>
                <div>
                  <SortableList
                    data={fixedData}
                    items={fixedData.map((v, i) => ({
                      id: v.value,
                      children: (
                        <>
                          {i + 1} – {v.display} {v.href && <Link href={v.href}>(View)</Link>}
                        </>
                      ),
                    }))}
                    onChange={handleChange}
                  />
                </div>

                <Button fullWidth onClick={() => setShowModal(true)}>
                  Add
                </Button>
              </TextVerticalSpacer>
            )}
          </Form.Legend>
        )}
      </FormField>

      {showModal && (
        <Search<SearchQueryData, SearchQueryVariables>
          title={searchTitle}
          query={searchQuery}
          selected={fieldValue}
          createQueryVariablesFn={searchCreateQueryVariablesFn}
          queryDataToItemsFn={searchQueryDataToItemsFn}
          onClose={() => setShowModal(false)}
          onDone={(newIds) => formik.setFieldValue(props.name, newIds)}
        />
      )}
    </>
  )
}

export default MultiSelectSearch
