import { boolean, string } from 'yup'
import {
  FormCheckboxInput,
  FormLongTextInput,
  FormMultiSelectInput,
  FormTextInput,
} from '@superhi/design'
import { FieldConfig } from '@superhi/utils'

import FormSelectCourseHomework from '../../../../../components/FormSelectCourseHomework'
import FormSelectCourseProject from '../../../../../components/FormSelectCourseProject'

const WISTIA_PROJECT_ID: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Wistia project ID',
  label: 'Wistia project ID',
  name: 'wistiaProjectId',
  placeholder: 'E.g.',
  required: true,
}

const IS_LOCKED: FieldConfig<typeof FormCheckboxInput> = {
  label: 'Is locked?',
  ariaLabel: 'Is locked?',
  name: 'isLocked',
  required: true,
}

const HOMEWORK_ID: FieldConfig<typeof FormSelectCourseHomework> = {
  ariaLabel: 'Homework',
  label: 'Homework',
  name: 'homeworkId',
  placeholder: 'Choose one',
}

const PROJECT_ID: FieldConfig<typeof FormSelectCourseProject> = {
  ariaLabel: 'Project',
  label: 'Project',
  name: 'projectId',
  placeholder: 'Choose one',
}

const RESOURCE_URL: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Resource URL',
  label: 'Resource URL',
  name: 'resourceUrl',
  placeholder: 'E.g.',
}

export const FIELDS = {
  WISTIA_PROJECT_ID,
  HOMEWORK_ID,
  PROJECT_ID,
  RESOURCE_URL,
  IS_LOCKED,
}

export const VALIDATION_SCHEMA = {
  wistiaProjectId: string().required(),
  isLocked: boolean().required(),
  homeworkId: string().nullable(),
  projectId: string().nullable(),
  resourceUrl: string(),
}
