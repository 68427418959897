import { Body, Button, Heading, Label, Link } from '@superhi/design'
import pluralize from 'pluralize'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useHistory } from 'react-router-dom'

import * as styles from './styles'

type Props = {
  title?: string
  description?: string
  actions?: any
  hideBack?: boolean
  isLoading?: boolean
  currentPage?: number
  totalPages?: number
}

type PageItemAction = 'edit' | 'delete'
const ACTIONS: Record<PageItemAction, string> = {
  edit: 'Editing',
  delete: 'Deleting',
}

const getFormattedTitle = (parts: string[]) => {
  const current = parts[parts.length - 1]
  const rest = parts.slice(0, parts.length - 1)

  let title = 'Home'

  if (parts.length === 3) {
    const action = ACTIONS[current as PageItemAction]

    if (action) {
      title = `${action} ${pluralize.singular(rest[rest.length - 2].replace(/-/g, ' '))} (ID #${
        rest[rest.length - 1]
      })`
    }
  } else if (parts.length === 2) {
    if (current === 'new') {
      title = `Creating ${pluralize.singular(rest[rest.length - 1].replace(/-/g, ' '))}`
    } else {
      title = `Viewing ${pluralize.singular(
        rest[rest.length - 1].replace(/-/g, ' '),
      )} (ID #${current})`
    }
  } else if (parts.length === 1) {
    title = current.replace(/-/g, ' ')
  }

  return {
    breadcrumbs: [
      // {
      //   display: 'Home',
      //   href: '/',
      // },
      ...rest.map((item, i) => ({
        display: item.replace(/-/g, ' '),
        href: `/${rest.slice(0, i + 1).join('/')}`,
      })),
    ],
    title,
  }
}

const Page: React.FC<React.PropsWithChildren<Props>> & {
  ActionsGroup: typeof styles.ActionsGroup
} = ({
  title,
  description,
  actions = [],
  children,
  hideBack = false,
  isLoading = false,
  currentPage = 1,
  totalPages = 1,
}) => {
  const history = useHistory()
  const params = useLocation()
  const parts = params.pathname.split('/').filter(Boolean)
  const formattedTitle = title || parts.join(' - ')

  const currentTitle = getFormattedTitle(parts)

  const handleChange = (e: any) => {
    e.persist()
    const nextPage = [...(e.target.options as any)][e.target.selectedIndex].value as string

    history.replace({
      search: `?page=${nextPage}`,
    })
  }

  return (
    <>
      <Helmet>
        <title>{formattedTitle}</title>
      </Helmet>

      <styles.Header>
        {currentTitle.breadcrumbs.length > 0 && (
          <styles.Breadcrumbs>
            {currentTitle.breadcrumbs.map((crumb, i) => (
              <Body level="3" inline key={i} color="GREY_70">
                <Link href={crumb.href}>{crumb.display}</Link>
              </Body>
            ))}
          </styles.Breadcrumbs>
        )}

        <styles.HeaderTitle>
          <styles.HeaderTitleWrapper>
            <Heading color="BLUE_50" level="4">
              {currentTitle.title}
              {isLoading ? ' (Loading...)' : ''}
            </Heading>

            {totalPages > 1 && (
              <styles.Pagination>
                {currentPage > 1 && <Link href={`?page=${currentPage - 1}`}>Prev</Link>}
                <select value={currentPage} onBlur={handleChange} onChange={handleChange}>
                  {new Array(totalPages).fill(null).map((_, i) => (
                    <option key={i} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
                {currentPage < totalPages && <Link href={`?page=${currentPage + 1}`}>Next</Link>}
              </styles.Pagination>
            )}
          </styles.HeaderTitleWrapper>

          {description && <p>{description}</p>}
        </styles.HeaderTitle>
      </styles.Header>

      <styles.Content>{children}</styles.Content>

      <styles.Actions>
        {actions}
        {!hideBack && (
          <Button
            version="outline"
            fullWidth
            href={params.pathname.split('/').slice(0, -1).join('/')}
          >
            Back
          </Button>
        )}
      </styles.Actions>
    </>
  )
}

Page.ActionsGroup = styles.ActionsGroup

export default Page
