import React from 'react'
import { useQuery } from '@apollo/client'
import uniq from 'lodash.uniq'
import slugify from 'slugify'
import { Heading, SiteHeader, getImgProps, HorizontalSpacer, Body, HiLogo } from '@superhi/design'
import { useLocation } from 'react-router'
import { buildUrl as buildAccountUrl } from '@superhi/account'

import PAGES from '../../../../../pages'
import { API } from '../../../../../api'
import pkg from '../../../../../../package.json'
import { ACCOUNT_URL, SITE_URL } from '../../../../../config'

import { SESSION } from './queries'
// import { LogoWrapper } from './styles';

const Logo = () => (
  <svg width="84" height="18" viewBox="0 0 84 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.168 17.4H18.648L11.208 0.36H7.44L0 17.4H3.48L5.16 13.248H13.488L15.168 17.4ZM6.12 10.824L8.232 5.592C8.736 4.512 9.048 3.816 9.312 2.856H9.36C9.6 3.816 9.912 4.512 10.416 5.592L12.528 10.824H6.12Z"
      fill="currentColor"
    />
    <path
      d="M25.6999 17.76C28.0519 17.76 29.7799 16.8 30.7399 15.144V17.4H33.7159V0H30.6199V7.248C29.5879 5.76 28.0279 5.04 25.8919 5.04C22.1959 5.04 19.6519 7.632 19.6519 11.4C19.6519 15.192 22.1479 17.76 25.6999 17.76ZM26.7559 15.48C24.3799 15.48 22.8919 13.92 22.8919 11.376C22.8919 8.832 24.3079 7.32 26.7079 7.32C29.0839 7.32 30.6919 8.808 30.6919 11.136V11.712C30.6919 13.992 29.1079 15.48 26.7559 15.48Z"
      fill="currentColor"
    />
    <path
      d="M37.9613 5.4V17.4H41.0573V11.088C41.0573 8.832 42.2572 7.464 44.1772 7.464C45.9532 7.464 46.8652 8.568 46.8652 10.68V17.4H49.9613V10.968C49.9613 8.76 51.1372 7.464 53.0812 7.464C54.8572 7.464 55.7933 8.568 55.7933 10.704V17.4H58.8893V10.296C58.8893 6.912 57.2093 5.016 54.2093 5.016C52.1213 5.016 50.5373 5.976 49.5773 7.8C48.9053 5.976 47.4173 5.016 45.3053 5.016C43.2173 5.016 41.7293 5.952 40.9613 7.776V5.4H37.9613Z"
      fill="currentColor"
    />
    <path
      d="M62.4969 3.408H65.8809V0.312H62.4969V3.408ZM62.6409 17.4H65.7369V5.4H62.6409V17.4Z"
      fill="currentColor"
    />
    <path
      d="M69.8597 17.4H72.9557V11.568C72.9557 8.976 74.3957 7.464 76.7477 7.464C78.9557 7.464 80.0117 8.664 80.0117 11.064V17.4H83.1077V10.656C83.1077 6.96 81.3317 5.016 77.9477 5.016C75.5477 5.016 73.8197 6.024 72.8597 7.968V5.4H69.8597V17.4Z"
      fill="currentColor"
    />
  </svg>
)

const Header: React.FC<React.PropsWithChildren<unknown>> = () => {
  const location = useLocation()
  const { data } = useQuery<API.HeaderSession>(SESSION, {
    // fetchPolicy: 'network-only',
    // fetchPolicy: 'cache-and-network',
  })
  const navItems = uniq<string>(
    PAGES.filter((page) => !!page.name && !page.excludeNavItem).map((page) => page.name!),
  )

  const userImageProps = React.useMemo(
    () =>
      data?.session?.avatar?.url
        ? getImgProps(data.session.avatar.url, {
            small: 10,
            medium: 10,
            large: 10,
            xlarge: 10,
          })
        : undefined,
    [data],
  )

  return (
    <SiteHeader
      logo={
        <HorizontalSpacer align="center" amount={{ small: 16, medium: 16, large: 16 }}>
          <HiLogo />
          <Logo />
        </HorizontalSpacer>
      }
      logoHref="/"
      logInButton={{
        text: 'Log in',
        href: buildAccountUrl(ACCOUNT_URL, 'login', {
          redirectUrl: `${SITE_URL}${location.pathname}`,
        }),
        isExternal: true,
        newTab: false,
      }}
      closeOn={location.pathname}
      user={
        data?.session
          ? {
              image: userImageProps,
              name: data.session.name || data.session.username,
              email: data.session.email,
            }
          : undefined
      }
      userItems={[
        {
          items: [
            {
              display: 'Log out',
              href: buildAccountUrl(process.env.REACT_APP_ACCOUNT_URL!, 'logout'),
              isExternal: true,
              newTab: false,
            },
          ],
        },
      ]}
      extraActions={[
        {
          // display: `v${pkg.version}`,
          display: process.env.NODE_ENV === 'development' ? 'dev' : `v${pkg.version}`,
        },
      ]}
      items={[
        {
          display: 'Menu',
          items: navItems.map((name) => ({
            display: name,
            href: `/${slugify(name, { lower: true })}`,
          })),
        },
      ]}
    />
  )
}

export default Header
