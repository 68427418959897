import gql from 'graphql-tag'

export const QUERY = gql`
  query URGH {
    session {
      id
      isAdmin
    }
  }
`
