import { Body, Link } from '@superhi/design'

import { API } from '../../../../../api'

type Props = API.CourseChapterPreview & {}

const CourseChapterPreview: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  position,
  title,
}) => (
  <div>
    <Body level="3" bold inline>
      <Link href={`/course-chapters/${id}`}>
        Chapter {position} – {title}
      </Link>
    </Body>
  </div>
)

export { default as FRAGMENT } from './fragments'
export default CourseChapterPreview
