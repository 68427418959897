import { FieldConfig } from '../utils'

export const TITLE: FieldConfig<unknown> = {
  type: 'string',
  label: 'Title',
  ariaLabel: 'Title',
  name: 'title',
  placeholder: 'E.g. Rik Lomas',
  required: true,
}

export const SLUG: FieldConfig<unknown> = {
  type: 'string',
  label: 'Slug',
  ariaLabel: 'Slug',
  name: 'slug',
  placeholder: 'E.g. rik-lomas',
  required: true,
}

export const APPLICATION_URL: FieldConfig<unknown> = {
  type: 'string',
  label: 'Application URL',
  ariaLabel: 'Application URL',
  name: 'applicationUrl',
  required: true,
}

export const IMAGE: FieldConfig<unknown> = {
  type: 'image',
  label: 'Image',
  ariaLabel: 'Image',
  name: 'image',
  required: true,
}

export const INTRO: FieldConfig<unknown> = {
  type: 'longtext',
  label: 'Intro',
  ariaLabel: 'Intro',
  height: 6,
  name: 'intro',
  placeholder:
    "E.g. Rik is a Mancunian coder, teacher and CEO of SuperHi. He was the co-founder of Steer (a code school in London) and has taught several thousand people to code. He is a bit too old to be posting memes on our social media and recently featured as a Sour Patch Kid in the Macy's Thanksgiving Parade.",
  required: true,
}

export const BODY: FieldConfig<unknown> = {
  type: 'longtext',
  label: 'Body',
  ariaLabel: 'Body',
  name: 'body',
  required: true,
  height: 12,
}

export const WINNERS_NOTIFIED_AT: FieldConfig<unknown> = {
  type: 'datetime',
  label: 'Winners notified at',
  ariaLabel: 'Winners notified at',
  name: 'winnersNotifiedAt',
  required: true,
}

export const PUBLISHED_AT: FieldConfig<unknown> = {
  type: 'datetime',
  label: 'Published at',
  ariaLabel: 'Published at',
  name: 'publishedAt',
  required: true,
}

export const CLOSING_AT: FieldConfig<unknown> = {
  type: 'datetime',
  label: 'Closing at',
  ariaLabel: 'Closing at',
  name: 'closingAt',
  required: true,
}
