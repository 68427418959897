import gql from 'graphql-tag'

export const INDEX = gql`
  query discounts_index($page: Int!, $perPage: Int!) {
    discounts(page: $page, perPage: $perPage) {
      nodes {
        id
        name
        type
        isStackable
        isActive
        percent
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`

export const VIEW = gql`
  query discounts_view($id: ID!) {
    discount(id: $id) {
      id
      name
      description
      type
      code
      percent
      isActive
      isPaused
      startAt
      endAt
      clauses {
        expressions {
          ... on DiscountExpressionType {
            key
            typeValue: value {
              operator
              value
            }
          }

          ... on DiscountExpressionProduct {
            key
            productValue: value {
              operator
              value
            }
          }

          ... on DiscountExpressionCount {
            key
            countValue: value {
              operator
              value
            }
          }
        }
      }
    }
  }
`
