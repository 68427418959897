import React from 'react'
import { string } from 'yup'
import { Form } from '@superhi/design'

import BaseForm from '../../../_templates/BaseForm'
import { API } from '../../../../../api'
import {
  CREATE_VARIABLES_CREATE as BASE_CREATE_VARIABLES_CREATE,
  INITIAL_VALUES_CREATE as BASE_INITIAL_VALUES_CREATE,
  VALIDATION_SCHEMA_CREATE as BASE_VALIDATION_SCHEMA_CREATE,
} from '../../config'
import { FormCreate } from '../../Form'
import { InitialValuesCreate as BaseInitialValuesCreate } from '../../types'
import { configToInput } from '../../../utils'
import * as config from '../config'

import MUTATION from './mutations'

export type InitialValues = BaseInitialValuesCreate & {
  previewDownloadUrl: string
  mailchimpTag: string
}

const ProductBookCreate: React.FC<React.PropsWithChildren<unknown>> = () => (
  <BaseForm<InitialValues, API.ProductBookCreateVariables>
    mutation={MUTATION}
    initialValues={{
      ...BASE_INITIAL_VALUES_CREATE,
      previewDownloadUrl: '',
      mailchimpTag: '',
    }}
    validationSchema={{
      ...BASE_VALIDATION_SCHEMA_CREATE,
      previewDownloadUrl: string().required(),
      mailchimpTag: string().required(),
    }}
    createVariablesFn={(formValues) => ({
      ...BASE_CREATE_VARIABLES_CREATE(formValues),
      previewDownloadUrl: formValues.previewDownloadUrl,
      mailchimpTag: formValues.mailchimpTag,
    })}
  >
    <FormCreate />

    <Form.Row>{configToInput(config.PREVIEW_DOWNLOAD_URL)}</Form.Row>

    <Form.Row>{configToInput(config.MAILCHIMP_TAG)}</Form.Row>
  </BaseForm>
)

export default ProductBookCreate
