import gql from 'graphql-tag'

export const INDEX = gql`
  query popups_index {
    popups {
      nodes {
        id
        title
        isActive
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`

export const VIEW = gql`
  query popups_view($id: ID!) {
    popup(id: $id) {
      id
      title
      isActive
      body
      buttonUrl
      createdAt
    }
  }
`
