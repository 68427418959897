import { FieldConfig } from '../utils'

export const TITLE: FieldConfig<unknown> = {
  type: 'string',
  name: 'title',
  label: 'Title',
  ariaLabel: 'Title',
  placeholder: 'E.g. Creative Coding Collection',
  required: true,
}

export const SLUG: FieldConfig<unknown> = {
  type: 'string',
  name: 'slug',
  label: 'Slug',
  ariaLabel: 'Slug',
  placeholder: 'E.g. creative-coding-collection',
  required: true,
}

export const DESCRIPTION: FieldConfig<unknown> = {
  type: 'longtext',
  name: 'description',
  label: 'Description',
  ariaLabel: 'Description',
  placeholder:
    'E.g. Interested in beginning a career in coding? Our bundle is a great place to start learning.',
  height: 6,
  required: true,
}

export const IS_BEST_SELLER: FieldConfig<unknown> = {
  type: 'boolean',
  name: 'isBestSeller',
  label: 'Best seller?',
  ariaLabel: 'Best seller?',
  text: '',
  required: false,
}

export const IMAGE: FieldConfig<unknown> = {
  type: 'image',
  name: 'image',
  label: 'Image',
  ariaLabel: 'Image',
  required: true,
}

export const VARIANT_IDS: FieldConfig<unknown> = {
  type: 'select-multi-product-variant',
  label: 'Product variants',
  ariaLabel: 'Product variants',
  name: 'variantIds',
  required: true,
}
