import gql from 'graphql-tag'

export default gql`
  query membership_products_update($id: ID!) {
    membershipProduct(id: $id) {
      id
      description
      mailchimpTag
      slug
      stripeProductId
      title
    }
  }
`
