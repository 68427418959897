import gql from 'graphql-tag'

export default gql`
  mutation RefundCreate(
    $orderId: ID!
    $lineItems: [RefundLineItemInput!]!
    $exchangeItems: [RefundExchangeItemInput!]
    $notes: String
  ) {
    refundCreate(
      orderId: $orderId
      lineItems: $lineItems
      exchangeItems: $exchangeItems
      notes: $notes
    ) {
      id
    }
  }
`
