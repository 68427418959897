import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Form, FormLongTextInput, FormMultiSelectInput, FormTextInput } from '@superhi/design'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'
import InputImage from '../../../../../components/InputImage'

import { FormValues } from './types'
import QUERY from './queries'
import MUTATION from './mutations'
import { FIELDS, VALIDATION_SCHEMA } from './config'

const CourseLessonsUpdate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.course_lessons_update, API.course_lessons_updateVariables>(QUERY, {
    variables: {
      id,
    },
  })

  if (!data?.courseLesson) {
    return null
  }

  return (
    <BaseForm<FormValues, API.course_lessons_update_mutationVariables>
      mutation={MUTATION}
      validationSchema={VALIDATION_SCHEMA}
      initialValues={{
        slug: data.courseLesson.slug,
        title: data.courseLesson.title,
        description: data.courseLesson.description || '',
        wistiaVideoId: data.courseLesson.wistiaId,
      }}
      createVariablesFn={(values) => ({
        ...values,
        id,
      })}
    >
      <Form.Rows>
        <FormTextInput {...FIELDS.TITLE} />
        <FormTextInput {...FIELDS.SLUG} />
        <FormTextInput {...FIELDS.WISTIA_VIDEO_ID} />
        <FormLongTextInput {...FIELDS.DESCRIPTION} />
      </Form.Rows>
    </BaseForm>
  )
}

export default CourseLessonsUpdate
