import gql from 'graphql-tag'

export default gql`
  mutation user_membership_create(
    $userId: ID!
    $membershipProductId: ID!
    $stripeSubscriptionId: String!
  ) {
    membershipCreate(
      userId: $userId
      membershipProductId: $membershipProductId
      stripeSubscriptionId: $stripeSubscriptionId
    ) {
      id
    }
  }
`
