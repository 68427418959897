import { Body } from '@superhi/design'

type Props = { children?: string | null; emptyText?: string }

const StringPreview = ({ children, emptyText = 'No data' }: Props) => {
  const isEmpty = !children || children === ''

  return (
    <Body level="3" color={!isEmpty ? 'GREY_90' : 'GREY_60'}>
      {!isEmpty ? children : `<${emptyText}>`}
    </Body>
  )
}

export default StringPreview
