import gql from 'graphql-tag'

export default gql`
  query settings_update {
    settings {
      announcement {
        id
        body
        hideAt
        path
        showAt
      }
    }
  }
`
