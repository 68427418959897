import React from 'react'
import { useQuery } from '@apollo/client'
import { useFormikContext } from 'formik'
import {
  Form,
  Button,
  CheckboxInput,
  FormMultiSelectInput,
  Modal,
  TextVerticalSpacer,
} from '@superhi/design'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import SortableItem from './SortableItem'

type Item = React.ComponentProps<typeof SortableItem>

type Props<T = any> = {
  data: T[]
  items: Item[]
  onChange: (items: T[]) => void
}

function SortableList<T = any>({ data, items, onChange }: Props<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id)
      const newIndex = items.findIndex((item) => item.id === over?.id)

      const newData = [...data]
      newData.splice(newIndex, 0, newData.splice(oldIndex, 1)[0])

      onChange(newData)
    }
  }

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      {/* {items.map((item) => (
        <SortableItem key={item.id} {...item} />
      ))} */}
      <TextVerticalSpacer level="4">
        <SortableContext
          items={items.map((item) => item.id)}
          strategy={verticalListSortingStrategy}
        >
          {items.map((item) => (
            <SortableItem key={item.id} {...item} />
            // <div key={item.id} id={item.id}>
            //     {item.children}
            //   </div>
          ))}
        </SortableContext>
      </TextVerticalSpacer>
    </DndContext>
  )
}

export default SortableList
