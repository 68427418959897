import gql from 'graphql-tag'

export default gql`
  mutation ProductCollectionCreate(
    $title: String!
    $slug: String!
    $description: String!
    $variantIds: [ID!]!
    $isBestSeller: Boolean!
    $image: Upload!
  ) {
    productCollectionCreate(
      title: $title
      slug: $slug
      description: $description
      variantIds: $variantIds
      isBestSeller: $isBestSeller
      image: $image
    ) {
      id
    }
  }
`
