import gql from 'graphql-tag'

import { FRAGMENT as PRICE_CONVERSION_FRAGMENT } from './components/PriceConversionPreview'

export const INDEX = gql`
  query prices_index($page: Int!, $perPage: Int!) {
    prices(page: $page, perPage: $perPage) {
      nodes {
        id
        name
        conversions {
          currency {
            code
            id
            name
          }
          value
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`

export const VIEW = gql`
  query prices_view($id: ID!) {
    price(id: $id) {
      id
      name
      conversions {
        ...PriceConversionPreview
      }
    }
  }

  ${PRICE_CONVERSION_FRAGMENT}
`
