import {
  FIELDS as CREATE_FIELDS,
  VALIDATION_SCHEMA as CREATE_VALIDATION_SCHEMA,
} from '../Create/config'

export const FIELDS = {
  TITLE: CREATE_FIELDS.TITLE,
  PLAN: CREATE_FIELDS.PLAN,
  ADMIN_EMAIL: CREATE_FIELDS.ADMIN_EMAIL,
  USER_EMAILS: CREATE_FIELDS.USER_EMAILS,
}

export const VALIDATION_SCHEMA = {
  title: CREATE_VALIDATION_SCHEMA.title,
  plan: CREATE_VALIDATION_SCHEMA.plan,
  adminEmail: CREATE_VALIDATION_SCHEMA.adminEmail,
  userEmails: CREATE_VALIDATION_SCHEMA.userEmails,
}
