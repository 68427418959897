import { Form, FormLongTextInput, FormSelectInput, FormTextInput } from '@superhi/design'
import React from 'react'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'

import { FormValues } from './types'
import { FIELDS, VALIDATION_SCHEMA } from './config'
import MUTATION from './mutations'

const TeamsCreate: React.FC<React.PropsWithChildren<unknown>> = () => (
  <BaseForm<FormValues, API.teams_create_mutationVariables>
    mutation={MUTATION}
    validationSchema={VALIDATION_SCHEMA}
    initialValues={{
      title: '',
      plan: API.TeamPlan.STANDARD,
      adminEmail: '',
      userEmails: '',
      stripeSubscriptionId: '',
    }}
    createVariablesFn={(values) => ({
      ...values,
      userEmails: values.userEmails
        .split('\n')
        .map((v) => v.trim())
        .filter(Boolean),
    })}
  >
    <Form.Rows>
      <FormTextInput {...FIELDS.TITLE} />
      <FormTextInput {...FIELDS.STRIPE_SUBSCRIPTION_ID} />
      <FormSelectInput {...FIELDS.PLAN} />
      <FormTextInput {...FIELDS.ADMIN_EMAIL} />
      <FormLongTextInput {...FIELDS.USER_EMAILS} />
    </Form.Rows>
  </BaseForm>
)

export default TeamsCreate
