import { UserChip } from '@superhi/design'
import React from 'react'

import { API } from '../../../../../api'

type Props = API.UserCoursePreview

const UserCoursePreview: React.FC<React.PropsWithChildren<Props>> = ({
  progress,
  accessType,
  course,
}) => (
  <UserChip
    name={course.title}
    jobTitle={`${accessType} – ${progress.status}`}
    image={{ src: course.image.url || '' }}
    link={{
      href: `/courses/${course.id}`,
    }}
  />
)

export { default as FRAGMENT } from './fragments'
export default UserCoursePreview
