import React from 'react'
import { Link, Price } from '@superhi/design'

import { createModelPage } from '../utils'
import { API } from '../../../api'
import { formatDateTime } from '../../../utils'

import Create from './Create'
import { INDEX, VIEW } from './queries'
import CancelRefundButton from './components/CancelRefundButton'

export default createModelPage<API.refund_index_refunds_nodes[], API.refund_view_refund>({
  name: 'Refunds',
  index: {
    queryKey: 'refunds',
    query: INDEX,
    formatData: (data) =>
      data.map((item) => ({
        // ...data,
        id: item.id,
        orderId: item.order.id,
        status: item.status,
      })),
  },
  view: {
    queryKey: 'refund',
    query: VIEW,
    formatData: ({ exchangeItems, basket, order, ...data }) => ({
      ...data,
      ...(basket?.id
        ? {
            'Payment link': (
              <Link
                href={`${process.env.REACT_APP_MARKETING_SITE_URL}/checkout/exchange?basketId=${basket?.id}`}
                isExternal
              >{`${process.env.REACT_APP_MARKETING_SITE_URL}/checkout/exchange?basketId=${basket?.id}`}</Link>
            ),
          }
        : {}),
      'Original order': (
        <Link href={`/orders/${order.id}`}>
          #{order.id} on {formatDateTime(order.insertedAt)} by {order.customerDetails.name}
        </Link>
      ),
      lineItems: (
        <>
          {data.lineItems?.nodes.map((lineItem) => (
            <div key={lineItem.id}>
              {lineItem.quantity} x {lineItem.orderLineItem.variant.title} - [{lineItem.status}]
            </div>
          ))}
        </>
      ),
      ...(exchangeItems && exchangeItems.nodes.length > 0
        ? {
            exchangeItems: (
              <>
                {exchangeItems.nodes.map((exchangeItem) => (
                  <div key={exchangeItem.id}>
                    {exchangeItem.quantity} x {exchangeItem.variant.title}
                  </div>
                ))}
              </>
            ),
          }
        : {}),
      payments: (
        <>
          {data.payments?.nodes.map((payment) => (
            <div key={payment.id}>
              {Price.format(payment.value.value, payment.value.currencyCode)} on{' '}
              {formatDateTime(payment.insertedAt)}
            </div>
          ))}
        </>
      ),
    }),
    actions: (id) => <CancelRefundButton refundId={id} />,
  },
  create: Create,
})
