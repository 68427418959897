import { FieldConfig } from '../utils'

export const TITLE: FieldConfig<unknown> = {
  type: 'string',
  label: 'Title',
  ariaLabel: 'Title',
  name: 'title',
  required: true,
  placeholder: 'E.g. Hello visitor',
}

export const IS_ACTIVE: FieldConfig<unknown> = {
  type: 'boolean',
  label: 'Is active?',
  ariaLabel: 'Is active?',
  name: 'isActive',
  required: false,
  text: '',
}

export const BODY: FieldConfig<unknown> = {
  type: 'longtext',
  label: 'Body',
  ariaLabel: 'Body',
  name: 'body',
  required: true,
  height: 6,
  placeholder: 'E.g. Lorem ipsum dolor',
}

export const BUTTON_URL: FieldConfig<unknown> = {
  type: 'string',
  label: 'Button URL',
  ariaLabel: 'Button URL',
  name: 'buttonUrl',
  required: true,
  placeholder: 'E.g. http://www.google.com',
}

export const BUTTON_TEXT: FieldConfig<unknown> = {
  type: 'string',
  label: 'Button Text',
  ariaLabel: 'Button Text',
  name: 'buttonText',
  required: true,
  placeholder: 'E.g. Click me',
}
