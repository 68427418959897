import { Form, FormCheckboxInput, FormTextInput } from '@superhi/design'
import React from 'react'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'
import FormSelectCourseProject from '../../../../../components/FormSelectCourseProject'
import FormSelectCourseHomework from '../../../../../components/FormSelectCourseHomework'

import { FormValues } from './types'
import { FIELDS, VALIDATION_SCHEMA } from './config'
import MUTATION from './mutations'

const CourseChaptersCreate: React.FC<React.PropsWithChildren<unknown>> = () => (
  <BaseForm<FormValues, API.course_chapters_create_mutationVariables>
    mutation={MUTATION}
    validationSchema={VALIDATION_SCHEMA}
    initialValues={{
      homeworkId: '',
      projectId: '',
      resourceUrl: '',
      wistiaProjectId: '',
      isLocked: false,
    }}
    createVariablesFn={(values) => ({
      ...values,
      homeworkId: values.homeworkId,
      projectId: values.projectId,
      resourceUrl: values.resourceUrl,
    })}
  >
    <Form.Rows>
      <FormTextInput {...FIELDS.WISTIA_PROJECT_ID} />
      <FormSelectCourseHomework {...FIELDS.HOMEWORK_ID} />
      <FormSelectCourseProject {...FIELDS.PROJECT_ID} />
      <FormTextInput {...FIELDS.RESOURCE_URL} />
      <FormCheckboxInput {...FIELDS.IS_LOCKED} />
    </Form.Rows>
  </BaseForm>
)

export default CourseChaptersCreate
