import gql from 'graphql-tag'

export default gql`
  mutation UserAdmin($id: ID!, $isAdmin: Boolean!) {
    userUpdateAdmin(id: $id, isAdmin: $isAdmin) {
      id
      isAdmin
    }
  }
`
