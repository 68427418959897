import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { string, array, object, number } from 'yup'
import { Form, FormTextInput } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import * as config from '../config'
import { configToInput } from '../../utils'

import QUERY from './queries'
import MUTATION from './mutations'

type FormValues = {
  name: string
  conversions: {
    currencyId: string
    value: number
  }[]
}

const PriceUpdate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.prices_update>(QUERY, { variables: { id } })

  if (!data?.price) {
    return null
  }

  return (
    <BaseForm<FormValues, API.PriceUpdateMutationVariables>
      initialValues={{
        name: data.price.name,
        conversions: data.price.conversions.map((conversion) => ({
          currencyId: conversion.currency.id,
          value: conversion.value,
        })),
      }}
      validationSchema={{
        name: string().required(),
        conversions: array()
          .of(
            object({
              currencyId: string().required(),
              value: number().required(),
            }).required(),
          )
          .required(),
      }}
      mutation={MUTATION}
      createVariablesFn={(formValues) => ({
        id,
        name: formValues.name,
        conversions: formValues.conversions.map((conversion) => ({
          currencyId: conversion.currencyId,
          value: Number(conversion.value),
        })),
      })}
    >
      <Form.Row>{configToInput(config.NAME)}</Form.Row>

      <Form.Legend label="Conversions">
        {data.price.conversions.map((conversion, i) => (
          <Form.Row key={conversion.currency.id}>
            {configToInput(config.CONVERSIONS_VALUE(`conversions.${i}`, conversion.currency.name))}
          </Form.Row>
        ))}
      </Form.Legend>
    </BaseForm>
  )
}

export default PriceUpdate
