import gql from 'graphql-tag'

export const QUERY = gql`
  query courses_index($page: Int!, $perPage: Int!) {
    courses(page: $page, perPage: $perPage) {
      nodes {
        id
        title
        isBeta
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`
