import gql from 'graphql-tag'

export default gql`
  mutation resources_create_mutation(
    $description: String!
    $image: Upload!
    $tags: [ResourceTag!]!
    $title: String!
    $url: String!
  ) {
    resourceCreate(
      description: $description
      image: $image
      tags: $tags
      title: $title
      url: $url
    ) {
      id
    }
  }
`
