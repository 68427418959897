import gql from 'graphql-tag'

import { FRAGMENT as PERSON_FRAGMENT } from '../../../people/components/PersonPreview'
import { FRAGMENT as COURSE_PROJECT_FRAGMENT } from '../../../courseProjects/components/CourseProjectPreview'
import { FRAGMENT as COURSE_HOMEWORK_FRAGMENT } from '../../../courseHomeworks/components/CourseHomeworkPreview'
import { FRAGMENT as COURSE_CHAPTER_FRAGMENT } from '../../../courseChapters/components/CourseChapterPreview'
import { FRAGMENT as COURSE_LESSON_FRAGMENT } from '../../../courseLessons/components/CourseLessonPreview'

export const QUERY = gql`
  query courses_view($id: ID!) {
    course(id: $id) {
      id
      title
      slug
      shortDescription
      description
      subjects
      difficultyLevel
      slackChannelId
      slackChannelName
      resourceUrl
      length
      isBeta

      chapters {
        nodes {
          ...CourseChapterPreview
          lessons {
            nodes {
              ...CourseLessonPreview
            }
          }
          project {
            ...CourseProjectPreview
          }
          homework {
            ...CourseHomeworkPreview
          }
        }
      }

      image {
        url
      }
      instructors {
        ...PersonPreview
      }

      mailchimpTag

      requirements
      slackChannelId
    }
  }

  ${PERSON_FRAGMENT}
  ${COURSE_CHAPTER_FRAGMENT}
  ${COURSE_PROJECT_FRAGMENT}
  ${COURSE_HOMEWORK_FRAGMENT}
  ${COURSE_LESSON_FRAGMENT}
`
