import React from 'react'
import { useMutation, DocumentNode } from '@apollo/client'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ObjectSchemaDefinition } from 'yup'
import { FormikHelpers, FormikValues, useFormikContext } from 'formik'
import { Button, Form, Link } from '@superhi/design'
import { setErrorsFromApollo } from '@superhi/utils'

import Page from '../../../../components/Page'
import { getModelFromPath } from '../../utils'

const BUTTON: any = {
  edit: 'Update',
  new: 'Create',
}

type Props<FormValues extends FormikValues, MutationVariables> = {
  mutation: DocumentNode
  initialValues: FormValues
  createVariablesFn: (formValues: FormValues) => MutationVariables
  validationSchema: ObjectSchemaDefinition<FormValues>
  children: any
  onCompleteUrl?: string
}

function BaseForm<FormValues extends FormikValues, MutationVariables>({
  mutation,
  initialValues,
  createVariablesFn,
  validationSchema,
  children,
  onCompleteUrl,
}: Props<FormValues, MutationVariables>) {
  const { id } = useParams<any>()
  const action = BUTTON[useLocation().pathname.split('/').pop() as string]
  const modelName = getModelFromPath(useLocation().pathname)
  const modelNameUnderscore = modelName.replace(/-/g, '_')

  const history = useHistory()

  const [mutationFn] = useMutation<any, MutationVariables>(mutation)

  const onSubmit = React.useCallback<
    (values: FormValues, helpers: FormikHelpers<FormValues>) => Promise<any>
  >(
    async (values, formikBag) => {
      try {
        console.log('xxx', createVariablesFn(values))

        await mutationFn({
          variables: createVariablesFn(values),
          refetchQueries: [
            `${modelNameUnderscore}_index`,
            `${modelNameUnderscore}_view`,
            `${modelNameUnderscore}_update`,
          ],
        })

        history.push(onCompleteUrl || `/${modelName}${id ? `/${id}` : ''}`)
      } catch (e) {
        console.log(e)
        setErrorsFromApollo(formikBag.setFieldError, e)
      }
    },
    [createVariablesFn, history, id, modelName, modelNameUnderscore, mutationFn, onCompleteUrl],
  )

  return (
    <Page
    // actions={
    //   <>
    //     <Button href={`/${modelName}/${id}/delete`} fullWidth>
    //       Delete
    //     </Button>
    //   </>
    // }
    >
      <Form<FormValues>
        name="main"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        maxWidth={900}
        submitButtonText={action}
      >
        {children}
      </Form>
    </Page>
  )
}

export default BaseForm
