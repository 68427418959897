import gql from 'graphql-tag'

export default gql`
  mutation DiscountCreate(
    $name: String!
    $description: String
    $type: DiscountType!
    $code: String!
    $percent: Int!
    $isPaused: Boolean
    $clauses: [DiscountClauseInput!]!
    $startAt: DateTime
    $endAt: DateTime
    $isStackable: Boolean!
  ) {
    discountCreate(
      clauses: $clauses
      code: $code
      description: $description
      name: $name
      percent: $percent
      isPaused: $isPaused
      type: $type
      startAt: $startAt
      endAt: $endAt
      isStackable: $isStackable
    ) {
      id
    }
  }
`
