import gql from 'graphql-tag'

export const QUERY = gql`
  query course_watched_summaries_view($id: ID!) {
    courseWatchedSummaries(id: $id) {
      id
      course {
        id
        title
        watchedPercentageThreshold
      }
      date
      total
    }
  }
`
