import { FormTextInput } from '@superhi/design'
import { FieldConfig } from '@superhi/utils'
import { ObjectSchemaDefinition, array, string } from 'yup'

import MultiSelectCourse from '../../../../../components/MultiSelectCourse'

export type FormValues = {
  email: string
  directAccessCourseIds?: string[]
}

const EMAIL: FieldConfig<typeof FormTextInput> = {
  name: 'email',
  label: 'Email',
  ariaLabel: 'Email',
  placeholder: 'e.g. hi@superhi.com',
  required: true,
}

const DIRECT_ACCESS_COURSE_IDS: FieldConfig<typeof MultiSelectCourse> = {
  name: 'directAccessCourseIds',
  label: 'Direct access courses',
  ariaLabel: 'Direct access courses',
  required: false,
  hint: "Disabled courses are one's that the user has access to via a membership. These cannot be changed.",
}

export const FIELD_CONFIGS = {
  EMAIL,
  DIRECT_ACCESS_COURSE_IDS,
}

export const VALIDATION_SCHEMA: ObjectSchemaDefinition<FormValues> = {
  email: string().required(),
  directAccessCourseIds: array().of(string()),
}
