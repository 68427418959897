import 'lodash.product'
import _ from 'lodash'
import set from 'lodash.set'
import cloneDeep from 'lodash.clonedeep'
import omit from 'lodash.omit'
import formatDistance from 'date-fns/formatDistance'

export const formatDateTime = (datetime: string) =>
  new Intl.DateTimeFormat(navigator.language, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  }).format(new Date(datetime))

export const formatDateTimeDistance = (datetime: string) =>
  formatDistance(new Date(datetime), new Date(), { addSuffix: true })

export const camelToTitle = (str: string): string =>
  str
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim()

export function setRecord<T extends object, I extends keyof T = any, V extends keyof T[I] = any>(
  record: T,
  id: keyof T,
  field: string,
  value: V,
) {
  return set<T>(cloneDeep<T>(record), [id, field], value)
}

export function deleteRecord<T extends object>(record: T, id: keyof T) {
  return omit<T>(record, [id]) as T
}

export function cartesianProduct<T>(options: T[][]): T[][] {
  return (_ as any).product(...options)
}
