import gql from 'graphql-tag'

export default gql`
  mutation ProductBookCreate(
    $description: String!
    $image: Upload!
    $priceId: ID
    $fulfillableIds: [ID!]
    $shortDescription: String!
    $slug: String!
    $title: String!
    $releaseDate: Date
    $variants: [ProductVariantCreateInput!]
    $contents: [ProductContentInput!]!
    $isBestSeller: Boolean!
    $previewDownloadUrl: String!
    $maxQuantity: Int
    $mailchimpTag: String!
  ) {
    productBookCreate(
      description: $description
      image: $image
      priceId: $priceId
      fulfillableIds: $fulfillableIds
      shortDescription: $shortDescription
      slug: $slug
      title: $title
      releaseDate: $releaseDate
      variants: $variants
      isBestSeller: $isBestSeller
      previewDownloadUrl: $previewDownloadUrl
      contents: $contents
      maxQuantity: $maxQuantity
      mailchimpTag: $mailchimpTag
    ) {
      id
    }
  }
`
