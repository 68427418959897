import { API } from '../../../../../api'
import { PageDelete } from '../../../utils'

import DELETE from './mutations'

const Destroy: PageDelete<API.course_lessons_delete> = {
  mutation: DELETE,
}

export default Destroy
