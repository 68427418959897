import gql from 'graphql-tag'

export default gql`
  query course_chapters_update($id: ID!) {
    courseChapter(id: $id) {
      id
      description
      isLocked
      homework {
        id
      }
      lessons {
        nodes {
          id
        }
      }
      position
      project {
        id
      }
      resourceUrl
      slug
      title
      wistiaProjectId
    }
  }
`
