import gql from 'graphql-tag'

export const CURRENCIES = gql`
  query PricesCreateAllCurrencies {
    currencies {
      nodes {
        id
        name
      }
    }
  }
`
