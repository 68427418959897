import { FieldConfig } from '../../utils'

export const TITLE: FieldConfig<unknown> = {
  type: 'string',
  label: 'Title',
  ariaLabel: 'Title',
  name: 'title',
  required: true,
  placeholder: 'E.g. Foundation HTML, CSS & JS',
}

export const SLUG: FieldConfig<unknown> = {
  type: 'string',
  label: 'Slug',
  ariaLabel: 'Slug',
  name: 'slug',
  required: true,
  placeholder: 'E.g. foundation-html-css-js',
}

export const IMAGE: FieldConfig<unknown> = {
  type: 'image',
  label: 'Image',
  ariaLabel: 'Image',
  name: 'image',
  required: true,
}

export const IS_BEST_SELLER: FieldConfig<unknown> = {
  type: 'boolean',
  label: 'Best seller?',
  ariaLabel: 'Best seller?',
  name: 'isBestSeller',
  required: false,
  text: '',
}

export const DESCRIPTION: FieldConfig<unknown> = {
  type: 'longtext',
  label: 'Description',
  ariaLabel: 'Description',
  name: 'description',
  required: true,
  height: 12,
}

export const SHORT_DESCRIPTION: FieldConfig<unknown> = {
  type: 'longtext',
  label: 'Short description',
  ariaLabel: 'Short description',
  name: 'shortDescription',
  required: true,
  height: 8,
}

// export const VARIANTS = <VariantsManager />;

export const FULFILLABLE_IDS: FieldConfig<unknown> = {
  type: 'select-fulfillables',
  label: 'Fulfillable Items',
  ariaLabel: 'Fulfillable Items',
  name: 'fulfillableIds',
  required: false,
}

export const PRICE_ID: FieldConfig<unknown> = {
  type: 'select-price',
  label: 'Price',
  ariaLabel: 'Price',
  name: 'priceId',
  required: true,
}

export const MAX_QUANTITY: FieldConfig<unknown> = {
  type: 'number',
  label: 'Max quantity',
  ariaLabel: 'Max quantity',
  name: 'maxQuantity',
  min: 1,
  required: true,
}

export const RELEASE_DATE: FieldConfig<unknown> = {
  type: 'date',
  label: 'Release date',
  ariaLabel: 'Release date',
  name: 'releaseDate',
  required: false,
}

export const CONTENTS_TITLE = (name: string): FieldConfig<unknown> => ({
  type: 'string',
  label: 'Title',
  ariaLabel: 'Title',
  name: `${name}.title`,
  required: true,
})

export const CONTENTS_DESCRIPTION = (name: string): FieldConfig<unknown> => ({
  type: 'longtext',
  label: 'Description',
  ariaLabel: 'Description',
  name: `${name}.description`,
  height: 6,
  required: false,
})
