import gql from 'graphql-tag'

import { FRAGMENT as PRODUCT_COURSE_FRAGMENT } from '../products/productCourses/components/ProductCoursesPreview'

export const INDEX = gql`
  query collaborators_index {
    collaborators {
      id
      name
      productCourses {
        id
        title
      }
    }
  }
`

export const VIEW = gql`
  query collaborators_view($id: ID!) {
    collaborator(id: $id) {
      id
      name
      payouts {
        startDate
        endDate
        values {
          currencyCode
          value
        }
        orders {
          id
        }
      }
      productCourses {
        ...ProductCoursesPreview
      }
    }
  }

  ${PRODUCT_COURSE_FRAGMENT}
`
