import gql from 'graphql-tag'

export default gql`
  mutation UserFreeze($id: ID!, $isFrozen: Boolean!) {
    userUpdateFreeze(id: $id, isFrozen: $isFrozen) {
      id
      isFrozen
    }
  }
`
