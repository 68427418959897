import { createModelPage } from '../utils'
import { API } from '../../../api'

import Create from './Create'
import Update from './Update'
import { INDEX, VIEW } from './queries'
import { DELETE } from './mutations'

export default createModelPage<API.hall_of_fame_index, API.hall_of_fame_view>({
  name: 'Hall of Fame',
  index: {
    queryKey: 'hallOfFamers',
    query: INDEX,
  },
  view: {
    queryKey: 'hallOfFamer',
    query: VIEW,
  },
  create: Create,
  edit: Update,
  destroy: {
    mutation: DELETE,
  },
})
