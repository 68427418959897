import { createModelPage } from '../utils'

import List from './pages/List'
import View from './pages/View'

export default createModelPage<any, any>({
  name: 'Course Watched Summaries',
  index: List,
  view: View,
})
