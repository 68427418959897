import { createModelPage } from '../utils'

import Create from './Create'
import Update from './Update'
import View from './View'
import { INDEX } from './queries'
import { DELETE } from './mutations'

export default createModelPage<any, any>({
  name: 'People',
  index: {
    queryKey: 'people',
    query: INDEX,
  },
  view: View,
  create: Create,
  edit: Update,
  destroy: {
    mutation: DELETE,
  },
})
