import gql from 'graphql-tag'

export default gql`
  mutation teams_update_mutation(
    $id: ID!
    $adminEmail: String!
    $plan: TeamPlan!
    $title: String!
    $userEmails: [String!]!
  ) {
    teamUpdate(
      id: $id
      adminEmail: $adminEmail
      plan: $plan
      title: $title
      userEmails: $userEmails
    ) {
      id
    }
  }
`
