import gql from 'graphql-tag'

export default gql`
  mutation course_chapters_create_mutation(
    $homeworkId: ID
    $projectId: ID
    $resourceUrl: String
    $wistiaProjectId: String!
    $isLocked: Boolean!
  ) {
    courseChapterCreate(
      homeworkId: $homeworkId
      projectId: $projectId
      resourceUrl: $resourceUrl
      wistiaProjectId: $wistiaProjectId
      isLocked: $isLocked
    ) {
      id
    }
  }
`
