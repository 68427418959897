import { string, number, object, mixed, array } from 'yup'
import { FormLongTextInput, FormMultiSelectInput, FormTextInput } from '@superhi/design'
import { FieldConfig } from '@superhi/utils'

import { API } from '../../../../../api'
import InputImage from '../../../../../components/InputImage'

const URL: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'URL',
  label: 'URL',
  name: 'url',
  placeholder: 'E.g.',
}

const TITLE: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Title',
  label: 'Title',
  name: 'title',
  placeholder: 'E.g.',
}

const DESCRIPTION: FieldConfig<typeof FormLongTextInput> = {
  ariaLabel: 'Description',
  label: 'Description',
  name: 'description',
  placeholder: 'E.g.',
  height: 6,
}

const TAGS: FieldConfig<typeof FormMultiSelectInput> = {
  ariaLabel: 'Tags',
  label: 'Tags',
  name: 'tags',
  placeholder: 'E.g.',
  options: Object.values(API.ResourceTag).map((item) => ({
    display: item,
    value: item,
  })),
}

const IMAGE: FieldConfig<typeof InputImage> = {
  required: true,
  label: 'Image',
  ariaLabel: 'Image',
  name: 'image',
}

export const FIELDS = {
  DESCRIPTION,
  TITLE,
  IMAGE,
  URL,
  TAGS,
}

export const VALIDATION_SCHEMA = {
  description: string().required(),
  image: object({
    url: string(),
    value: string().required(),
    file: mixed().required(),
  }).required(),
  url: string().required(),
  tags: array()
    .of(string().oneOf(Object.values(API.ResourceTag)).required())
    .required(),
  title: string().required(),
}
