export const getPreviewImage = (file: File): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader()

    reader.onload = function (e) {
      if (e?.target) {
        resolve(e.target.result as string)
      }
    }

    reader.readAsDataURL(file)
  })
