import gql from 'graphql-tag'

export const QUERY = gql`
  query membership_affiliates_view($id: ID!) {
    membershipAffiliate(id: $id) {
      id
      applicationFeePercent
      name
      slug
      stripeAccountId
      stripeCouponId
    }
  }
`
