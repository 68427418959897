import gql from 'graphql-tag'

export default gql`
  query SelectCurrency {
    currencies(perPage: 100) {
      nodes {
        id
        name
        code
      }
    }
  }
`
