import React from 'react'
import { FormMultiSelectInput } from '@superhi/design'

import { API } from '../../api'
import MultiSelectSearch from '../MultiSelectSearch'

import { QUERY, SEARCH_QUERY } from './queries'

function FormMultiSelectCourseChapters(
  props: Omit<React.ComponentProps<typeof FormMultiSelectInput>, 'options'>,
) {
  return (
    <MultiSelectSearch<
      API.MultiSelectCourseChapters,
      API.MultiSelectCourseChaptersVariables,
      API.MultiSelectCourseChapters_Search,
      {}
    >
      {...props}
      query={QUERY}
      getQueryVariables={(ids) => ({ ids })}
      queryDataToOptionsFn={(data) =>
        data.courseChapters?.nodes.map((item) => ({
          value: item.id,
          display: item.title,
          href: `/course-chapters/${item.id}`,
        })) || []
      }
      searchTitle="Choose chapters"
      searchQuery={SEARCH_QUERY}
      searchCreateQueryVariablesFn={() => ({})}
      searchQueryDataToItemsFn={(data) =>
        data.courseChapters?.nodes.map((item) => ({
          id: item.id,
          children: item.title || item.id,
        })) || []
      }
    />
  )
}

export default FormMultiSelectCourseChapters
