import Home from './Home'
import Error from './Error'
import MODEL_PAGES from './models'
import Deploy from './Deploy'

type PageConfig = {
  name?: string
  path?: string
  exact?: boolean
  isPublic?: boolean
  Component: React.FC<React.PropsWithChildren<unknown>>
  excludeNavItem?: boolean
}

const PAGES: PageConfig[] = [
  {
    name: 'Home',
    path: '/',
    exact: true,
    Component: Home,
  },
  {
    name: 'Deploy',
    path: '/deploy',
    exact: true,
    Component: Deploy,
  },
  ...MODEL_PAGES,
  {
    Component: Error,
    isPublic: true,
  },
]

export default PAGES
