import gql from 'graphql-tag'

export default gql`
  query people_update($id: ID!) {
    person(id: $id) {
      id
      description
      image {
        url
      }
      startedOn
      jobTitle
      location
      name
      slug
      facebook
      instagram
      twitter
      isSuperhi
    }
  }
`
