import gql from 'graphql-tag'

export default gql`
  query users_isFrozenButton($id: ID!) {
    user(id: $id) {
      isFrozen
      hasOverdueInvoice
    }
  }
`
