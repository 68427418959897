import { FormData } from '../'
import { API } from '../../../../../api'

export const PRODUCT_TYPE_OPTIONS = ['BOOK', 'COURSE'].map((type) => ({
  display: type,
  value: type,
}))

const COUNT_STRINGS: Record<keyof typeof API.DiscountCountOperator, string> = {
  EQ: `equal to`,
  GT: 'greater than',
  GTE: 'greater than or equal to',
  LT: 'less than',
  LTE: 'less than or equal to',
}

const getCountExpressions = (countExpressions: API.ExpressionCountInput[] = []) =>
  countExpressions.length > 0
    ? countExpressions
        ?.map(
          (expression) =>
            `Number of products is ${COUNT_STRINGS[expression.operator]} ${expression.value}`,
        )
        .join(' AND ')
    : undefined

const getProductExpressions = (productExpressions: API.ExpressionProductInput[] = []) =>
  productExpressions.length > 0
    ? productExpressions
        ?.map(
          (expression) =>
            `Product is${expression.value.length > 1 ? ' any of ' : ' '}${expression.value.join(
              ', ',
            )}`,
        )
        .join(' AND ')
    : undefined

const getTypeExpressions = (typeExpressions: API.ExpressionTypeInput[] = []) =>
  typeExpressions.length > 0
    ? typeExpressions
        ?.map(
          (expression) =>
            `Type is${expression.value.length > 1 ? ' any of ' : ' '}${expression.value.join(
              ', ',
            )}`,
        )
        .join(' AND ')
    : undefined

export const getFriendlyDescription = (clauses: FormData['clauses']) =>
  clauses
    .map((clause) =>
      [
        getCountExpressions(clause.countExpressions),
        getProductExpressions(clause.productExpressions),
        getTypeExpressions(clause.typeExpressions),
      ]
        .filter(Boolean)
        .join(' AND '),
    )
    .join(' OR ')

export const COUNT_EXPRESSIONS_OPERATOR_OPTIONS = Object.values(API.DiscountCountOperator).map(
  (option) => ({
    display: COUNT_STRINGS[option],
    value: option,
  }),
)

export const SLUG_EXPRESSIONS_OPERATOR_OPTIONS = Object.values(API.DiscountOperator).map(
  (option) => ({
    display: option,
    value: option,
  }),
)

export const TYPE_EXPRESSIONS_OPERATOR_OPTIONS = Object.values(API.DiscountOperator).map(
  (option) => ({
    display: option,
    value: option,
  }),
)

export const createEmptyCountExpression = (): API.ExpressionCountInput => ({
  operator: API.DiscountCountOperator.EQ,
  value: 0,
})

export const createEmptyProductExpression = (): API.ExpressionProductInput => ({
  operator: API.DiscountOperator.IN,
  value: [],
})

export const createEmptyTypeExpression = (): API.ExpressionTypeInput => ({
  operator: API.DiscountOperator.IN,
  value: [],
})

export const createEmptyClause = (): {
  countExpressions?: API.ExpressionCountInput[]
  productExpressions?: API.ExpressionProductInput[]
  typeExpressions?: API.ExpressionTypeInput[]
} => ({
  countExpressions: [],
  productExpressions: [],
  typeExpressions: [],
})
