import gql from 'graphql-tag'

export default gql`
  mutation ProductFulfillableCreate(
    $title: String!
    $description: String
    $fulfillmentId: String!
    $sku: String!
    $allowMultiplePerOrder: Boolean!
  ) {
    productFulfillableCreate(
      title: $title
      description: $description
      fulfillmentId: $fulfillmentId
      sku: $sku
      allowMultiplePerOrder: $allowMultiplePerOrder
    ) {
      id
    }
  }
`
