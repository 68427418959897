import gql from 'graphql-tag'

export default gql`
  query discounts_update($id: ID!) {
    discount(id: $id) {
      id
      isStackable
      clauses {
        expressions {
          ... on DiscountExpressionType {
            key
            typeValue: value {
              operator
              value
            }
          }

          ... on DiscountExpressionProduct {
            key
            productValue: value {
              operator
              value
            }
          }

          ... on DiscountExpressionCount {
            key
            countValue: value {
              operator
              value
            }
          }
        }
      }
      code
      description
      endAt
      isPaused
      name
      percent
      startAt
      type
    }
  }
`
