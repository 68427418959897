import gql from 'graphql-tag'

export default gql`
  query MultiSelectPerson {
    people(perPage: 100) {
      nodes {
        id
        name
      }
    }
  }
`
