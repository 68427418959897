import { Form } from '@superhi/design'
import React from 'react'

import { configToInput } from '../../utils'
import { InitialValuesUpdate } from '../types'
import VariantsManager from '../VariantsManager'

import {
  CONTENTS_DESCRIPTION,
  CONTENTS_TITLE,
  DESCRIPTION,
  FULFILLABLE_IDS,
  IMAGE,
  IS_BEST_SELLER,
  MAX_QUANTITY,
  PRICE_ID,
  RELEASE_DATE,
  SHORT_DESCRIPTION,
  SLUG,
  TITLE,
} from './config'

type FormCreateProps = {}

export const FormCreate: React.FC<React.PropsWithChildren<FormCreateProps>> = () => (
  <>
    {configToInput(TITLE)}

    {configToInput(SLUG)}

    {configToInput(IMAGE)}

    {configToInput(IS_BEST_SELLER)}

    {configToInput(DESCRIPTION)}

    {configToInput(SHORT_DESCRIPTION)}

    <VariantsManager />

    <Form.Peek<InitialValuesUpdate, InitialValuesUpdate['hasVariants']> name="hasVariants">
      {({ meta }) =>
        !meta.value && (
          <>
            {configToInput(FULFILLABLE_IDS)}

            {configToInput(PRICE_ID)}

            {configToInput(MAX_QUANTITY)}
          </>
        )
      }
    </Form.Peek>

    <Form.Repeatable<InitialValuesUpdate, 'contents'>
      label="Contents"
      name="contents"
      defaultValue={{ title: '', description: '' }}
    >
      {({ name }) => (
        <>
          {configToInput(CONTENTS_TITLE(name))}

          {configToInput(CONTENTS_DESCRIPTION(name))}
        </>
      )}
    </Form.Repeatable>
  </>
)

type FormUpdateProps = {}

export const FormUpdate: React.FC<React.PropsWithChildren<FormUpdateProps>> = () => (
  <>
    {configToInput(TITLE)}

    {configToInput(SLUG)}

    {configToInput({ ...IMAGE, required: false })}

    {configToInput(IS_BEST_SELLER)}

    {configToInput(RELEASE_DATE)}

    {configToInput(SHORT_DESCRIPTION)}

    {configToInput(DESCRIPTION)}

    <VariantsManager />

    <Form.Peek<InitialValuesUpdate, InitialValuesUpdate['hasVariants']> name="hasVariants">
      {({ meta }) =>
        !meta.value && (
          <>
            {configToInput(FULFILLABLE_IDS)}

            {configToInput(PRICE_ID)}

            {configToInput(MAX_QUANTITY)}
          </>
        )
      }
    </Form.Peek>

    <Form.Repeatable<InitialValuesUpdate, 'contents'>
      label="Contents"
      name="contents"
      defaultValue={{ title: '', description: '' }}
    >
      {({ name }) => (
        <>
          {configToInput(CONTENTS_TITLE(name))}

          {configToInput(CONTENTS_DESCRIPTION(name))}
        </>
      )}
    </Form.Repeatable>
  </>
)
