import gql from 'graphql-tag'

export default gql`
  mutation course_homeworks_update_mutation(
    $id: ID!
    $description: String!
    $finishedUrl: String
    $briefUrl: String
    $image: Upload
    # $files: [CourseAssetFileUpdateInput!]
    # $links: [CourseAssetLinkUpdateInput!]
    $slug: String!
    $title: String!
    $isRequired: Boolean!
  ) {
    courseHomeworkUpdate(
      id: $id
      description: $description
      # files: $files
      finishedUrl: $finishedUrl
      briefUrl: $briefUrl
      image: $image
      # links: $links
      slug: $slug
      title: $title
      isRequired: $isRequired
    ) {
      id
    }
  }
`
