import React from 'react'
import { formatDateOnlyWithoutDay } from '@superhi/design/dist/cjs/components/utils/DateTime'
import { Link, TextVerticalSpacer, Price } from '@superhi/design'
import sortBy from 'lodash.sortby'

import { createModelPage } from '../utils'
import { API } from '../../../api'

import { INDEX, VIEW } from './queries'
import { Payouts } from './styles'

export default createModelPage<
  API.collaborators_index_collaborators[],
  API.collaborators_view_collaborator
>({
  name: 'Collaborators',
  index: {
    queryKey: 'collaborators',
    query: INDEX,
    formatData: (data) =>
      data.map((item) => ({
        ...item,
        productCourses: item.productCourses.map((productCourse) => productCourse.title).join(', '),
      })),
  },
  view: {
    queryKey: 'collaborator',
    query: VIEW,
    formatData: ({ payouts, ...data }) => ({
      ...data,
      payouts: (
        <Payouts>
          {sortBy(payouts, 'startDate').map((payout, i) => (
            <TextVerticalSpacer level="4" key={i}>
              <div>
                <strong>{formatDateOnlyWithoutDay(payout.startDate)}</strong>
              </div>
              <div>
                {payout.values.map((value, valueI) => (
                  <div key={valueI}>
                    {value.currencyCode} – {Price.format(value.value, value.currencyCode)}
                  </div>
                ))}
              </div>
              <div>
                {payout.orders.map((order) => (
                  <div key={order.id}>
                    <Link href={`/orders/${order.id}`}>{order.id}</Link>
                  </div>
                ))}
              </div>
            </TextVerticalSpacer>
          ))}
        </Payouts>
      ),
    }),
  },
})
