import { API } from '../../../../../api'
import { PageView } from '../../../utils'

import { QUERY } from './queries'

const View: PageView<API.course_homeworks_view_courseHomework> = {
  queryKey: 'courseHomework',
  query: QUERY,
}

export default View
