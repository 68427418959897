import gql from 'graphql-tag'

const MEMBERSHIP_QUERY = gql`
  query membership_products_summary {
    membershipProducts {
      nodes {
        id
        stripeProductId
        title
        description
        slug
        price {
          recurring {
            interval
          }
        }
      }
    }
  }
`

export { MEMBERSHIP_QUERY }
