import styled from 'styled-components'
import { theme } from '@superhi/design'

export const IsFiltered = styled.div`
  padding: 0.5em 1em;
  font-size: 0.75em;
  display: flex;
  align-items: center;
`

export const Wrapper = styled.div<{ $isFiltered?: boolean }>`
  background-color: ${({ $isFiltered }) => ($isFiltered ? 'yellow' : 'transparent')};
  padding: 2px;
`

export const Table = styled.table`
  width: 100%;
  background-color: white;
  border-spacing: 0;
  border: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid ${theme.colors.GREY_50};

  td,
  th {
    padding: 24px 0;
  }

  td {
    border-top: 1px solid ${theme.colors.GREY_50};
    /* border-right: 1px solid ${theme.colors.GREY_50}; */

    &:not(:last-child) {
      padding-right: 24px;
    }
  }

  thead {
    td {
      padding: 24px 0;
      text-transform: capitalize;
      border-top: 0;

      &:first-child {
        overflow: hidden;
      }

      &:last-child {
        overflow: hidden;
      }
    }

    td,
    th {
      /* text-transform: uppercase; */
      font-weight: bold;

      &:first-child {
        overflow: hidden;
      }

      &:last-child {
        overflow: hidden;
      }
    }
  }

  tbody {
    tr:last-child {
      td,
      th {
        &:first-child {
          overflow: hidden;
        }

        &:last-child {
          overflow: hidden;
        }
      }
    }
  }

  tbody {
    tr:hover {
      td,
      th {
        background-color: ${theme.colors.GREY_10};
      }
    }
  }

  td:first-child {
    width: 1px;

    a {
      display: block;
      text-align: left;
    }
  }

  td.actions {
    text-align: right;
    width: 1px;
  }
`

export const Actions = styled.div`
  /* display: flex; */

  > * {
    &:not(:last-child) {
      /* display: inline-block; */
      margin-right: 0.5em;
    }
  }
`
