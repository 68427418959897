import { string, number, object, mixed, array, boolean } from 'yup'
import {
  FormCheckboxInput,
  FormLongTextInput,
  FormMultiSelectInput,
  FormSelectInput,
  FormTextInput,
} from '@superhi/design'
import { FieldConfig } from '@superhi/utils'

import { API } from '../../../../../api'
import InputImage from '../../../../../components/InputImage'
import MultiSelectPerson from '../../../../../components/MultiSelectPerson'

const IS_BETA: FieldConfig<typeof FormCheckboxInput> = {
  ariaLabel: 'Is beta?',
  label: 'Is beta?',
  name: 'isBeta',
  required: true,
}

const HAS_ALL_CHAPTERS: FieldConfig<typeof FormCheckboxInput> = {
  ariaLabel: 'Has all chapters?',
  label: 'Has all chapters?',
  name: 'hasAllChapters',
  required: true,
}

const DESCRIPTION: FieldConfig<typeof FormLongTextInput> = {
  ariaLabel: 'Description',
  label: 'Description',
  name: 'description',
  placeholder: 'E.g.',
  height: 6,
  required: true,
}

const DIFFICULTY_LEVEL: FieldConfig<typeof FormSelectInput> = {
  ariaLabel: 'Difficulty level',
  label: 'Difficulty level',
  name: 'difficultyLevel',
  placeholder: 'E.g.',
  options: Object.values(API.CourseDifficultyLevel).map((item) => ({
    display: item,
    value: item,
  })),
  required: true,
}

const STATUS: FieldConfig<typeof FormSelectInput> = {
  ariaLabel: 'Status',
  label: 'Status',
  name: 'status',
  // placeholder: 'E.g.',
  options: Object.values(API.CourseStatus).map((item) => ({
    display: item,
    value: item,
  })),
  required: true,
}

const LENGTH: FieldConfig<typeof FormSelectInput> = {
  ariaLabel: 'Length',
  label: 'Length',
  name: 'length',
  placeholder: 'E.g.',
  options: Object.values(API.CourseLength).map((item) => ({
    display: item,
    value: item,
  })),
  required: true,
}

const INSTRUCTORS: FieldConfig<typeof MultiSelectPerson> = {
  ariaLabel: 'Instructors',
  label: 'Instructors',
  name: 'instructors',
  placeholder: 'E.g.',
  required: true,
}

const MAILCHIMP_TAG: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'MailChimp tag',
  label: 'MailChimp tag',
  name: 'mailchimpTag',
  placeholder: 'E.g.',
  required: true,
}

const SLACK_CHANNEL_NAME: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Slack channel name',
  label: 'Slack channel name',
  name: 'slackChannelName',
  placeholder: 'E.g.',
  required: true,
}

const SLACK_CHANNEL_ID: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Slack channel ID',
  label: 'Slack channel ID',
  name: 'slackChannelId',
  placeholder: 'E.g.',
  required: true,
}

const SYLLABUS_URL: FieldConfig<typeof FormTextInput> = {
  type: 'url',
  ariaLabel: 'Syllabus URL',
  label: 'Syllabus URL',
  name: 'syllabusUrl',
  placeholder: 'E.g.',
  required: true,
}

const SLUG: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Slug',
  label: 'Slug',
  name: 'slug',
  placeholder: 'E.g.',
  required: true,
}

const TITLE: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Title',
  label: 'Title',
  name: 'title',
  placeholder: 'E.g.',
  required: true,
}

const RESOURCE_URL: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Resource URL',
  label: 'Resource URL',
  name: 'resourceUrl',
  placeholder: 'E.g.',
}

const REQUIREMENTS: FieldConfig<typeof FormLongTextInput> = {
  ariaLabel: 'Requirements',
  label: 'Requirements',
  name: 'requirements',
  placeholder: 'E.g.',
  height: 6,
  required: true,
}

const OVERVIEW: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Overview',
  // label: 'Overview',
  name: 'overview',
  placeholder: 'E.g.',
  required: true,
}

const TARGET_AUDIENCE: FieldConfig<typeof FormLongTextInput> = {
  ariaLabel: 'Target audience',
  label: 'Target audience',
  name: 'targetAudience',
  placeholder: 'E.g.',
  required: true,
  height: 6,
}

const SHORT_DESCRIPTION: FieldConfig<typeof FormLongTextInput> = {
  ariaLabel: 'Short description',
  label: 'Short description',
  name: 'shortDescription',
  placeholder: 'E.g.',
  height: 6,
  required: true,
}

const SUBJECTS: FieldConfig<typeof FormMultiSelectInput> = {
  ariaLabel: 'Subjects',
  label: 'Subjects',
  name: 'subjects',
  placeholder: 'E.g.',
  options: Object.values(API.CourseSubject).map((item) => ({
    display: item,
    value: item,
  })),
  required: true,
}

const WISTIA_PROJECT_IDS: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Wistia project IDs',
  label: 'Wistia project IDs',
  name: 'wistiaProjectIds',
  placeholder: 'E.g. 12345, 908653',
  hint: 'Use comma separated values',
}

const ORDER: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Order',
  label: 'Order',
  name: 'order',
  type: 'number',
  placeholder: 'E.g.',
  required: true,
}

const IMAGE: FieldConfig<typeof InputImage> = {
  label: 'Image',
  ariaLabel: 'Image',
  name: 'image',
  required: true,
}

export const FIELDS = {
  DESCRIPTION,
  DIFFICULTY_LEVEL,
  LENGTH,
  INSTRUCTORS,
  MAILCHIMP_TAG,
  SLACK_CHANNEL_ID,
  SLACK_CHANNEL_NAME,
  SYLLABUS_URL,
  SLUG,
  TITLE,
  REQUIREMENTS,
  SHORT_DESCRIPTION,
  SUBJECTS,
  WISTIA_PROJECT_IDS,
  ORDER,
  IMAGE,
  STATUS,
  IS_BETA,
  OVERVIEW,
  RESOURCE_URL,
  HAS_ALL_CHAPTERS,
  TARGET_AUDIENCE,
}

export const VALIDATION_SCHEMA = {
  description: string().required(),
  difficultyLevel: string().oneOf(Object.values(API.CourseDifficultyLevel)).required(),
  image: object({
    url: string(),
    value: string().required(),
    file: mixed().required(),
  }).required(),
  instructors: array().of(string().required()).required(),
  length: string().oneOf(Object.values(API.CourseLength)).required(),
  mailchimpTag: string().required(),
  order: number().required(),
  resourceUrl: string(),
  requirements: string().required(),
  shortDescription: string().required(),
  slackChannelId: string().required(),
  slackChannelName: string().required(),
  syllabusUrl: string().required(),
  overview: array().of(string().required()).required(),
  targetAudience: string().required(),
  status: string().oneOf(Object.values(API.CourseStatus)).required(),
  slug: string().required(),
  subjects: array()
    .of(string().oneOf(Object.values(API.CourseSubject)).required())
    .required(),
  title: string().required(),
  wistiaProjectIds: string(),
  // wistiaProjectIds: array().of(string().required()),
  isBeta: boolean().required(),
  hasAllChapters: boolean().required(),
}
