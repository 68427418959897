import { gql } from '@apollo/client'

export default gql`
  fragment PriceConversionPreview on PriceConversion {
    currency {
      code
      name
    }
    value
  }
`
