import { API } from '../../../../../api'
import { PageView } from '../../../utils'

import { QUERY } from './queries'

const View: PageView<API.resources_view_resource> = {
  queryKey: 'resource',
  query: QUERY,
}

export default View
