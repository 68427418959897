import React from 'react'
import { string, number, boolean, array, object } from 'yup'
import { DocumentNode } from '@apollo/client'
import {
  Form,
  FormCheckboxInput,
  FormTextInput,
  FormSelectInput,
  FormLongTextInput,
} from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'

import Clauses from './Clauses'

const TYPE_OPTIONS = Object.values(API.DiscountType).map((type) => ({
  display: type,
  value: type,
}))

export type FormData = {
  name: string
  type: API.DiscountType
  code: string
  description?: string

  isPaused?: boolean
  isStackable: boolean

  percent: number

  clauses: {
    countExpressions?: {
      operator: API.DiscountCountOperator
      value: number
    }[]
    productExpressions?: {
      operator: API.DiscountOperator
      value: string[]
    }[]
    typeExpressions?: {
      operator: API.DiscountOperator
      value: API.ExpressionTypeValue[]
    }[]
  }[]

  hasStartAt?: boolean
  startAt?: string
  hasEndAt?: boolean
  endAt?: string
}

type Props<MutationVariables> = {
  initialValues: FormData
  mutation: DocumentNode
  createVariablesFn: (formValues: FormData) => MutationVariables
}

function DiscountForm<MutationVariables>({
  initialValues,
  mutation,
  createVariablesFn,
}: Props<MutationVariables>) {
  return (
    <BaseForm<any, any>
      initialValues={initialValues}
      validationSchema={{
        name: string().required(),
        type: string().oneOf(Object.values(API.DiscountType)).required(),
        code: string().required(),
        description: string(),

        isPaused: boolean(),
        isStackable: boolean().required(),
        percent: number().required(),

        clauses: array()
          .of(
            object({
              countExpressions: array().of(
                object({
                  operator: string().oneOf(Object.values(API.DiscountCountOperator)).required(),
                  value: number().required(),
                }).required(),
              ),
              productExpressions: array().of(
                object({
                  operator: string().oneOf(Object.values(API.DiscountOperator)).required(),
                  value: array().of(string().required()).required(),
                }).required(),
              ),
              typeExpressions: array().of(
                object({
                  operator: string().oneOf(Object.values(API.DiscountOperator)).required(),
                  value: array()
                    .of(string().oneOf(Object.values(API.ExpressionTypeValue)).required())
                    .required(),
                }).required(),
              ),
            }).required(),
          )
          .required(),

        hasStartAt: boolean(),
        startAt: string(),
        hasEndAt: boolean(),
        endAt: string(),
      }}
      mutation={mutation}
      createVariablesFn={createVariablesFn}
    >
      <Form.Row>
        <FormTextInput
          ariaLabel="Name"
          label="Name"
          name="name"
          placeholder="e.g. 30% off everything"
          required
        />
      </Form.Row>

      <Form.Row>
        <FormLongTextInput
          label="Description"
          ariaLabel="Description"
          name="description"
          placeholder="e.g. for friends of SuperHi."
          height={6}
        />
      </Form.Row>

      <Form.Row>
        <FormSelectInput ariaLabel="Type" label="Type" name="type" options={TYPE_OPTIONS} />
      </Form.Row>

      <Form.Row>
        <FormTextInput
          ariaLabel="Code"
          label="Code"
          name="code"
          placeholder="e.g. WFH2020"
          required
        />
      </Form.Row>

      <Form.Row>
        <FormCheckboxInput
          name="isPaused"
          ariaLabel="Is paused?"
          label="Is paused?"
          hint="Use this to temporarily pause a discount"
        />
      </Form.Row>

      <Form.Row>
        <FormCheckboxInput
          name="isStackable"
          ariaLabel="Is stackable?"
          label="Is stackable?"
          // hint="Use this if you want this discount to be applied "
        />
      </Form.Row>

      <Form.Row>
        <FormTextInput
          type="number"
          ariaLabel="Percent"
          label="Percent"
          name="percent"
          min={0}
          max={100}
          step={1}
          hint="The percentage discount to be applied. E.g. 20"
          required
        />
      </Form.Row>

      <Clauses />

      <Form.Legend label="Active dates">
        <Form.Row>
          <FormCheckboxInput ariaLabel="has start at" name="hasStartAt">
            This discount has a start date.
          </FormCheckboxInput>
        </Form.Row>

        <Form.Peek<FormData, FormData['hasStartAt']> name="hasStartAt">
          {({ meta }) =>
            meta.value === true ? (
              <Form.Row>
                <FormTextInput
                  ariaLabel="start"
                  type="datetime-local"
                  label="Start"
                  name="startAt"
                  required
                />
              </Form.Row>
            ) : (
              <Form.Message>This will start immediately.</Form.Message>
            )
          }
        </Form.Peek>

        <Form.Row>
          <FormCheckboxInput ariaLabel="has end at" name="hasEndAt">
            This discount has an end date.
          </FormCheckboxInput>
        </Form.Row>

        <Form.Peek<FormData, FormData['hasEndAt']> name="hasEndAt">
          {({ meta }) =>
            meta.value === true ? (
              <Form.Row>
                <FormTextInput
                  ariaLabel="end"
                  type="datetime-local"
                  label="End"
                  name="endAt"
                  required
                />
              </Form.Row>
            ) : (
              <Form.Message>
                This discount will be active until deactivated or deleted.
              </Form.Message>
            )
          }
        </Form.Peek>
      </Form.Legend>
    </BaseForm>
  )
}

export default DiscountForm
