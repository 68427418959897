import React from 'react'
import { useQuery } from '@apollo/client'
import { FormSelectInput } from '@superhi/design'

import { API } from '../../api'

import { QUERY } from './queries'

const FormSelectCourseProject: React.FC<
  React.PropsWithChildren<Omit<React.ComponentProps<typeof FormSelectInput>, 'options'>>
> = (props) => {
  const { data } = useQuery<API.FormSelectCourseProject>(QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  if (!data?.courseProjects) {
    return null
  }

  const OPTIONS = data.courseProjects.nodes.map((item) => ({
    value: item.id,
    display: item.title,
  }))

  return <FormSelectInput {...props} options={OPTIONS} />
}

export default FormSelectCourseProject
