import { gql } from '@apollo/client'

export default gql`
  fragment OrderPreview on Order {
    id
    insertedAt
    total {
      currencyCode
      value
    }
  }
`
