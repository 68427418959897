import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@superhi/design'

import MUTATION from './mutations'

type Props = {
  orderId: string
}

const ResendReceiptButton: React.FC<React.PropsWithChildren<Props>> = ({ orderId }) => {
  const [mutationFn] = useMutation(MUTATION, { variables: { id: orderId } })

  const onSubmit = async () => {
    try {
      await mutationFn()

      window.alert('The receipt for this order has been sent.')
    } catch (e) {
      window.alert('Something went wrong')
    }
  }

  return (
    <Button fullWidth onClick={onSubmit}>
      Resend receipt
    </Button>
  )
}

export default ResendReceiptButton
