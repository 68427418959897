import gql from 'graphql-tag'

export const INDEX = gql`
  query hall_of_fame_index($page: Int!, $perPage: Int!) {
    hallOfFamers(page: $page, perPage: $perPage) {
      nodes {
        id
        title
        type
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`

export const VIEW = gql`
  query hall_of_fame_view($id: ID!) {
    hallOfFamer(id: $id) {
      id
      title
      type
      url
    }
  }
`
