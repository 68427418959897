import { Body, Button, Price, TextVerticalSpacer } from '@superhi/design'
import { formatStandard } from '@superhi/design/dist/cjs/components/utils/DateTime'
import sortBy from 'lodash.sortby'

import { createModelPage } from '../utils'
import { API } from '../../../api'
import { getStripeUrl } from '../../../utils/stripe'

import { INDEX, VIEW } from './queries'

const createHistory = (
  payments?: API.membership_invoices_view_membershipInvoice_payments[] | null,
): (
  | API.membership_invoices_view_membershipInvoice_payments
  | API.membership_invoices_view_membershipInvoice_payments_refunds
)[] => {
  if (!payments) {
    return []
  }

  return sortBy([...payments.flatMap((p) => [p, ...(p.refunds || [])])], ['insertedAt']).reverse()
}

export default createModelPage<
  API.membership_invoices_index_membershipInvoices_nodes[],
  API.membership_invoices_view_membershipInvoice
>({
  name: 'Membership Invoices',
  index: {
    queryKey: 'membershipInvoices',
    query: INDEX,
    formatData: (data) =>
      data.map((item) => ({
        ...item,
        // product: item.product.title,
        user: item.user.email,
        total: Price.format(item.total.value, item.total.currencyCode),
      })),
  },
  view: {
    queryKey: 'membershipInvoice',
    query: VIEW,
    formatData: ({ payments, ...data }) => ({
      ...data,
      history: (
        <TextVerticalSpacer>
          {createHistory(payments).map((history) => {
            switch (history.__typename) {
              case 'MembershipInvoicePayment': {
                return (
                  <div>
                    <div>
                      {Price.format(history.value.value, history.value.currencyCode)} payment
                      succeeded
                    </div>
                    <Body level="3" color="GREY_70">
                      {formatStandard(history.insertedAt)}
                    </Body>
                  </div>
                )
              }
              case 'MembershipInvoicePaymentRefund': {
                return (
                  <div>
                    <div>
                      {Price.format(history.value.value, history.value.currencyCode)} refunded
                    </div>
                    <Body level="3" color="GREY_70">
                      {formatStandard(history.insertedAt)}
                    </Body>
                  </div>
                )
              }
            }
          })}
          <div>
            <div>Created</div>
            <Body level="3" color="GREY_70">
              {formatStandard(data.createdAt)}
            </Body>
          </div>
        </TextVerticalSpacer>
      ),
    }),
    actions: (id) => (
      <>
        <Button href={getStripeUrl(`/invoices/${id}`)} fullWidth version="outline" isExternal>
          View in Stripe
        </Button>
        <Button href={`/membership-refunds/new?invoiceId=${id}`} fullWidth>
          Create refund
        </Button>
      </>
    ),
  },
})
