import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { Wrapper } from './styles'

type Props = {
  id: string
  children: React.ReactNode
}

const SortableItem: React.FC<React.PropsWithChildren<Props>> = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Wrapper ref={setNodeRef} style={style as any} {...attributes} {...listeners}>
      {children}
    </Wrapper>
  )
}

export default SortableItem
