import gql from 'graphql-tag'

export default gql`
  fragment MembershipProductPreview on MembershipProduct {
    id
    title
    price {
      currencyCode
      value
      recurring {
        interval
        intervalCount
      }
    }
  }
`
