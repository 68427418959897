import { FieldConfig } from '../utils'

export const NAME: FieldConfig<unknown> = {
  type: 'string',
  label: 'Name',
  ariaLabel: 'Name',
  name: 'name',
  placeholder: 'E.g. Rik Lomas',
  required: true,
}

export const SLUG: FieldConfig<unknown> = {
  type: 'string',
  label: 'Slug',
  ariaLabel: 'Slug',
  name: 'slug',
  placeholder: 'E.g. rik-lomas',
  required: true,
}

export const IMAGE: FieldConfig<unknown> = {
  type: 'image',
  label: 'Image',
  ariaLabel: 'Image',
  name: 'image',
  required: true,
}

export const DESCRIPTION: FieldConfig<unknown> = {
  type: 'longtext',
  label: 'Description',
  ariaLabel: 'Description',
  height: 6,
  name: 'description',
  placeholder:
    "E.g. Rik is a Mancunian coder, teacher and CEO of SuperHi. He was the co-founder of Steer (a code school in London) and has taught several thousand people to code. He is a bit too old to be posting memes on our social media and recently featured as a Sour Patch Kid in the Macy's Thanksgiving Parade.",
  required: true,
}

export const IS_SUPER_HI: FieldConfig<unknown> = {
  type: 'boolean',
  label: 'Is SuperHi?',
  ariaLabel: 'Is SuperHi?',
  name: 'isSuperhi',
  required: false,
  text: '',
}

export const STARTED_ON: FieldConfig<unknown> = {
  type: 'date',
  label: 'Started on',
  ariaLabel: 'Started on',
  name: 'startedOn',
  required: false,
}

export const JOB_TITLE: FieldConfig<unknown> = {
  type: 'string',
  label: 'Job title',
  ariaLabel: 'Job title',
  name: 'jobTitle',
  placeholder: 'E.g. Founder & CEO',
  required: true,
}

export const LOCATION: FieldConfig<unknown> = {
  type: 'string',
  label: 'Location',
  ariaLabel: 'Location',
  name: 'location',
  placeholder: 'E.g. New York, USA',
  required: false,
}

export const FACEBOOK: FieldConfig<unknown> = {
  type: 'string',
  label: 'Facebook',
  ariaLabel: 'Facebook',
  name: 'facebook',
  placeholder: 'E.g. riklomas',
  required: false,
}

export const INSTAGRAM: FieldConfig<unknown> = {
  type: 'string',
  label: 'Instagram',
  ariaLabel: 'Instagram',
  name: 'instagram',
  placeholder: 'E.g. riklomas',
  required: false,
}

export const TWITTER: FieldConfig<unknown> = {
  type: 'string',
  label: 'Twitter',
  ariaLabel: 'Twitter',
  name: 'twitter',
  placeholder: 'E.g. riklomas',
  required: false,
}
