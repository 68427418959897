import gql from 'graphql-tag'

export default gql`
  mutation ProductCourseCreate(
    $courseId: ID!
    $description: String!
    $image: Upload!
    $priceId: ID
    $fulfillableIds: [ID!]
    $instructorIds: [ID!]
    $shortDescription: String!
    $slug: String!
    $title: String!
    $variants: [ProductVariantCreateInput!]
    $contents: [ProductContentInput!]!
    $difficultyLevel: ProductCourseDifficultyLevel!
    $length: ProductCourseLength!
    $requirements: String!
    $subjects: [ProductCourseSubject!]!
    $releaseDate: Date
    $syllabusUrl: String!
    $targetAudience: String!
    $overview: [String!]!
    $isBestSeller: Boolean!
    $projects: [ProductCourseProjectCreateInput!]
    $maxQuantity: Int
    $order: Int!
    $status: ProductStatus!
    $mailchimpTag: String!
    $isBeta: Boolean!
    $trailerWistiaId: String
  ) {
    productCourseCreate(
      courseId: $courseId
      description: $description
      image: $image
      priceId: $priceId
      releaseDate: $releaseDate
      fulfillableIds: $fulfillableIds
      shortDescription: $shortDescription
      slug: $slug
      title: $title
      variants: $variants
      contents: $contents
      difficultyLevel: $difficultyLevel
      length: $length
      requirements: $requirements
      subjects: $subjects
      syllabusUrl: $syllabusUrl
      targetAudience: $targetAudience
      overview: $overview
      isBestSeller: $isBestSeller
      projects: $projects
      maxQuantity: $maxQuantity
      instructorIds: $instructorIds
      order: $order
      status: $status
      mailchimpTag: $mailchimpTag
      isBeta: $isBeta
      trailerWistiaId: $trailerWistiaId
    ) {
      id
    }
  }
`
