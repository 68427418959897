import React from 'react'
import { useQuery } from '@apollo/client'
import { FormMultiSelectInput } from '@superhi/design'

import { API } from '../../api'

import QUERY from './queries'

type Props = Omit<React.ComponentProps<typeof FormMultiSelectInput>, 'options'>

const MultiSelectPerson: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { data } = useQuery<API.MultiSelectPerson>(QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  if (!data?.people) {
    return null
  }

  const OPTIONS = data.people.nodes.map((person) => ({
    value: person.id,
    display: person.name,
  }))

  return <FormMultiSelectInput<any> {...props} options={OPTIONS} />
}

export default MultiSelectPerson
