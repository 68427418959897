import gql from 'graphql-tag'

export default gql`
  query course_lessons_update($id: ID!) {
    courseLesson(id: $id) {
      description
      id
      slug
      title
      wistiaId
    }
  }
`
