import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Form, FormSelectInput, FormTextInput } from '@superhi/design'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'

import { MEMBERSHIP_QUERY } from './queries'
import MUTATION from './mutations'
import { FormValues, VALIDATION_SCHEMA } from './config'

const UserUpdate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<any>()
  const { data: membershipData } = useQuery<API.membership_products_summary>(MEMBERSHIP_QUERY)

  if (!membershipData?.membershipProducts) {
    return null
  }

  const products = membershipData.membershipProducts.nodes

  const membershipOptions = [
    { display: 'Choose one', value: undefined },
    ...products.map((p) => ({
      display: `${p.title} (${p.price?.recurring?.interval})`,
      value: p.id,
    })),
  ]

  return (
    <BaseForm<FormValues, API.user_membership_createVariables>
      initialValues={{
        membershipProductId: '',
        stripeSubscriptionId: '',
      }}
      validationSchema={VALIDATION_SCHEMA}
      mutation={MUTATION}
      createVariablesFn={(formValues) => ({
        ...formValues,
        userId: id,
      })}
    >
      <Form.Rows>
        <FormTextInput
          name="stripeSubscriptionId"
          id="stripe"
          label="Stripe Subscription ID"
          ariaLabel="Stripe Subscription ID"
        />
        <FormSelectInput
          name="membershipProductId"
          id="membership"
          label="Membership"
          ariaLabel="Membership"
          options={membershipOptions}
          required
        />
      </Form.Rows>
    </BaseForm>
  )
}

export default UserUpdate
