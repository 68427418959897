import { FieldConfig } from '../utils'

export const TITLE: FieldConfig<unknown> = {
  type: 'string',
  name: 'title',
  label: 'Title',
  ariaLabel: 'Title',
  placeholder: 'e.g. Learn To Code Now Book',
  required: true,
}

export const DESCRIPTION: FieldConfig<unknown> = {
  type: 'longtext',
  name: 'description',
  label: 'Description',
  ariaLabel: 'Description',
  placeholder: 'e.g. Something descriptive',
  height: 6,
  required: false,
}

export const FULFILLMENT_ID: FieldConfig<unknown> = {
  type: 'string',
  name: 'fulfillmentId',
  label: 'Fulfillment ID',
  ariaLabel: 'Fulfillment ID',
  placeholder: 'e.g. 123456',
  hint: 'This is the Whiplash ID',
  required: true,
}

export const SKU: FieldConfig<unknown> = {
  type: 'string',
  name: 'sku',
  label: 'SKU',
  ariaLabel: 'SKU',
  placeholder: 'e.g. SH-123',
  required: true,
}

export const ALLOW_MULTIPLE_PER_ORDER: FieldConfig<unknown> = {
  type: 'boolean',
  name: 'allowMultiplePerOrder',
  label: 'Allow multiple per order?',
  ariaLabel: 'Allow multiple per order?',
  text: '',
  required: false,
}
