import { Body, Link } from '@superhi/design'

import { API } from '../../../../../api'

type Props = Partial<API.CourseHomeworkPreview> & {}

const CourseHomeworkPreview: React.FC<React.PropsWithChildren<Props>> = ({ id, title }) => {
  const exists = !!id

  return (
    <div>
      <Body level="3" bold={exists} inline color={exists ? 'GREY_90' : 'GREY_60'}>
        {exists ? (
          <Link href={`/course-homeworks/${id}`}>[Homework] {title}</Link>
        ) : (
          `<No homework>`
        )}
      </Body>
    </div>
  )
}

export { default as FRAGMENT } from './fragments'
export default CourseHomeworkPreview
