import gql from 'graphql-tag'

export const QUERY = gql`
  query teams_view($id: ID!) {
    team(id: $id) {
      id
      title
      adminEmail
      admin {
        id
        email
      }
      users {
        id
        email
      }
      membership {
        id
        status
        plan {
          stripeSubscriptionId
          renewalDate
          renewalCost {
            value
            currencyCode
          }
        }
      }
      plan
      insertedAt
    }
  }
`
