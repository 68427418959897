import gql from 'graphql-tag'

export default gql`
  query orders_update($id: ID) {
    order(id: $id) {
      id
      giftDetails {
        email
        date
      }
    }
  }
`
