import gql from 'graphql-tag'

export default gql`
  fragment UserPreviewComponent on User {
    id
    email
    name
    avatar {
      url
    }
    type
  }
`
