import { UserChip } from '@superhi/design'

import { API } from '../../../../../../api'

type Props = API.ProductCoursesPreview & {}

const ProductCoursesPreview: React.FC<React.PropsWithChildren<Props>> = ({ id, image, title }) => (
  <UserChip
    name={title}
    image={{ src: image?.url || '' }}
    link={{
      href: `/product-courses/${id}`,
    }}
  />
)

export { default as FRAGMENT } from './fragments'
export default ProductCoursesPreview
