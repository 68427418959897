import React from 'react'
import { useQuery } from '@apollo/client'
import { FormMultiSelectInput } from '@superhi/design'

import { API } from '../../api'

import QUERY from './queries'

type Props = Omit<React.ComponentProps<typeof FormMultiSelectInput>, 'options'>

const MultiSelectProductFulfillable: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { data } = useQuery<API.MultiSelectProductFulfillable>(QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  if (!data?.productFulfillables) {
    return null
  }

  const OPTIONS = data.productFulfillables.nodes.map((fulfillable) => ({
    value: fulfillable.id,
    display: fulfillable.title,
  }))

  return <FormMultiSelectInput<any> {...props} options={OPTIONS} />
}

export default MultiSelectProductFulfillable
