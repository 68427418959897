import gql from 'graphql-tag'

import { FRAGMENT as ORDER_LINE_ITEM_FRAGMENT } from './components/OrderLineItemPreview'

export const INDEX = gql`
  query orders_index($page: Int, $perPage: Int, $search: String) {
    orders(page: $page, perPage: $perPage, search: $search) {
      nodes {
        id
        customerDetails {
          name
          email
        }
        insertedAt
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`

export const VIEW = gql`
  query orders_view($id: ID!) {
    order(id: $id) {
      id
      type

      customerDetails {
        name
        email
      }

      giftDetails {
        name
        email
        date
      }

      notes

      type

      lineItems {
        nodes {
          ...OrderLineItemPreview
        }
      }

      payments {
        value {
          currencyCode
          value
        }
        method
        paidAt
      }

      billingAddress {
        name
        line1
        line2
        city
        state
        country
        postalCode
      }

      shippingAddress {
        name
        line1
        line2
        city
        state
        country
        postalCode
      }

      # paymentDetails {
      #   paypal {
      #     token
      #   }
      #   stripe {
      #     methodId
      #   }
      # }

      # taxId

      total {
        currencyCode
        value
      }

      totalDiscount {
        value
        currencyCode
      }

      value {
        currencyCode
        value
      }

      refunds(perPage: 100) {
        nodes {
          id
          insertedAt
        }
      }

      # discounts {
      #   nodes {
      #     code
      #     description
      #     name
      #     percent
      #   }
      # }

      # referralSource

      version

      insertedAt
    }
  }

  ${ORDER_LINE_ITEM_FRAGMENT}
`
