import gql from 'graphql-tag'

export default gql`
  mutation HasOverdueInvoiceButton($id: ID!, $hasOverdueInvoice: Boolean!) {
    userUpdateHasOverdueInvoice(hasOverdueInvoice: $hasOverdueInvoice, id: $id) {
      id
      hasOverdueInvoice
    }
  }
`
