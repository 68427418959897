import gql from 'graphql-tag'

export default gql`
  fragment UserCoursePreview on UserCourse {
    id
    accessType
    progress {
      status
      percentage
    }
    course {
      id
      title
      image {
        url
      }
    }
  }
`
