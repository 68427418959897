import { FormTextInput } from '@superhi/design'
import React from 'react'

const FormDateTimeInput: React.FC<
  React.PropsWithChildren<React.ComponentProps<typeof FormTextInput>>
> = (props) => {
  // const formik = useFormikContext<any>();

  // const value = formik.values[props.name];

  return <FormTextInput {...props} type="datetime-local" />
}

export default FormDateTimeInput
