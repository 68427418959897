import { createModelPage } from '../utils'
import { API } from '../../../api'

import Update from './pages/Update'
import View from './pages/View'
import { INDEX } from './queries'

export default createModelPage<
  Pick<API.users_view_user, 'email' | 'id' | 'insertedAt' | 'membership' | 'name'>[],
  API.users_view_user
>({
  name: 'Membership Creation',
  index: {
    queryKey: 'users',
    query: INDEX,
    hideSearch: false,
    formatData: (users) =>
      users
        .filter((user) => user.membership === null)
        .map(({ membership, ...user }) => ({
          ...user,
        })),
  },
  edit: Update,
  view: View,
})
