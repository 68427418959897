import gql from 'graphql-tag'

export const INVOICE = gql`
  query membership_refunds_create_invoice($id: ID!) {
    membershipInvoice(id: $id) {
      id
      product {
        id
        title
      }
      discounts {
        ... on MembershipDiscountCoupon {
          name
        }
        ... on MembershipDiscountPromoCode {
          coupon {
            name
          }
        }
      }
      value {
        currencyCode
        value
      }
      totalDiscount {
        currencyCode
        value
      }
      total {
        currencyCode
        value
      }
      user {
        id
        email
      }
      createdAt
    }
  }
`
