import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@superhi/design'

import MUTATION from './mutations'

type Props = {
  refundId: string
}

const CancelRefundButton: React.FC<React.PropsWithChildren<Props>> = ({ refundId }) => {
  const [mutationFn] = useMutation(MUTATION, { variables: { id: refundId } })

  const onSubmit = async () => {
    try {
      await mutationFn()

      window.alert('This refund has been cancelled.')
    } catch (e) {
      window.alert('Something went wrong.')
    }
  }

  return (
    <Button fullWidth onClick={onSubmit}>
      Cancel refund
    </Button>
  )
}

export default CancelRefundButton
