import gql from 'graphql-tag'

export default gql`
  query resources_update($id: ID!) {
    resource(id: $id) {
      id
      description
      image {
        url
      }
      insertedAt
      tags
      title
      url
    }
  }
`
