import { gql } from '@apollo/client'

export default gql`
  fragment ProductVariantPreview on ProductVariant {
    id
    title
    product {
      id
      title
      image {
        url
      }
    }
  }
`
