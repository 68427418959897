import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Form, FormTextInput } from '@superhi/design'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'
import MultiSelectCourse from '../../../../../components/MultiSelectCourse'

import QUERY from './queries'
import MUTATION from './mutations'
import { FormValues, FIELD_CONFIGS, VALIDATION_SCHEMA } from './config'

const UserUpdate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.user_update>(QUERY, { variables: { id } })

  if (!data?.user) {
    return null
  }

  const disabledIds =
    data.user.courses?.nodes
      .filter((course) => course.accessType === API.UserCourseAccessType.MEMBERSHIP)
      .map((course) => course.course.id) || []

  return (
    <BaseForm<FormValues, API.UserUpdateVariables>
      initialValues={{
        email: data.user.email,
        directAccessCourseIds:
          data.user.courses?.nodes
            .filter((course) => course.accessType === API.UserCourseAccessType.DIRECT)
            .map((course) => course.course.id) || [],
      }}
      validationSchema={VALIDATION_SCHEMA}
      mutation={MUTATION}
      createVariablesFn={(formValues) => ({
        id,
        ...formValues,
      })}
    >
      <Form.Rows>
        <FormTextInput {...FIELD_CONFIGS.EMAIL} />

        <MultiSelectCourse {...FIELD_CONFIGS.DIRECT_ACCESS_COURSE_IDS} disabledIds={disabledIds} />
      </Form.Rows>
    </BaseForm>
  )
}

export default UserUpdate
