import gql from 'graphql-tag'

export const VIEW = gql`
  query settings_view {
    settings {
      announcement {
        id
        body
        path
      }
    }
  }
`
