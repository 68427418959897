import gql from 'graphql-tag'

export default gql`
  query teams_update($id: ID!) {
    team(id: $id) {
      id
      title
      plan
      adminEmail
      users {
        id
        email
      }
    }
  }
`
