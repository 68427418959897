import { createModelPage } from '../utils'

import Create from './Create'
import Update from './Update'
import { INDEX, VIEW } from './queries'
import { DELETE } from './mutations'

export default createModelPage<any, any>({
  name: 'Currencies',
  index: {
    queryKey: 'currencies',
    query: INDEX,
  },
  view: {
    queryKey: 'currency',
    query: VIEW,
  },
  create: Create,
  edit: Update,
  destroy: {
    mutation: DELETE,
  },
})
