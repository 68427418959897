import gql from 'graphql-tag'

export default gql`
  mutation courses_create_mutation(
    $description: String!
    $difficultyLevel: CourseDifficultyLevel!
    $image: Upload!
    $instructors: [ID!]!
    $length: CourseLength!
    $mailchimpTag: String!
    $order: Int!
    $shortDescription: String!
    $slackChannelId: String!
    $slackChannelName: String!
    $syllabusUrl: String!
    $slug: String!
    $subjects: [CourseSubject!]!
    $title: String!
    $wistiaProjectIds: [String!]
    $isBeta: Boolean!
    $overview: [String!]!
    $targetAudience: String!
    $requirements: String!
    $resourceUrl: String
    $hasAllChapters: Boolean!
    $status: CourseStatus!
  ) {
    courseCreate(
      description: $description
      difficultyLevel: $difficultyLevel
      image: $image
      instructors: $instructors
      length: $length
      mailchimpTag: $mailchimpTag
      order: $order
      requirements: $requirements
      shortDescription: $shortDescription
      slackChannelId: $slackChannelId
      slackChannelName: $slackChannelName
      syllabusUrl: $syllabusUrl
      slug: $slug
      subjects: $subjects
      title: $title
      wistiaProjectIds: $wistiaProjectIds
      isBeta: $isBeta
      overview: $overview
      targetAudience: $targetAudience
      resourceUrl: $resourceUrl
      hasAllChapters: $hasAllChapters
      status: $status
    ) {
      id
    }
  }
`
