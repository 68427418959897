import gql from 'graphql-tag'

export default gql`
  query product_fulfillables_update($id: ID!) {
    productFulfillable(id: $id) {
      id
      title
      sku
      description
      fulfillmentId
      allowMultiplePerOrder
    }
  }
`
