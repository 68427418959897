import React from 'react'
import { useQuery } from '@apollo/client'
import { FormSelectInput } from '@superhi/design'
import { useFormikContext } from 'formik'

import { API } from '../../api'

import QUERY from './queries'

const SelectCurrency: React.FC<
  React.PropsWithChildren<Omit<React.ComponentProps<typeof FormSelectInput>, 'options'>>
> = (props) => {
  const { data } = useQuery<API.SelectCurrency>(QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  if (!data?.currencies) {
    return null
  }

  const OPTIONS = [
    { display: 'Choose one', value: undefined },
    ...data.currencies.nodes.map((currency) => ({
      value: currency.code,
      display: currency.name,
    })),
  ]

  return <FormSelectInput {...props} options={OPTIONS} />
}

export default SelectCurrency
