import gql from 'graphql-tag'

export default gql`
  mutation ProductCourseUpdateMutation(
    $id: ID!
    $slug: String!
    $title: String!
    $courseId: ID!
    $description: String!
    $fulfillableIds: [ID!]
    $instructorIds: [ID!]
    $image: Upload
    $priceId: ID
    $maxQuantity: Int
    $releaseDate: Date
    $shortDescription: String!
    $variants: [ProductVariantUpdateInput!]
    $contents: [ProductContentInput!]!
    $difficultyLevel: ProductCourseDifficultyLevel!
    $length: ProductCourseLength!
    $requirements: String!
    $subjects: [ProductCourseSubject!]!
    $syllabusUrl: String!
    $targetAudience: String!
    $overview: [String!]!
    $isBestSeller: Boolean!
    $projects: [ProductCourseProjectUpdateInput!]
    $order: Int!
    $status: ProductStatus!
    $mailchimpTag: String!
    $isBeta: Boolean!
    $trailerWistiaId: String
  ) {
    productCourseUpdate(
      id: $id
      courseId: $courseId
      description: $description
      image: $image
      priceId: $priceId
      releaseDate: $releaseDate
      fulfillableIds: $fulfillableIds
      shortDescription: $shortDescription
      instructorIds: $instructorIds
      slug: $slug
      title: $title
      variants: $variants
      contents: $contents
      difficultyLevel: $difficultyLevel
      length: $length
      requirements: $requirements
      subjects: $subjects
      syllabusUrl: $syllabusUrl
      targetAudience: $targetAudience
      overview: $overview
      isBestSeller: $isBestSeller
      projects: $projects
      maxQuantity: $maxQuantity
      order: $order
      status: $status
      mailchimpTag: $mailchimpTag
      isBeta: $isBeta
      trailerWistiaId: $trailerWistiaId
    ) {
      id
    }
  }
`
