import React from 'react'
import { Button, Form, FormTextInput } from '@superhi/design'
import { useFormikContext } from 'formik'

import { InitialValuesCreate } from '../../types'
import { cartesianProduct } from '../../../../../utils'

export type Option = {
  name: string
  value: string
}

const CreateOptions: React.FC<React.PropsWithChildren<unknown>> = () => {
  const formik = useFormikContext<InitialValuesCreate>()

  const handleClickCreateVariants = () => {
    const appliedOptions: Option[][] =
      formik.values.options
        ?.filter((option) => option.name !== '' && option.value !== '')
        .map((option) =>
          option.value
            .split(',')
            .filter((value) => value !== '')
            .map((value) => ({
              name: option.name,
              value: value.trim(),
            })),
        ) || []

    if ((appliedOptions?.length || 0) === 0) {
      return
    }

    formik.setFieldValue(
      'variants',
      cartesianProduct<Option>(appliedOptions).map((option) => ({
        title: '',
        priceId: '',
        fulfillableIds: undefined,
        options: option,
      })),
    )
  }

  return (
    <>
      <Form.Repeatable<InitialValuesCreate, 'options'>
        label="Options"
        name="options"
        defaultValue={{ name: '', value: '' }}
      >
        {({ name }) => (
          <Form.Split ratios={{ small: 'full', medium: 'full', large: [30, 70] }}>
            <Form.Row>
              <FormTextInput
                ariaLabel="Name"
                name={`${name}.name`}
                label="Name"
                placeholder="E.g. Color"
              />
            </Form.Row>

            <Form.Row>
              <FormTextInput
                name={`${name}.value`}
                label="Values"
                ariaLabel="Values"
                placeholder="E.g. red, blue, green"
              />
            </Form.Row>
          </Form.Split>
        )}
      </Form.Repeatable>

      <Button onClick={handleClickCreateVariants} version="outline" fullWidth>
        Create variants
      </Button>
    </>
  )
}

export default CreateOptions
