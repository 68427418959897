import gql from 'graphql-tag'

export default gql`
  mutation course_chapters_update_mutation(
    $id: ID!
    $slug: String!
    $title: String!
    $description: String!
    $lessons: [ID!]!
    $homeworkId: ID
    $projectId: ID
    $resourceUrl: String
    $isLocked: Boolean!
  ) {
    courseChapterUpdate(
      id: $id
      description: $description
      homeworkId: $homeworkId
      lessons: $lessons
      projectId: $projectId
      resourceUrl: $resourceUrl
      slug: $slug
      title: $title
      isLocked: $isLocked
    ) {
      id
    }
  }
`
