import gql from 'graphql-tag'

export const ORDER = gql`
  query refund_create($id: ID!) {
    order(id: $id) {
      id
      customerDetails {
        name
        email
      }
      giftDetails {
        name
        email
      }

      user {
        id
      }

      type

      lineItems {
        nodes {
          id
          title
          # price {
          #   currencyCode
          #   value
          # }
          product {
            __typename
            id
          }
          variant {
            id
          }
          quantity
        }
      }

      billingAddress {
        name
        line1
        line2
        city
        state
        country
        postalCode
      }

      shippingAddress {
        name
        line1
        line2
        city
        state
        country
        postalCode
      }

      refunds {
        nodes {
          lineItems {
            nodes {
              id
              orderLineItem {
                id
                variant {
                  id
                }
              }
              quantity
            }
          }
        }
      }

      # paymentDetails {
      #   paypal {
      #     token
      #   }
      #   stripe {
      #     methodId
      #   }
      # }

      # taxId

      total {
        currencyCode
        value
      }

      value {
        currencyCode
        value
      }

      refunds {
        nodes {
          id
          updatedAt
        }
      }

      discounts {
        nodes {
          code
          percent
        }
      }

      # referralSource

      version

      insertedAt
    }
  }
`
