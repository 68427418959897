import styled from 'styled-components'
import { theme } from '@superhi/design'

export const Wrapper = styled.div`
  padding: 16px;
  border: 1px solid ${theme.colors.GREY_50};
  background-color: ${theme.colors.WHITE};
  border-radius: 10px;
  cursor: move;
`
