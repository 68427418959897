import React from 'react'
import { useQuery } from '@apollo/client'
import { FormSelectInput } from '@superhi/design'

import { API } from '../../api'

import { QUERY } from './queries'

const FormSelectCourseHomework: React.FC<
  React.PropsWithChildren<Omit<React.ComponentProps<typeof FormSelectInput>, 'options'>>
> = (props) => {
  const { data } = useQuery<API.FormSelectCourseHomework>(QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  if (!data?.courseHomeworks) {
    return null
  }

  const OPTIONS = [
    {
      display: '<No homework>',
      value: null,
    },
    ...data.courseHomeworks.nodes.map((item) => ({
      value: item.id,
      display: item.title,
    })),
  ]

  return <FormSelectInput<any> {...props} options={OPTIONS} />
}

export default FormSelectCourseHomework
