import gql from 'graphql-tag'

export default gql`
  mutation ProductBookUpdateMutation(
    $description: String!
    $fulfillableIds: [ID!]
    $id: ID!
    $image: Upload
    $priceId: ID
    $shortDescription: String!
    $slug: String!
    $title: String!
    $variants: [ProductVariantUpdateInput!]
    $contents: [ProductContentInput!]!
    $releaseDate: Date
    $isBestSeller: Boolean!
    $previewDownloadUrl: String!
    $maxQuantity: Int
    $mailchimpTag: String!
  ) {
    productBookUpdate(
      id: $id
      description: $description
      image: $image
      priceId: $priceId
      fulfillableIds: $fulfillableIds
      shortDescription: $shortDescription
      releaseDate: $releaseDate
      slug: $slug
      title: $title
      variants: $variants
      isBestSeller: $isBestSeller
      previewDownloadUrl: $previewDownloadUrl
      contents: $contents
      maxQuantity: $maxQuantity
      mailchimpTag: $mailchimpTag
    ) {
      id
    }
  }
`
