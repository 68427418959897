import { API } from '../../../../../api'
import { PageList } from '../../../utils'

import { QUERY } from './queries'

const List: PageList<API.course_watched_summaries_index_courses_nodes> = {
  queryKey: 'courses',
  query: QUERY,
  // formatData: (data) => ({

  // })
}

export default List
