import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Form, FormLongTextInput, FormTextInput } from '@superhi/design'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'
import InputImage from '../../../../../components/InputImage'

import { FormValues } from './types'
import QUERY from './queries'
import MUTATION from './mutations'
import { FIELDS, VALIDATION_SCHEMA } from './config'

const CourseProjectsUpdate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.course_projects_update, API.course_projects_updateVariables>(
    QUERY,
    {
      variables: {
        id,
      },
    },
  )

  if (!data?.courseProject) {
    return null
  }

  return (
    <BaseForm<FormValues, API.course_projects_update_mutationVariables>
      mutation={MUTATION}
      validationSchema={VALIDATION_SCHEMA}
      initialValues={{
        description: data.courseProject.description || '',
        files: [],
        finishedUrl: data.courseProject.finishedUrl || '',
        briefUrl: data.courseProject.briefUrl || '',
        image: {
          url: data.courseProject.image.url,
          value: '',
          file: undefined,
        },
        links: [],
        slug: data.courseProject.slug,
        title: data.courseProject.title,
      }}
      createVariablesFn={(values) => ({
        ...values,
        id,
        image: values.image.file,
      })}
    >
      <Form.Rows>
        <FormTextInput {...FIELDS.TITLE} />
        <FormTextInput {...FIELDS.SLUG} />
        <InputImage {...FIELDS.IMAGE} />
        <FormLongTextInput {...FIELDS.DESCRIPTION} />
        <FormTextInput {...FIELDS.FINISHED_URL} />
        <FormTextInput {...FIELDS.BRIEF_URL} />
      </Form.Rows>
    </BaseForm>
  )
}

export default CourseProjectsUpdate
