import { getValueIndex } from '@dnd-kit/core/dist/utilities/other/getValueIndex'
import { Link, TextVerticalSpacer } from '@superhi/design'

import { API } from '../../../../../api'
import BooleanPreview from '../../../../../components/BooleanPreview'
import ImagePreview from '../../../../../components/ImagePreview'
import StringPreview from '../../../../../components/StringPreview'
import UrlPreview from '../../../../../components/UrlPreview'
import CourseHomeworkPreview from '../../../courseHomeworks/components/CourseHomeworkPreview'
import CourseLessonPreview from '../../../courseLessons/components/CourseLessonPreview'
import CourseProjectPreview from '../../../courseProjects/components/CourseProjectPreview'
import { PageView } from '../../../utils'

import { QUERY } from './queries'

const View: PageView<API.course_chapters_view_courseChapter> = {
  queryKey: 'courseChapter',
  query: QUERY,
  formatData: ({ lessons, homework, project, ...data }) => ({
    ...data,
    syllabus: (
      <TextVerticalSpacer>
        <div>
          {lessons && lessons.nodes.length > 0 ? (
            lessons.nodes.map((lesson) => <CourseLessonPreview key={lesson.id} {...lesson} />)
          ) : (
            <StringPreview emptyText="No lessons" />
          )}
        </div>

        <CourseHomeworkPreview {...homework} />

        <CourseProjectPreview {...project} />
      </TextVerticalSpacer>
    ),
  }),
}

export default View
