import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { DocumentNode } from 'graphql'
import { useMutation } from '@apollo/client'
import { Button, Form } from '@superhi/design'
import pluralize from 'pluralize'

import { getModelFromPath } from '../utils'
import Page from '../../../components/Page'

import Links from './Links'

export type Props<T> = {
  mutation: DocumentNode
  linksQuery?: React.ComponentProps<typeof Links>['query']
  linksQueryKey?: React.ComponentProps<typeof Links>['queryKey']
  linksFormatDataFn?: (data: T) => React.ReactElement
}

const Delete: React.FC<React.PropsWithChildren<Props<any>>> = ({
  mutation,
  linksQuery,
  linksQueryKey,
  linksFormatDataFn,
}) => {
  const { id } = useParams<any>()
  const modelName = getModelFromPath(useLocation().pathname)
  const modelNameUnderscore = modelName.replace(/-/g, '_')
  const history = useHistory()
  const [mutationFn, mutationResponse] = useMutation(mutation, {
    variables: {
      id,
    },
    refetchQueries: [
      `${modelNameUnderscore}_index`,
      `${modelNameUnderscore}_view`,
      `${modelNameUnderscore}_update`,
    ],
  })

  const handleClickDelete = async () => {
    const confirmed = window.confirm(
      `Are you sure you want to ${pluralize.singular(modelName.replace(/-/g, ' '))} ${id}?`,
    )

    if (confirmed) {
      try {
        await mutationFn()
        history.push(`/${modelName}`)
      } catch (e) {}
    }
  }

  return (
    <Page>
      {linksQuery && linksQueryKey && linksFormatDataFn && (
        <Links query={linksQuery} queryKey={linksQueryKey} formatData={linksFormatDataFn} />
      )}

      {mutationResponse.error && (
        <Form.Message type="error">
          {mutationResponse.error.graphQLErrors
            .map((e) => `${(e as any).details}: ${e.message}`)
            .join('. ')}
        </Form.Message>
      )}
      <Button onClick={handleClickDelete}>
        Delete {pluralize.singular(modelName.replace(/-/g, ' '))} {id}
      </Button>
    </Page>
  )
}

export default Delete
