import gql from 'graphql-tag'

export default gql`
  mutation membership_refunds_create_mutation(
    $membershipInvoiceId: ID!
    $reason: CreditNoteReason
  ) {
    membershipRefundCreate(membershipInvoiceId: $membershipInvoiceId, reason: $reason) {
      id
    }
  }
`
