import gql from 'graphql-tag'

export default gql`
  query product_collections_update($id: ID!) {
    productCollection(id: $id) {
      id
      title
      slug
      description
      isBestSeller
      image {
        url
      }
      variants {
        nodes {
          id
        }
      }
    }
  }
`
