import {
  Form,
  FormCheckboxInput,
  FormLongTextInput,
  FormMultiSelectInput,
  FormSelectInput,
  FormTextInput,
} from '@superhi/design'
import React from 'react'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'
import InputImage from '../../../../../components/InputImage'
import MultiSelectPerson from '../../../../../components/MultiSelectPerson'

import { FormValues } from './types'
import { FIELDS, VALIDATION_SCHEMA } from './config'
import MUTATION from './mutations'

const MembershipAffiliatesCreate: React.FC<React.PropsWithChildren<unknown>> = () => (
  <BaseForm<FormValues, API.courses_create_mutationVariables>
    mutation={MUTATION}
    validationSchema={VALIDATION_SCHEMA}
    initialValues={{
      slug: '',
      title: '',
      shortDescription: '',
      description: '',
      wistiaProjectIds: '',
      resourceUrl: '',
      image: {
        url: undefined,
        value: '',
        file: null,
      },
      overview: [],
      targetAudience: '',
      length: API.CourseLength.SHORT,
      difficultyLevel: API.CourseDifficultyLevel.BEGINNER,
      order: 1,
      mailchimpTag: '',
      slackChannelId: '',
      slackChannelName: '',
      syllabusUrl: '',
      subjects: [],
      requirements: '',
      instructors: [],
      isBeta: false,
      hasAllChapters: true,
      status: API.CourseStatus.LIVE,
    }}
    createVariablesFn={(values) => ({
      ...values,
      wistiaProjectIds: values.wistiaProjectIds
        ?.split(',')
        .map((item) => item.trim())
        .filter(Boolean),
      image: values.image.file,
    })}
  >
    <Form.Rows>
      <FormTextInput {...FIELDS.TITLE} />
      <FormTextInput {...FIELDS.SLUG} />
      <FormTextInput {...FIELDS.WISTIA_PROJECT_IDS} />
      <FormLongTextInput {...FIELDS.SHORT_DESCRIPTION} />
      <FormLongTextInput {...FIELDS.DESCRIPTION} />
      <InputImage {...FIELDS.IMAGE} />
      <FormSelectInput {...FIELDS.LENGTH} />
      <FormSelectInput {...FIELDS.STATUS} />
      <FormSelectInput {...FIELDS.DIFFICULTY_LEVEL} />
      <FormTextInput {...FIELDS.ORDER} />
      <FormTextInput {...FIELDS.MAILCHIMP_TAG} />
      <FormTextInput {...FIELDS.RESOURCE_URL} />
      <FormTextInput {...FIELDS.SLACK_CHANNEL_NAME} />
      <FormTextInput {...FIELDS.SLACK_CHANNEL_ID} />
      <FormTextInput {...FIELDS.SYLLABUS_URL} />
      <FormMultiSelectInput {...FIELDS.SUBJECTS} />
      <FormLongTextInput {...FIELDS.REQUIREMENTS} />
      <MultiSelectPerson {...FIELDS.INSTRUCTORS} />
      <FormCheckboxInput {...FIELDS.IS_BETA} />
      <FormCheckboxInput {...FIELDS.HAS_ALL_CHAPTERS} />
      <FormLongTextInput {...FIELDS.TARGET_AUDIENCE} />
      <Form.Repeatable<FormValues, 'overview'>
        name="overview"
        label="What youll learn"
        defaultValue={''}
      >
        {({ name }) => (
          <Form.Row>
            <FormTextInput {...FIELDS.OVERVIEW} name={name} />
          </Form.Row>
        )}
      </Form.Repeatable>
    </Form.Rows>
  </BaseForm>
)

export default MembershipAffiliatesCreate
