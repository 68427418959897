import { useQuery } from '@apollo/client'
import React from 'react'

// import { API } from '../../../../api';
import RedirectExternal from '../../../RedirectExternal'
import { API } from '../../../../api'

import Header from './Header'
import * as styles from './styles'
import { QUERY } from './queries'

const getIsNotProd = () =>
  process?.env?.REACT_APP_API_DOMAIN?.includes('staging') ||
  process?.env?.REACT_APP_API_DOMAIN?.includes('localhost') ||
  false

const Layout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const isNotProd = React.useMemo(() => getIsNotProd(), [])
  const { data, loading } = useQuery<API.URGH>(QUERY)

  const isLoading = loading
  const session = data?.session
  const isAdmin = data?.session?.isAdmin

  let content = children

  if (isLoading) {
    content = <div>Loading...</div>
  }

  if (!loading && !session) {
    content = <div>You must be logged in.</div>
  }

  if (!isAdmin) {
    content = <div>You must be an admin.</div>
  }

  return (
    <>
      <Header />
      <styles.Wrapper $isNotProd={isNotProd}>{content}</styles.Wrapper>
    </>
  )
}

export default Layout
