import { Link, TextVerticalSpacer } from '@superhi/design'

import { API } from '../../../../../api'
import BooleanPreview from '../../../../../components/BooleanPreview'
import ImagePreview from '../../../../../components/ImagePreview'
import StringPreview from '../../../../../components/StringPreview'
import CourseChapterPreview from '../../../courseChapters/components/CourseChapterPreview'
import CourseHomeworkPreview from '../../../courseHomeworks/components/CourseHomeworkPreview'
import CourseLessonPreview from '../../../courseLessons/components/CourseLessonPreview'
import CourseProjectPreview from '../../../courseProjects/components/CourseProjectPreview'
import PersonPreview from '../../../people/components/PersonPreview'
import { PageView } from '../../../utils'

import { QUERY } from './queries'

const View: PageView<API.courses_view_course> = {
  queryKey: 'course',
  query: QUERY,
  formatData: ({ chapters, ...data }) => ({
    ...data,
    syllabus: (
      <TextVerticalSpacer>
        {chapters?.nodes.map((chapter) => (
          <div key={chapter.id}>
            <CourseChapterPreview key={chapter.id} {...chapter} />

            <TextVerticalSpacer level="3">
              <div>
                <div>
                  {chapter.lessons && chapter.lessons.nodes.length > 0 ? (
                    chapter.lessons.nodes.map((lesson) => (
                      <CourseLessonPreview key={lesson.id} {...lesson} />
                    ))
                  ) : (
                    <StringPreview emptyText="No lessons" />
                  )}
                </div>
              </div>
              <CourseHomeworkPreview {...chapter.homework} />
              <CourseProjectPreview {...chapter.project} />
            </TextVerticalSpacer>
          </div>
        ))}
      </TextVerticalSpacer>
    ),
  }),
}

export default View
