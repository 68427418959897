import gql from 'graphql-tag'

export default gql`
  mutation HallOfFameItemUpdate(
    $id: ID!
    $image: Upload
    $slug: String!
    $title: String!
    $type: HallOfFamerType!
    $url: String
    $userIds: [ID!]
  ) {
    hallOfFamerUpdate(
      id: $id
      slug: $slug
      image: $image
      title: $title
      type: $type
      url: $url
      userIds: $userIds
    ) {
      id
    }
  }
`
