import gql from 'graphql-tag'

export const QUERY = gql`
  query MultiSelectCourseLessons($ids: [ID!]) {
    courseLessons(ids: $ids) {
      nodes {
        id
        title
      }
    }
  }
`

export const SEARCH_QUERY = gql`
  query MultiSelectCourseLessons_Search {
    courseLessons(perPage: 3000) {
      nodes {
        id
        title
      }
    }
  }
`
