import gql from 'graphql-tag'

export default gql`
  fragment CoursePreview on Course {
    id
    title
    image {
      url
    }
  }
`
