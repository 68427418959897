import { createModelPage } from '../utils'

import Create from './pages/Create'
import Update from './pages/Update'
import List from './pages/List'
import View from './pages/View'
// import { DELETE } from './mutations';

export default createModelPage<any, any>({
  name: 'Teams',
  index: List,
  view: View,
  create: Create,
  edit: Update,
  // destroy: {
  //   mutation: DELETE,
  // },
})
