import React from 'react'
import { string } from 'yup'
import { Form } from '@superhi/design'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import BaseForm from '../../../_templates/BaseForm'
import { API } from '../../../../../api'
import {
  CREATE_VARIABLES_UPDATE as BASE_CREATE_VARIABLES_UPDATE,
  INITIAL_VALUES_UPDATE as BASE_INITIAL_VALUES_UPDATE,
  VALIDATION_SCHEMA_UPDATE as BASE_VALIDATION_SCHEMA_UPDATE,
} from '../../config'
import { FormUpdate } from '../../Form'
import { InitialValuesUpdate as BaseInitialValuesUpdate } from '../../types'
import { configToInput } from '../../../utils'
import * as config from '../config'

import QUERY from './queries'
import MUTATION from './mutations'

type InitialValues = BaseInitialValuesUpdate & {
  previewDownloadUrl: string
  mailchimpTag: string
}

const ProductBookUpdate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.product_books_update>(QUERY, {
    variables: {
      id,
    },
  })

  if (!data?.productBook) {
    return null
  }

  return (
    <BaseForm<InitialValues, API.ProductBookUpdateMutationVariables>
      mutation={MUTATION}
      initialValues={{
        ...BASE_INITIAL_VALUES_UPDATE(data.productBook),
        previewDownloadUrl: data.productBook.previewDownloadUrl,
        mailchimpTag: data.productBook.mailchimpTag,
      }}
      validationSchema={{
        ...BASE_VALIDATION_SCHEMA_UPDATE,
        previewDownloadUrl: string().required(),
        mailchimpTag: string().required(),
      }}
      createVariablesFn={(formValues) => ({
        ...BASE_CREATE_VARIABLES_UPDATE({ ...formValues, id }),
        previewDownloadUrl: formValues.previewDownloadUrl,
        mailchimpTag: formValues.mailchimpTag,
      })}
    >
      <FormUpdate />

      <Form.Row>{configToInput(config.PREVIEW_DOWNLOAD_URL)}</Form.Row>

      <Form.Row>{configToInput(config.MAILCHIMP_TAG)}</Form.Row>
    </BaseForm>
  )
}

export default ProductBookUpdate
