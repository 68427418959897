import { Price } from '@superhi/design'

import { API } from '../../../../../api'
import StringPreview from '../../../../../components/StringPreview'

type Props = API.PriceConversionPreview & {}

const PriceConversionPreview: React.FC<React.PropsWithChildren<Props>> = ({ currency, value }) => (
  <StringPreview>
    {`${currency.name}: ${Price.format(value, currency.code as API.CurrencyCode)}`}
  </StringPreview>
)

export { default as FRAGMENT } from './fragments'
export default PriceConversionPreview
