import gql from 'graphql-tag'

export const REFUND_DRAFT = gql`
  mutation refund_draft(
    $orderId: ID!
    $lineItems: [RefundLineItemInput!]!
    $exchangeItems: [RefundExchangeItemInput!]
  ) {
    refundDraft(orderId: $orderId, lineItems: $lineItems, exchangeItems: $exchangeItems) {
      total {
        value
        currencyCode
      }
    }
  }
`
