import { API } from '../../../api'
import { FieldConfig } from '../utils'

import { FormValues } from './Create'

export const TYPE_OPTIONS = Object.values(API.HallOfFamerType).map((option) => ({
  display: option,
  value: option,
}))

export const TITLE: FieldConfig<FormValues> = {
  type: 'string',
  label: 'Title',
  ariaLabel: 'Title',
  name: 'title',
  required: true,
  placeholder: 'E.g. Portfolio',
}

export const SLUG: FieldConfig<FormValues> = {
  type: 'string',
  label: 'Slug',
  ariaLabel: 'Slug',
  name: 'slug',
  required: true,
  placeholder: 'E.g. portfolio-by-user',
}

export const TYPE: FieldConfig<FormValues> = {
  type: 'select',
  label: 'Type',
  ariaLabel: 'Type',
  name: 'type',
  required: true,
  options: TYPE_OPTIONS,
}

export const URL: FieldConfig<FormValues> = {
  type: 'string',
  label: 'URL',
  ariaLabel: 'URL',
  name: 'url',
  required: true,
  placeholder: 'E.g. http://www.google.com',
}

export const USERS: FieldConfig<FormValues> = {
  type: 'string',
  required: false,
  label: 'Users',
  ariaLabel: 'Users',
  hint: 'This is a comma separated list of user IDs',
  name: 'userIds',
  placeholder: 'E.g. 1456, 684, 45556',
}

export const IMAGE: FieldConfig<FormValues> = {
  type: 'image',
  required: true,
  label: 'Image',
  ariaLabel: 'Image',
  name: 'image',
}
