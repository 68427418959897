import gql from 'graphql-tag'

export const QUERY = gql`
  query course_watched_summaries_index {
    courses {
      nodes {
        id
        title
      }
    }
  }
`
