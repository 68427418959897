import { Body, Link, Markdown } from '@superhi/design'

import StringPreview from '../StringPreview'

type Props = { children?: string | null }

const MarkdownPreview = ({ children }: Props) => {
  const isEmpty = !children || children === ''

  return !isEmpty ? (
    <Markdown
      overrides={{
        p: (bodyProps) => <Body {...bodyProps} level="3" />,
        a: (linkProps) => <Link {...linkProps} />,
      }}
    >
      {children!}
    </Markdown>
  ) : (
    <StringPreview>{children}</StringPreview>
  )
}

export default MarkdownPreview
