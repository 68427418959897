import { Form, FormCheckboxInput, FormLongTextInput, FormTextInput } from '@superhi/design'
import React from 'react'

import { API } from '../../../../../api'
import BaseForm from '../../../_templates/BaseForm'
import InputImage from '../../../../../components/InputImage'

import { FormValues } from './types'
import { FIELDS, VALIDATION_SCHEMA } from './config'
import MUTATION from './mutations'

const CourseHomeworksCreate: React.FC<React.PropsWithChildren<unknown>> = () => (
  <BaseForm<FormValues, API.course_homeworks_create_mutationVariables>
    mutation={MUTATION}
    validationSchema={VALIDATION_SCHEMA}
    initialValues={{
      description: '',
      files: [],
      finishedUrl: '',
      briefUrl: '',
      image: {
        url: '',
        value: '',
        file: null,
      },
      links: [],
      slug: '',
      title: '',
      isRequired: true,
    }}
    createVariablesFn={(values) => ({
      ...values,
    })}
  >
    <Form.Rows>
      <FormTextInput {...FIELDS.TITLE} />
      <FormTextInput {...FIELDS.SLUG} />
      <InputImage {...FIELDS.IMAGE} />
      <FormLongTextInput {...FIELDS.DESCRIPTION} />
      <FormTextInput {...FIELDS.FINISHED_URL} />
      <FormTextInput {...FIELDS.BRIEF_URL} />
      <FormCheckboxInput {...FIELDS.IS_REQUIRED} />
    </Form.Rows>
  </BaseForm>
)

export default CourseHomeworksCreate
