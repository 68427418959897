import { API } from '../../../../../api'
import { PageView } from '../../../utils'

import { QUERY } from './queries'

const View: PageView<API.membership_affiliates_view_membershipAffiliate> = {
  queryKey: 'membershipAffiliate',
  query: QUERY,
  formatData: ({ applicationFeePercent, ...data }) => ({
    ...data,
    applicationFeePercent: applicationFeePercent,
  }),
}

export default View
