import { theme } from '@superhi/design'
import styled from 'styled-components'

export const Preview = styled.img`
  display: block;
  width: 100%;
  height: 35vh;
  background-color: ${theme.colors.GREY_10};
  object-fit: contain;
`

export const Wrapper = styled.div`
  position: relative;
`

export const Info = styled.div`
  background-color: ${theme.colors.WHITE};
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 1em;
`
