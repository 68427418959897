import React, { useMemo } from 'react'
import { useQuery, DocumentNode } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import { Button, TextInput } from '@superhi/design'
import { useDebounce } from 'react-use'

import Page from '../../../../components/Page'
import { getModelFromPath } from '../../utils'

import Table from './Table'

import querystring from 'querystring'

const PER_PAGE = 50
const usePage = () => {
  const query = querystring.parse(useLocation().search.substr(1))
  const p = Array.isArray(query.page) ? query.page[0] : query.page || '1'
  const page = parseInt(p)
  return Number.isNaN(page) ? 1 : page
}

const getInitialSearch = (search: string) => {
  const url = new URL(`https://www.google.com${search}`)
  return url.searchParams.get('q') || undefined
}

export type Props<T> = {
  queryKey: string
  query: DocumentNode
  actions?: (data: T) => any
  formatData?: (data: T) => any
  hideSearch?: boolean
  hideCreate?: boolean
  hideEdit?: boolean
  hideDelete?: boolean
}

function Index<T>({
  query,
  queryKey,
  formatData,
  actions,
  hideSearch = true,
  hideCreate = false,
  hideEdit = false,
  hideDelete = false,
}: Props<T>) {
  const modelName = getModelFromPath(useLocation().pathname)
  const currentPage = usePage()
  const location = useLocation()
  const initialSearch = useMemo(() => getInitialSearch(location.search), [location.search])
  const [search, setSearch] = React.useState<string | undefined>(initialSearch)
  const [searchVariable, setSearchVariable] = React.useState<string | undefined>(initialSearch)
  const { data, loading } = useQuery<{
    [key: string]: {
      nodes: any
      totalCount: number
      pageInfo: { totalCount: number; hasNextPage: boolean }
    }
  }>(query, {
    variables: {
      search: searchVariable,
      page: currentPage,
      perPage: PER_PAGE,
    },
  })

  const formattedData = React.useMemo(() => {
    if (data) {
      const theData = data[queryKey]?.nodes || data[queryKey]
      return formatData ? formatData(theData) : theData
    }

    return []
  }, [data, formatData, queryKey])

  const totalPages = data ? Math.ceil((data[queryKey].totalCount || 1) / PER_PAGE) : 1

  useDebounce(() => setSearchVariable(search), 500, [search])

  return (
    <Page
      isLoading={loading}
      currentPage={currentPage}
      totalPages={totalPages}
      actions={
        <>
          {!hideSearch && (
            <TextInput
              ariaLabel="Search"
              id="search"
              name="search"
              type="search"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder="Search"
            />
          )}

          {!hideCreate && (
            <Button fullWidth href={`/${modelName}/new`}>
              Create new
            </Button>
          )}

          {actions && <Page.ActionsGroup>{actions(formattedData)}</Page.ActionsGroup>}
        </>
      }
      hideBack
    >
      {loading && !data ? (
        'Loading...'
      ) : formattedData.length > 0 ? (
        <Table
          data={formattedData}
          modelName={modelName}
          hideEdit={hideEdit}
          hideDelete={hideDelete}
        />
      ) : (
        'No data'
      )}
    </Page>
  )
}

export default Index
