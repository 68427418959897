import gql from 'graphql-tag'

export default gql`
  query prices_update($id: ID!) {
    price(id: $id) {
      id
      name
      conversions {
        currency {
          id
          code
          name
        }
        value
      }
    }
  }
`
