import gql from 'graphql-tag'

export default gql`
  mutation resources_update_mutation(
    $id: ID!
    $description: String!
    $image: Upload!
    $tags: [ResourceTag!]!
    $title: String!
    $url: String!
  ) {
    resourceUpdate(
      id: $id
      description: $description
      image: $image
      tags: $tags
      title: $title
      url: $url
    ) {
      id
    }
  }
`
