import gql from 'graphql-tag'

import { FRAGMENT as COURSE_LESSON_FRAGMENT } from '../../../courseLessons/components/CourseLessonPreview'
import { FRAGMENT as COURSE_PROJECT_FRAGMENT } from '../../../courseProjects/components/CourseProjectPreview'
import { FRAGMENT as COURSE_HOMEWORK_FRAGMENT } from '../../../courseHomeworks/components/CourseHomeworkPreview'

export const QUERY = gql`
  query course_chapters_view($id: ID!) {
    courseChapter(id: $id) {
      id
      slug
      title
      description
      resourceUrl
      wistiaProjectId
      position
      isLocked

      lessons {
        nodes {
          ...CourseLessonPreview
        }
      }

      project {
        ...CourseProjectPreview
      }

      homework {
        ...CourseHomeworkPreview
      }
    }
  }

  ${COURSE_LESSON_FRAGMENT}
  ${COURSE_PROJECT_FRAGMENT}
  ${COURSE_HOMEWORK_FRAGMENT}
`
