import React from 'react'
import { Form } from '@superhi/design'

import { createModelPage } from '../utils'
import { API } from '../../../api'

import Update from './Update'
import Create from './Create'
import { INDEX, VIEW, DELETE_VIEW } from './queries'
import { DELETE } from './mutations'

export default createModelPage<
  API.product_fulfillables_index_productFulfillables_nodes[],
  API.product_fulfillables_view_productFulfillable,
  API.product_fulfillables_delete_view['productFulfillable']
>({
  name: 'Product Fulfillables',
  index: {
    queryKey: 'productFulfillables',
    query: INDEX,
  },
  view: {
    queryKey: 'productFulfillable',
    query: VIEW,
    formatData: ({ variants, ...data }) => ({ ...data, variants: variants?.nodes }),
  },
  edit: Update,
  create: Create,
  destroy: {
    linksQueryKey: 'productFulfillable',
    linksQuery: DELETE_VIEW,
    linksFormatDataFn: (data) =>
      (data?.variants?.nodes.length || 0) > 0 ? (
        <Form.Message>
          This fulfillable is attached to {data?.variants?.nodes.length} variants. These variants
          are linked to {data?.variants?.nodes.map((n) => n.product.title).join(', ')}
        </Form.Message>
      ) : (
        <div />
      ),
    mutation: DELETE,
  },
})
