import React from 'react'
import { boolean, string } from 'yup'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Form } from '@superhi/design'

import { configToInput } from '../../utils'
import * as config from '../config'
import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'

import QUERY from './queries'
import MUTATION from './mutations'

type FormData = {
  title: string
  description?: string
  fulfillmentId: string
  sku: string
  allowMultiplePerOrder: boolean
}

const ProductFulfillableUpdate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.product_fulfillables_update>(QUERY, { variables: { id } })

  if (!data?.productFulfillable) {
    return null
  }

  return (
    <BaseForm<FormData, API.ProductFulfillableUpdateVariables>
      validationSchema={{
        title: string().required(),
        description: string(),
        fulfillmentId: string().required(),
        sku: string().required(),
        allowMultiplePerOrder: boolean().required(),
      }}
      mutation={MUTATION}
      initialValues={{
        title: data.productFulfillable.title,
        description: data.productFulfillable.description || '',
        fulfillmentId: data.productFulfillable.fulfillmentId,
        sku: data.productFulfillable.sku,
        allowMultiplePerOrder: data.productFulfillable.allowMultiplePerOrder,
      }}
      createVariablesFn={(values) => ({ ...values, id })}
    >
      <Form.Row>{configToInput(config.TITLE)}</Form.Row>

      <Form.Row>{configToInput(config.DESCRIPTION)}</Form.Row>

      <Form.Row>{configToInput(config.FULFILLMENT_ID)}</Form.Row>

      <Form.Row>{configToInput(config.SKU)}</Form.Row>

      <Form.Row>{configToInput(config.ALLOW_MULTIPLE_PER_ORDER)}</Form.Row>
    </BaseForm>
  )
}

export default ProductFulfillableUpdate
