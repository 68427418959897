import gql from 'graphql-tag'

export const QUERY = gql`
  query resources_view($id: ID!) {
    resource(id: $id) {
      id
      description
      image {
        url
      }
      insertedAt
      tags
      title
      url
    }
  }
`
