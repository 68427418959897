import gql from 'graphql-tag'

export default gql`
  mutation PersonCreateMutation(
    $description: String!
    $image: Upload!
    $jobTitle: String!
    $location: String
    $name: String!
    $slug: String!
    $facebook: String
    $instagram: String
    $twitter: String
    $isSuperhi: Boolean!
  ) {
    personCreate(
      description: $description
      image: $image
      jobTitle: $jobTitle
      location: $location
      name: $name
      slug: $slug
      facebook: $facebook
      instagram: $instagram
      twitter: $twitter
      isSuperhi: $isSuperhi
    ) {
      id
    }
  }
`
