import { Heading, Message, TextVerticalSpacer } from '@superhi/design'

import { API } from '../../../../../api'
import { PageView } from '../../../utils'

import { QUERY } from './queries'

const View: PageView<API.course_watched_summaries_view_courseWatchedSummaries[]> = {
  queryKey: 'courseWatchedSummaries',
  query: QUERY,
  formatData: (data) => {
    if (!data) {
      return null
    }

    const course = data[0]?.course

    if (!course) {
      return null
    }

    return (
      <TextVerticalSpacer>
        <TextVerticalSpacer level="4">
          <Heading level="4">
            {course.title} ({5}%)
          </Heading>
          <Message level="2">
            The percentage watched in order to count a user as having &quot;watched&quot; this
            course is {course.watchedPercentageThreshold}%.
          </Message>
        </TextVerticalSpacer>

        <TextVerticalSpacer level="2">
          {data.map((item) => (
            <TextVerticalSpacer key={item.id} level="4">
              <div>
                From the beginning of time until the end of <strong>{item.date}</strong>
              </div>
              <strong>{item.total}</strong> people have watched this course
            </TextVerticalSpacer>
          ))}
        </TextVerticalSpacer>
      </TextVerticalSpacer>
    )
  },
}

export default View
