import gql from 'graphql-tag'

export default gql`
  query popup_update($id: ID!) {
    popup(id: $id) {
      id
      title
      isActive
      body
      buttonUrl
      buttonText
      createdAt
    }
  }
`
