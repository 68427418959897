import {
  FormCheckboxInput,
  FormLongTextInput,
  FormMultiSelectInput,
  FormSelectInput,
  FormTextInput,
} from '@superhi/design'
import { FieldConfig } from '@superhi/utils'

import { API } from '../../../../api'
import InputImage from '../../../../components/InputImage'
import MultiSelectPerson from '../../../../components/MultiSelectPerson'
import SelectCourse from '../../../../components/SelectCourse'

export const DIFFICULTY_LEVEL_OPTIONS = Object.values(API.ProductCourseDifficultyLevel).map(
  (option) => ({
    display: option,
    value: option,
  }),
)

export const LENGTH_OPTIONS = Object.values(API.ProductCourseLength).map((option) => ({
  display: option,
  value: option,
}))

export const SUBJECT_OPTIONS = Object.values(API.ProductCourseSubject).map((option) => ({
  display: option,
  value: option,
}))

export const STATUS_OPTIONS = Object.values(API.ProductStatus).map((option) => ({
  display: option,
  value: option,
}))

export const DIFFICULTY_LEVEL: FieldConfig<typeof FormSelectInput> = {
  name: 'difficultyLevel',
  label: 'Difficulty level',
  ariaLabel: 'Difficulty level',
  options: DIFFICULTY_LEVEL_OPTIONS,
  required: true,
}

export const LENGTH: FieldConfig<typeof FormSelectInput> = {
  name: 'length',
  label: 'Length',
  ariaLabel: 'Length',
  options: LENGTH_OPTIONS,
  required: true,
}

export const REQUIREMENTS: FieldConfig<typeof FormLongTextInput> = {
  name: 'requirements',
  label: 'Requirements',
  ariaLabel: 'Requirements',
  height: 8,
  required: true,
}

export const INSTRUCTOR_IDS: FieldConfig<typeof MultiSelectPerson> = {
  name: 'instructorIds',
  label: 'Instructors',
  ariaLabel: 'Instructors',
  required: true,
}

export const SUBJECTS: FieldConfig<typeof FormMultiSelectInput> = {
  name: 'subjects',
  label: 'Subjects',
  ariaLabel: 'Subjects',
  options: SUBJECT_OPTIONS,
  required: true,
}

export const TARGET_AUDIENCE: FieldConfig<typeof FormLongTextInput> = {
  name: 'targetAudience',
  label: 'Target audience',
  ariaLabel: 'Target audience',
  height: 8,
  required: true,
}

export const TRAILER_WISTIA_ID: FieldConfig<typeof FormTextInput> = {
  name: 'trailerWistiaId',
  label: 'Trailer Wistia ID',
  ariaLabel: 'Trailer Wistia ID',
  required: false,
}

export const SYLLABUS_URL: FieldConfig<typeof FormTextInput> = {
  name: 'syllabusUrl',
  label: 'Syllabus URL',
  ariaLabel: 'Syllabus URL',
  required: true,
}

export const IS_BETA: FieldConfig<typeof FormCheckboxInput> = {
  name: 'isBeta',
  label: 'Is beta?',
  ariaLabel: 'Is beta?',
  required: true,
}

export const MAILCHIMP_TAG: FieldConfig<typeof FormTextInput> = {
  name: 'mailchimpTag',
  label: 'Mailchimp Tag',
  ariaLabel: 'Mailchimp Tag',
  required: true,
}

export const OVERVIEW = (name: string): FieldConfig<typeof FormTextInput> => ({
  name,
  label: 'Overview',
  ariaLabel: 'Overview',
  required: true,
})

export const PROJECTS_TITLE = (name: string): FieldConfig<typeof FormTextInput> => ({
  name: `${name}.title`,
  label: 'Title',
  ariaLabel: 'Title',
  placeholder: 'E.g. Something',
  required: true,
})

export const PROJECTS_DESCRIPTION = (name: string): FieldConfig<typeof FormLongTextInput> => ({
  name: `${name}.description`,
  label: 'Description',
  ariaLabel: 'Description',
  placeholder: 'E.g. Something',
  height: 6,
  required: true,
})

export const PROJECTS_IMAGE = (name: string): FieldConfig<typeof InputImage> => ({
  name: `${name}.image`,
  label: 'Image',
  ariaLabel: 'Image',
  required: false,
})

export const PROJECTS_VIDEO_ID = (name: string): FieldConfig<typeof FormTextInput> => ({
  name: `${name}.videoId`,
  label: 'Video ID',
  ariaLabel: 'Video ID',
  required: false,
})

export const ORDER: FieldConfig<typeof FormTextInput> = {
  type: 'number',
  name: 'order',
  label: 'Order',
  ariaLabel: 'Order',
  required: true,
}

export const COURSE_ID: FieldConfig<typeof SelectCourse> = {
  name: 'courseId',
  label: 'Course',
  ariaLabel: 'Course',
  required: true,
}

export const STATUS: FieldConfig<typeof FormSelectInput> = {
  name: 'status',
  label: 'Status',
  ariaLabel: 'Status',
  required: true,
  options: STATUS_OPTIONS,
}
