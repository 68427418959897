import { FormLongTextInput, FormTextInput } from '@superhi/design'
import { FieldConfig } from '@superhi/utils'
import { string } from 'yup'

import {
  FIELDS as CREATE_FIELDS,
  VALIDATION_SCHEMA as CREATE_VALIDATION_SCHEMA,
} from '../Create/config'

const SLUG: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Slug',
  label: 'Slug',
  name: 'slug',
  placeholder: 'E.g.',
  required: true,
}

const TITLE: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Title',
  label: 'Title',
  name: 'title',
  placeholder: 'E.g.',
  required: true,
}

const DESCRIPTION: FieldConfig<typeof FormLongTextInput> = {
  ariaLabel: 'Description',
  label: 'Description',
  name: 'description',
  placeholder: 'E.g.',
  height: 6,
}

export const FIELDS = {
  ...CREATE_FIELDS,
  SLUG,
  TITLE,
  DESCRIPTION,
}

export const VALIDATION_SCHEMA = {
  ...CREATE_VALIDATION_SCHEMA,
  slug: string().required(),
  title: string().required(),
  description: string(),
}
