import { Body, DateTime, Link, Price } from '@superhi/design'

import { API } from '../../../../../api'

type Props = API.OrderPreview

const OrderPreview: React.FC<React.PropsWithChildren<Props>> = ({ id, insertedAt, total }) => (
  <Body level="3">
    <Link href={`/orders/${id}`}>
      #{id} on <DateTime>{insertedAt}</DateTime> – 
      <Price currencyCode={total.currencyCode}>{total.value}</Price>
    </Link>
  </Body>
)

export { default as FRAGMENT } from './fragments'
export default OrderPreview
