import gql from 'graphql-tag'

export default gql`
  mutation SettingsUpdateMutation($announcement: AnnouncementInput) {
    settingsUpdate(announcement: $announcement) {
      announcement {
        body
      }
    }
  }
`
