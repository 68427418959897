import gql from 'graphql-tag'

export const QUERY = gql`
  query course_projects_view($id: ID!) {
    courseProject(id: $id) {
      id
      slug
      title
      description
      briefUrl
      finishedUrl
      image {
        url
      }
      # files {
      #   id
      #   title
      #   url
      # }
      # links {
      #   id
      #   title
      #   url
      # }
    }
  }
`
