import gql from 'graphql-tag'

export default gql`
  query scholarship_update($id: ID!) {
    scholarship(id: $id) {
      id
      applicationUrl
      body
      closingAt
      image {
        url
      }
      intro
      publishedAt
      slug
      title
      winnersNotifiedAt
    }
  }
`
