import gql from 'graphql-tag'

export const INDEX = gql`
  query all_users_index($page: Int!, $perPage: Int!, $search: String) {
    users(page: $page, perPage: $perPage, search: $search) {
      nodes {
        id
        name
        email
        insertedAt
        membership {
          status
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`
