import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { string, array, object, number, boolean } from 'yup'
import { Form, FormTextInput } from '@superhi/design'

import { API } from '../../../../api'
import BaseForm from '../../_templates/BaseForm'
import * as config from '../config'
import { configToInput } from '../../utils'

import QUERY from './queries'
import MUTATION from './mutations'

type FormValues = {
  title: string
  isActive: boolean
  body: string
  buttonUrl: string
  buttonText: string
}

const PriceUpdate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { id } = useParams<any>()
  const { data } = useQuery<API.popup_update>(QUERY, { variables: { id } })

  if (!data?.popup) {
    return null
  }

  return (
    <BaseForm<FormValues, API.popups_update_mutationVariables>
      initialValues={{
        title: data?.popup.title || '',
        isActive: data?.popup.isActive,
        body: data?.popup.body || '',
        buttonUrl: data?.popup.buttonUrl || '',
        buttonText: data?.popup.buttonText || '',
      }}
      validationSchema={{
        title: string().required(),
        isActive: boolean().required(),
        body: string().required(),
        buttonUrl: string().required(),
        buttonText: string().required(),
      }}
      mutation={MUTATION}
      createVariablesFn={(formValues) => ({ id, ...formValues })}
    >
      <Form.Row>{configToInput(config.TITLE)}</Form.Row>
      <Form.Row>{configToInput(config.IS_ACTIVE)}</Form.Row>
      <Form.Row>{configToInput(config.BODY)}</Form.Row>
      <Form.Row>{configToInput(config.BUTTON_TEXT)}</Form.Row>
      <Form.Row>{configToInput(config.BUTTON_URL)}</Form.Row>
    </BaseForm>
  )
}

export default PriceUpdate
