import { array, string } from 'yup'
import { FormLongTextInput, FormSelectInput, FormTextInput } from '@superhi/design'
import { FieldConfig } from '@superhi/utils'

import { API } from '../../../../../api'

const TITLE: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Title',
  label: 'Title',
  name: 'title',
  placeholder: 'E.g. SuperHi',
  required: true,
}

const PLAN: FieldConfig<typeof FormSelectInput> = {
  ariaLabel: 'Plan type',
  label: 'Plan type',
  name: 'plan',
  placeholder: 'Choose one',
  options: Object.values(API.TeamPlan).map((v) => ({ display: v, value: v })),
  required: true,
}

const STRIPE_SUBSCRIPTION_ID: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Stripe subscription ID',
  label: 'Stripe subscription ID',
  name: 'stripeSubscriptionId',
  placeholder: 'E.g. fdfsdfds',
  required: true,
}

const ADMIN_EMAIL: FieldConfig<typeof FormTextInput> = {
  ariaLabel: 'Admin email',
  label: 'Admin email',
  name: 'adminEmail',
  placeholder: 'E.g. rik@superhi.com',
  required: true,
}

const USER_EMAILS: FieldConfig<typeof FormLongTextInput> = {
  ariaLabel: 'User emails',
  label: 'User emails',
  name: 'userEmails',
  placeholder: 'E.g. rik@superhi.com\nchris@superhi.com\nphil@superhi.com',
  required: true,
  height: 12,
  hint: 'Put each email address on a separate line',
}

export const FIELDS = {
  TITLE,
  PLAN,
  STRIPE_SUBSCRIPTION_ID,
  ADMIN_EMAIL,
  USER_EMAILS,
}

export const VALIDATION_SCHEMA = {
  title: string().required(),
  plan: string().oneOf(Object.values(API.TeamPlan)).required(),
  stripeSubscriptionId: string().required(),
  adminEmail: string().required(),
  userEmails: string().required(),
}
