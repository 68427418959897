import gql from 'graphql-tag'

export const INDEX = gql`
  query product_books_index($page: Int!, $perPage: Int!) {
    productBooks(page: $page, perPage: $perPage) {
      nodes {
        id
        title
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`

export const VIEW = gql`
  query product_books_view($id: ID!) {
    productBook(id: $id) {
      id
      slug
      title
      shortDescription
      previewDownloadUrl
      mailchimpTag
      description
      image {
        url
      }
    }
  }
`
