import { gql } from '@apollo/client'

export default gql`
  fragment StripePricePreview on StripePrice {
    value
    currencyCode
    recurring {
      interval
      intervalCount
    }
  }
`
