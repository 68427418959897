import gql from 'graphql-tag'

export const INDEX = gql`
  query scholarships_index($page: Int!, $perPage: Int!) {
    scholarships(page: $page, perPage: $perPage) {
      nodes {
        id
        title
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`

export const VIEW = gql`
  query scholarships_view($id: ID!) {
    scholarship(id: $id) {
      id
      title
      applicationUrl
      body
      image {
        url
      }
    }
  }
`
