import { createModelPage } from '../utils'
import { API } from '../../../api'

import Create from './Create'
import Update from './Update'
import { INDEX, VIEW } from './queries'
import { DELETE } from './mutations'

export default createModelPage<
  API.scholarships_index_scholarships_nodes[],
  API.scholarships_view_scholarship
>({
  name: 'Scholarships',
  index: {
    queryKey: 'scholarships',
    query: INDEX,
  },
  view: {
    queryKey: 'scholarship',
    query: VIEW,
  },
  create: Create,
  edit: Update,
  destroy: {
    mutation: DELETE,
  },
})
