import fetch from 'isomorphic-unfetch'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from 'apollo-link-context'
import { createLink } from 'apollo-absinthe-upload-link'
import { authTokenCookie } from '@superhi/account'

import * as API from './types'

let apolloClient: ApolloClient<any> | null = null

// for client its build as string with webpack, for server its set by CI
const uri =
  process.env.REACT_APP_API_DOMAIN +
  `/v${process.env.REACT_APP_API_VERSION}` +
  (process.env.NODE_ENV === 'development' ? '/graphql' : '')

const httpLink = createLink({
  uri,
  fetch,
})

// Polyfill fetch() on the server (used by apollo-client)
if (!(process as any).browser) {
  ;(global as any).fetch = fetch
}

const cache = new InMemoryCache()

const getAuthTokenFromRequest = (request: any) => {
  if (!request) {
    return null
  }
  const [, token] = request.headers.cookie.split(`; ${authTokenCookie.name}=`)

  return token
}

const createAuthLink = (request: any): any =>
  setContext((_, { headers = {} }) => {
    const token = getAuthTokenFromRequest(request) || authTokenCookie.get()

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }
  })

const create = (initialState: any, request?: any) =>
  new ApolloClient({
    link: createAuthLink(request).concat(httpLink),
    cache: cache.restore(initialState),
    ssrMode: !(process as any).browser, // Disables forceFetch on the server (so queries are only run once)
    connectToDevTools: (process as any).browser,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
      },
      query: {
        fetchPolicy: 'network-only',
      },
      mutate: {},
    },
    // defaultOptions: {
    //   query: {
    //     fetchPolicy: '',
    //   },
    // },
  })

export const initApollo = ({
  initialState = {},
  request,
}: {
  initialState?: object
  request?: any
}) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!(process as any).browser) {
    return create(initialState, request)
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, request)
  }

  return apolloClient
}

export { API }
export default initApollo
