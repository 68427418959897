import { Body, Link } from '@superhi/design'

import StringPreview from '../StringPreview'

type Props = { url?: string | null }

const UrlPreview: React.FC<React.PropsWithChildren<Props>> = ({ url, children }) =>
  url ? (
    <Body level="3">
      <Link href={url} isExternal>
        {children || url}
      </Link>
    </Body>
  ) : (
    <StringPreview />
  )

export default UrlPreview
