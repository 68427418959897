import gql from 'graphql-tag'

export default gql`
  mutation course_lessons_update_mutation(
    $id: ID!
    $description: String
    $slug: String!
    $title: String!
    $wistiaVideoId: String!
  ) {
    courseLessonUpdate(
      id: $id
      description: $description
      slug: $slug
      title: $title
      wistiaVideoId: $wistiaVideoId
    ) {
      id
    }
  }
`
