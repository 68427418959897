import gql from 'graphql-tag'

export const QUERY = gql`
  query teams_index {
    teams {
      nodes {
        id
        title
      }
      totalCount
      pageInfo {
        hasNextPage
        totalCount
      }
    }
  }
`
